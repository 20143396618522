import { contactTypes as actionTypes } from '@actions';
import { commonState, commonReducer } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true }),
};

const defaultState = {
  ...commonState.default(),
};

export const contactCreate = (state = defaultState, action) =>
  commonReducer(actionTypes.CREATE, state, action);

export const contactList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const contactUpdate = (state = defaultState, action) =>
  commonReducer(actionTypes.UPDATE, state, action);
