import React from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { useTranslate, useSearchSelector } from '@hooks';
import { searchOffers } from '@actions';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import { FilterBlock } from '@dotone/react-ui-core';

const OfferFilterBlock = ({ ids = [], ...otherProps }) => {
  const { t, locale } = useTranslate('common', 'model');
  const dispatch = useDispatch();
  const { data: offers } = useSearchSelector(({ offerSearch }) => offerSearch, {
    ids,
    locale,
    active: false,
  });

  useEffectOnce(() => {
    if (ids.length && !offers.length) {
      dispatch(searchOffers({ ids, locale, active: false }));
    }
  });
  return (
    <FilterBlock
      list={false}
      label={t('offer')}
      items={offers.map((value) => getNameWithId(value))}
      {...otherProps}
    />
  );
};

export default React.memo(OfferFilterBlock);
