import React, { useEffect } from 'react';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useTranslate, useCurrentUser } from '@hooks';
import { Form, Row, Col, FormGroup, Label } from 'reactstrap';
import { toBoolean, toDate } from '@dotone/react-ui-core/utils';
import {
  SubsectionTitle,
  CampaignStatusSelect,
  DateTimeSelect,
  FlexContainer,
  Button,
  ButtonCancel,
  FormAction,
  BooleanSelect,
} from '@dotone/react-ui-core';
import { OfferSearchInput, AffiliateSearchInput } from '../forms';

const CampaignFilterForm = ({ defaultValues, onSubmit, onReset, onDismiss, ...otherProps }) => {
  const { t } = useTranslate('campaign', 'model');
  const { currentUser } = useCurrentUser();
  const { watch, connectForm, setValue, handleSubmit } = useForm({
    defaultValues,
  });
  const isCustomPayout = watch('isCustomPayout');

  const handleSubmitForm = (values) => {
    onSubmit({
      ...values,
      payScheduleTo: toDate(values.payScheduleTo),
      payScheduleFrom: toDate(values.payScheduleFrom),
    });
  };

  useEffect(() => {
    if (!toBoolean(isCustomPayout)) {
      setValue('payScheduleFrom', '');
      setValue('payScheduleTo', '');
    }
  }, [isCustomPayout, setValue]);

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} {...otherProps}>
      {connectForm(
        <Row>
          <Col lg={12}>
            <SubsectionTitle>{t('common:filterBy')}</SubsectionTitle>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <FormGroup>
              <Label>{t('::common:model.offer')}</Label>
              <OfferSearchInput multi name="offerIds" />
            </FormGroup>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <FormGroup>
              <Label>{t('::common:model.affiliate')}</Label>
              <AffiliateSearchInput multi name="affiliateIds" />
            </FormGroup>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <FormGroup>
              <Label>{t('::common:model.status')}</Label>
              <CampaignStatusSelect
                multi
                withConfirming={!!currentUser.partialPro}
                name="approvalStatuses"
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{t('isCustomPayout')}</Label>
              <BooleanSelect name="isCustomPayout" />
            </FormGroup>
          </Col>
          {toBoolean(isCustomPayout) && (
            <Col md={12} lg={12} xl={12}>
              <FormGroup>
                <Label>{t('payScheduleExpiration')}</Label>
                <FlexContainer align="center">
                  <DateTimeSelect name="payScheduleFrom" timeFormat={false} />
                  <span className="mx-1">{t('common:to')}</span>
                  <DateTimeSelect name="payScheduleTo" timeFormat={false} />
                </FlexContainer>
              </FormGroup>
            </Col>
          )}
        </Row>
      )}
      <FormAction align="right">
        <Button type="submit" color="primary">
          {t('common:actions.applyFilters')}
        </Button>
        <Button type="button" color="secondary" onClick={() => onReset()}>
          {t('common:actions.reset')}
        </Button>
        <ButtonCancel onClick={onDismiss} />
      </FormAction>
    </Form>
  );
};

export default CampaignFilterForm;
