import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@hooks';
import { deleteDownload } from '@actions';
import { downloadFile } from '@utils';
import { TablePaginated, Text, ButtonFlat } from '@dotone/react-ui-core';
import { formatDateTime, formatFileStatus } from '../formatters';

const formatActions = (onDownload, onDelete) =>
  function RenderActions(cell, row) {
    return (
      <>
        <ButtonFlat
          variant="download"
          disabled={!row.cdnUrl}
          iconProps={{ size: 18 }}
          className="mr-4"
          onClick={() => onDownload(row)}
        />
        <ButtonFlat
          confirm
          variant="delete"
          iconProps={{ size: 18 }}
          className="text-danger"
          onClick={() => onDelete(row)}
        />
      </>
    );
  };
const formatNotes = (cell) => cell && <Text html>{cell.replace(/\r|\n/g, '<br/>')}</Text>;

const getColumns = ({ t, onDownload, onDelete }) => [
  {
    dataField: 'id',
    text: t('id'),
  },
  {
    dataField: 'name',
    text: t('name'),
  },
  {
    dataField: 'createdAt',
    text: t('createdAt'),
    formatter: formatDateTime(),
  },
  {
    dataField: 'notes',
    text: t('notes'),
    headerStyle: { width: '40%' },
    formatter: formatNotes,
  },
  {
    dataField: 'downloadedBy',
    text: t('downloadedBy'),
  },
  {
    dataField: 'status',
    text: t('status'),
    formatter: formatFileStatus,
  },
  {
    dataField: 'actions',
    text: '',
    classes: 'align-middle text-center',
    isDummyField: true,
    formatter: formatActions(onDownload, onDelete),
  },
];

const DownloadTable = (props) => {
  const { t } = useTranslate('download', 'model');
  const dispatch = useDispatch();

  const onDelete = (prop) => {
    dispatch(deleteDownload(prop.id));
  };

  const onDownload = (prop) => {
    downloadFile(prop.cdnUrl);
  };

  const columns = getColumns({ t, onDownload, onDelete });
  const tableProps = {
    remote: true,
    searchable: false,
    condensed: true,
    hover: true,
    responsive: true,
    classes: 'text-sm stylish-table',
    keyField: 'id',
    columns,
    ...props,
  };

  return <TablePaginated {...tableProps} />;
};

export default DownloadTable;
