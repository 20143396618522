import React from 'react';
import { Modal, Input, ExternalLink, CardStackable } from '@dotone/react-ui-core';
import { useTranslate } from '@hooks';

const TextCreativeLandingPageModal = ({ textCreative, isOpen, handleToggle }) => {
  const { t } = useTranslate('textCreative', 'model');

  const handleClick = (e) => {
    e.target.select();
  };

  return (
    <Modal
      title={t('customLandingPage')}
      size="lg"
      footer={false}
      scrollable={false}
      isOpen={isOpen}
      onToggle={handleToggle}
    >
      {textCreative && (
        <CardStackable title={t('customLandingPage')}>
          <Input
            type="textarea"
            readOnly
            rows={3}
            value={textCreative.customLandingPage}
            onClick={handleClick}
          />
          <div className="mt-3 text-right">
            <ExternalLink href={textCreative.customLandingPage} />
          </div>
        </CardStackable>
      )}
    </Modal>
  );
};

export default TextCreativeLandingPageModal;
