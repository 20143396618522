import React from 'react';
import { useTranslate } from '@hooks';
import { FilterBlock, FilterList } from '@dotone/react-ui-core';
import { camelCase } from 'lodash';
import { MediaCategoryFilterBlock } from '../filters';

const AffiliateFilterList = ({ filters, ...otherProps }) => {
  const { t, tShared } = useTranslate('affiliate', 'table');
  const { labels, statuses, genders, sources, mediaCategoryIds } = filters;

  const translate = (nestedNs, items = []) => {
    return items.map((item) => tShared(`${nestedNs}.${camelCase(item)}`));
  };

  return (
    <FilterList {...otherProps}>
      <FilterBlock label={t('status')} items={translate('status', statuses)} list={false} />
      <FilterBlock label={t('label')} items={translate('label', labels)} list={false} />
      <MediaCategoryFilterBlock ids={mediaCategoryIds} />
      <FilterBlock label={t('source')} items={translate('source', sources)} list={false} />
      <FilterBlock label={t('gender')} items={translate('gender', genders)} list={false} />
    </FilterList>
  );
};

export default React.memo(AffiliateFilterList);
