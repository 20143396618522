import { LanguageApi } from '@api';
import { languageTypes as actionTypes } from './types';

export const fetchLanguages = () => (dispatch) => {
  dispatch({
    type: actionTypes.LOADING,
  });

  LanguageApi.getList()
    .then((response) => {
      dispatch({
        type: actionTypes.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
