import { AffiliateFeedApi } from '@api';
import { feedTypes as actionTypes } from './types';

export const fetchFeeds = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  AffiliateFeedApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
