import { AffiliateApi } from '@api';
import { affiliateTypes as actionTypes } from './types';

export const fetchAffiliates = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  AffiliateApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const updateAffiliate = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  AffiliateApi.update(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const fetchRecentAffiliates = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST_RECENT.LOADING,
  });

  AffiliateApi.getRecent(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST_RECENT.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST_RECENT.FAILURE,
        message: error.statusText,
      });
    });
};

export const searchAffiliates = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH.LOADING,
  });

  const searchKey = JSON.stringify(params);

  AffiliateApi.search(params)
    .then((response) => {
      dispatch({
        type: actionTypes.SEARCH.SUCCESS,
        payload: { ...response, data: { [searchKey]: response.data } },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SEARCH.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
