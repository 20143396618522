import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { updateOrder } from '@actions';
import { orderStatuses as statuses, statApprovals } from '@dotone/react-ui-core/constants';
import { camelCase } from 'lodash';
import { Table, Row, Col, Form } from 'reactstrap';
import {
  InputGroup,
  OrderStatusSelect,
  OrderStatusBadge,
  DateTime,
  FormAction,
  Button,
  ButtonCancel,
  StatApprovalBadge,
} from '@dotone/react-ui-core';

const editableApprovals = [
  statuses.PENDING,
  statuses.INVALID,
  statuses.REJECTED,
  statuses.FULLRETURN,
];

const OrderForm = ({ defaultValues = {}, onCancel }) => {
  const { t, tShared } = useTranslate('order', 'model');
  const dispatch = useDispatch();
  const { handleSubmit, connectForm, watch, setValue } = useForm({ defaultValues });

  const { approval, status, recordedAt, orderNumber, stepLabel, stepName } = defaultValues;

  const handleSubmitForm = ({ id, ...values }) => {
    dispatch(updateOrder(id, values));
  };

  const { isLoading, status: updateStatus } = useShallowEqualSelector(
    ({ orderUpdate }) => orderUpdate
  );

  const enableUpdate = editableApprovals.includes(approval);

  const [total, trueShare] = watch(['total', 'trueShare']);

  const hintProps =
    approval === statApprovals.INVALID
      ? { tKey: orderNumber, title: tShared(`status.${camelCase(status)}`) }
      : null;

  useEffect(() => {
    setValue('truePay', ((parseFloat(total) / 100) * trueShare).toFixed(2));
  }, [setValue, total, trueShare]);

  useEffect(() => {
    if (!isLoading && updateStatus === 'success' && onCancel) {
      onCancel();
    }
  }, [isLoading, updateStatus, onCancel]);

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)}>
      <div className="form-inner">
        {connectForm(
          <Row>
            <Col lg={5}>
              <Table>
                <thead>
                  <tr>
                    <th colSpan={2}>{t('common:model.order')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('recordedAt')}</td>
                    <td>{recordedAt && <DateTime full value={recordedAt} />}</td>
                  </tr>
                  <tr>
                    <td>{t('orderNumber')}</td>
                    <td>{orderNumber}</td>
                  </tr>
                  <tr>
                    <td>{t('stepName')}</td>
                    <td>
                      {stepLabel} ({stepName})
                    </td>
                  </tr>
                  <tr>
                    <td>{t('transactionStatus')}</td>
                    <td>
                      <StatApprovalBadge value={approval} hintProps={hintProps} />
                    </td>
                  </tr>
                  <tr>
                    <td>{t('status')}</td>
                    <td>
                      {enableUpdate ? (
                        <>
                          <OrderStatusBadge value={status} className="mb-2" />
                          <OrderStatusSelect name="status" rules={{ required: true }} />
                        </>
                      ) : (
                        <OrderStatusBadge value={status} />
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th colSpan={3}>{t('truePay')}</th>
                  </tr>
                  <tr>
                    <th>{t('total')}</th>
                    <th>{t('trueShare')}</th>
                    <th>{t('truePay')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <InputGroup
                        name="total"
                        nowrap
                        icon="$"
                        inputProps={{
                          min: 0,
                          step: '0.01',
                          type: 'number',
                          name: 'total',
                          disabled: !enableUpdate,
                        }}
                      />
                    </td>
                    <td>
                      <InputGroup
                        name="trueShare"
                        nowrap
                        icon="%"
                        inputProps={{
                          type: 'number',
                          name: 'trueShare',
                          readOnly: true,
                        }}
                      />
                    </td>
                    <td>
                      <InputGroup
                        name="truePay"
                        nowrap
                        icon="$"
                        inputProps={{
                          type: 'number',
                          name: 'truePay',
                          readOnly: true,
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </div>
      <FormAction align="right">
        {enableUpdate && (
          <Button type="submit" variant="save" color="primary" submitting={isLoading}>
            {t('common:actions.save')}
          </Button>
        )}
        {onCancel && <ButtonCancel disabled={isLoading} onClick={onCancel} />}
      </FormAction>
    </Form>
  );
};

export default OrderForm;
