import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFilterSelector, useShallowEqualSelector, useTranslate } from '@hooks';
import { updateContact, fetchContacts } from '@actions';
import { contactStatus } from '@dotone/react-ui-core/constants';
import { MessageEmpty, ContactItem, Modal, ButtonCircle } from '@dotone/react-ui-core';
import ContactForm from './ContactForm';

const ContactList = (props) => {
  const { t, locale } = useTranslate('contact', 'actions');
  const dispatch = useDispatch();
  const [current, setCurrent] = useState();

  const { data } = useFilterSelector(({ contactList }) => contactList, {
    status: contactStatus.ACTIVE,
  });

  const submitting = useShallowEqualSelector(
    ({ contactCreate, contactUpdate }) => contactCreate.isLoading || contactUpdate.isLoading
  );

  const handleToggle = (contact) => () => {
    setCurrent(contact);
  };

  const handleUpdate = (contact, status) => {
    dispatch(updateContact(contact.id, { status }));
  };

  useEffect(() => {
    if (!data.length) {
      dispatch(fetchContacts({ locale }));
    }
  }, [dispatch, locale, data.length]);

  return (
    <div {...props}>
      <div className="text-right p-3">
        <ButtonCircle variant="add" color="primary" onClick={handleToggle({})} />
      </div>
      {data.map((contact) => (
        <ContactItem
          key={contact.id}
          contact={contact}
          submitting={submitting}
          onEdit={handleToggle(contact)}
          onUpdate={(status) => handleUpdate(contact, status)}
        />
      ))}
      {!data.length && <MessageEmpty className="p-card" />}
      <Modal
        isOpen={!!current}
        title={current?.id ? t('edit') : t('add')}
        onToggle={handleToggle()}
        footer={false}
        size="lg"
      >
        {current && <ContactForm defaultValues={current} onCancel={handleToggle()} />}
      </Modal>
    </div>
  );
};

export default React.memo(ContactList);
