import React from 'react';
import { Input, ControlGroup } from '@dotone/react-ui-core';

const OfferVariantRadioGroup = ({ offerVariants, name, groupProps = {}, ...otherProps }) => {
  return (
    <ControlGroup name={name} formProps={otherProps.formProps} {...groupProps}>
      {offerVariants.map((offerVariant) => (
        <Input
          id={`${name}${offerVariant.id}`}
          key={`${name}${offerVariant.id}`}
          type="radio"
          name={name}
          label={offerVariant.fullName}
          value={offerVariant.id}
          {...otherProps}
        />
      ))}
    </ControlGroup>
  );
};

export default OfferVariantRadioGroup;
