import React from 'react';
import { useTranslate } from '@hooks';
import { camelCase, chain, sum } from 'lodash';
import { Text, FlagList, ModalWithInitialButton, Badge, DeviceIcon } from '@dotone/react-ui-core';
import { FileText as DocumentIcon } from 'react-feather';
import DataCell from './OfferDataCell';
import OfferAvailableToolDataCell from './OfferAvailableToolDataCell';
import OfferPayout from './OfferPayout';

const Row = ({ title, hintProps, children }) => (
  <tr>
    <th className="align-middle text-right text-nowrap" style={{ width: '10%', height: '68px' }}>
      <Text
        align="right"
        weight="bold"
        className="text-dark m-0 pr-3 border-right"
        hintProps={hintProps}
      >
        {title}
      </Text>
    </th>
    {children}
  </tr>
);

const OfferCardInfo = ({ offer, ...otherProps }) => {
  const { t, tShared } = useTranslate('offer', 'model');
  const { tHint } = useTranslate('offer', 'hints');
  const {
    id,
    capturedTime,
    capturedTimeNumDays,
    approvedTime,
    approvedTimeNumDays,
    publishedTime,
    publishedTimeNumDays,
    attributionType,
    payouts,
    conversionSteps,
    offerCap,
    detailViewsLastMonth,
    totalActiveAffiliates,
    totalActiveImageCreatives,
    totalActiveTextCreatives,
    countries,
    brandBackground,
    productDescription,
    targetAudience,
    otherInfo,
    suggestedMedia,
    mediaRestrictions,
    trackDevice,
  } = offer;

  const trackingDevices = chain([trackDevice]).flatten().compact().value();
  const conversionStep = conversionSteps[0];

  return (
    <div className="table-responsive" {...otherProps}>
      <table className="table table-bordered-bottom table-sm stylish-table m-0">
        <tbody>
          <Row title={t('payout')}>
            {[...Array(4)].map((_, index) => {
              const payout = payouts[index];

              if (payout) {
                return <OfferPayout offerId={id} key={index} payout={payout} />;
              }

              return <td key={index} />;
            })}
          </Row>
          <Row title={t('validation')}>
            {conversionStep && (
              <DataCell hintProps={tHint('conversion', { id })} style={{ width: '20%' }}>
                {conversionStep.label}
              </DataCell>
            )}
            <DataCell hintProps={tHint('capturedTime', { id })} style={{ width: '20%' }}>
              {capturedTime &&
                tShared(`conversionTime.${camelCase(capturedTime)}`, {
                  value: capturedTimeNumDays,
                })}
            </DataCell>
            <DataCell hintProps={tHint('publishedTime', { id })} style={{ width: '20%' }}>
              {publishedTime &&
                tShared(`conversionTime.${camelCase(publishedTime)}`, {
                  value: publishedTimeNumDays,
                })}
            </DataCell>
            <DataCell hintProps={tHint('confirmedTime', { id })} style={{ width: '20%' }}>
              {approvedTime &&
                tShared(`conversionTime.${camelCase(approvedTime)}`, {
                  value: approvedTimeNumDays,
                })}
            </DataCell>
          </Row>
          <Row title={t('marketingInfo')}>
            <DataCell label={t('brandBackground')}>
              {brandBackground && (
                <ModalWithInitialButton
                  title={t('brandBackground')}
                  footerVariant="close"
                  buttonProps={{
                    className: 'text-secondary',
                    color: 'link',
                    size: 'sm',
                    children: <DocumentIcon />,
                  }}
                >
                  <Text html>{brandBackground}</Text>
                </ModalWithInitialButton>
              )}
            </DataCell>
            <DataCell label={t('productDescription')}>
              {productDescription && (
                <ModalWithInitialButton
                  title={t('productDescription')}
                  footerVariant="close"
                  buttonProps={{
                    className: 'text-secondary',
                    color: 'link',
                    size: 'sm',
                    children: <DocumentIcon />,
                  }}
                >
                  <Text html>{productDescription}</Text>
                </ModalWithInitialButton>
              )}
            </DataCell>
            <DataCell label={t('audienceAndMedia')}>
              {(targetAudience || suggestedMedia) && (
                <ModalWithInitialButton
                  title={t('audienceAndMedia')}
                  footerVariant="close"
                  buttonProps={{
                    className: 'text-secondary',
                    color: 'link',
                    size: 'sm',
                    children: <DocumentIcon />,
                  }}
                >
                  {targetAudience && (
                    <div className="mb-3">
                      <Text size="md" muted>
                        {t('targetAudience')}
                      </Text>
                      <Text html>{targetAudience}</Text>
                    </div>
                  )}
                  {suggestedMedia && (
                    <div className="mb-3">
                      <Text size="md" muted>
                        {t('suggestedMedia')}
                      </Text>
                      <Text html>{suggestedMedia}</Text>
                    </div>
                  )}
                </ModalWithInitialButton>
              )}
            </DataCell>
            <DataCell label={t('additionalInfo')}>
              {otherInfo && (
                <ModalWithInitialButton
                  title={t('additionalInfo')}
                  footerVariant="close"
                  buttonProps={{
                    className: 'text-secondary',
                    color: 'link',
                    size: 'sm',
                    children: <DocumentIcon />,
                  }}
                >
                  <Text html>{otherInfo}</Text>
                </ModalWithInitialButton>
              )}
            </DataCell>
          </Row>
          <Row title={t('trackingInfo')}>
            <DataCell label={t('attributionType')}>
              {attributionType && t(`attributionType.${camelCase(attributionType)}`)}
            </DataCell>
            <DataCell hintProps={tHint('cookieLength', { id })}>
              {conversionSteps[0]?.daysToExpire}
            </DataCell>
            <DataCell hintProps={tHint('conversionCap', { id })}>
              {offerCap?.number &&
                offerCap?.capType &&
                `${offerCap.number} ${t(`offer:capType.${camelCase(offerCap.capType)}`)}`}
            </DataCell>
            <DataCell label={t('trackingDevice')}>
              {trackingDevices?.map((device) => (
                <DeviceIcon device={device} key={device} />
              ))}
            </DataCell>
          </Row>
          <Row title={t('availableTools')}>
            <OfferAvailableToolDataCell offer={offer} />
          </Row>
          <Row
            title={t('offer:hints.mediaRestriction.title')}
            hintProps={{ ...tHint('mediaRestriction'), target: `hint-restrictions-${id}` }}
          >
            <DataCell colSpan="4" className="text-left">
              {mediaRestrictions?.map((restriction) => (
                <Badge key={restriction.id} color="danger" className="mr-1 text-rg">
                  {restriction.name}
                </Badge>
              ))}
            </DataCell>
          </Row>
          <Row title={t('offer:hints.allowedGeo.title')} hintProps={tHint('allowedGeo', { id })}>
            <DataCell colSpan="4" className="text-left">
              <FlagList countries={countries} style={{ height: 16 }} className="mx-1" />
            </DataCell>
          </Row>
          <Row title={t('activities')}>
            <DataCell
              value={sum(detailViewsLastMonth)}
              hintProps={tHint('offerPageView', { id })}
            />
            <DataCell hintProps={tHint('activeAffiliates', { id })}>
              {totalActiveAffiliates}
            </DataCell>
            <DataCell hintProps={tHint('activeBanners', { id })}>
              {totalActiveImageCreatives}
            </DataCell>
            <DataCell hintProps={tHint('activePromoAndDeals', { id })}>
              {totalActiveTextCreatives}
            </DataCell>
          </Row>
        </tbody>
      </table>
    </div>
  );
};

export default OfferCardInfo;
