import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useTranslate, useShallowEqualSelector } from '@hooks';
import { searchStats } from '@actions';
import { camelCase, snakeCase, trim } from 'lodash';
import {
  Form,
  FormGroup,
  FormAction,
  Label,
  Input,
  Button,
  ButtonCancel,
  StatSearchFieldSelect,
} from '@dotone/react-ui-core';

const StatSearchForm = ({
  defaultValues: givenDefaultValues = {},
  onSubmit,
  onDismiss,
  ...otherProps
}) => {
  const { t } = useTranslate('stat', 'search');
  const router = useRouter();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);

  const {
    isLoading: submitting,
    status,
    searchKey,
    searchParams,
  } = useShallowEqualSelector(({ statSearch }) => statSearch);

  const { field, search = [] } = givenDefaultValues;
  const defaultValues = {
    ...givenDefaultValues,
    field: field ? camelCase(field) : null,
    search: search.join('\n'),
  };
  const { dataType } = searchParams || {};

  const handleSubmit = (values) => {
    const data = {
      ...values,
      search: values.search.split('\n').map(trim),
      field: snakeCase(values.field),
    };

    dispatch(searchStats({ data }));
    setSubmitted(true);
    if (onSubmit) onSubmit(data);
  };

  useEffect(() => {
    if (submitted && status === 'success' && searchKey) {
      router.push(
        {
          pathname: '/advertisers/transactions/search',
          query: { searchKey, dataType },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [submitted, status, router, searchKey, dataType]);

  return (
    <Form defaultValues={defaultValues} onSubmit={handleSubmit} {...otherProps}>
      <FormGroup>
        <Label>{t('searchBy')}</Label>
        <StatSearchFieldSelect name="field" rules={{ required: true }} />
      </FormGroup>
      <FormGroup>
        <Label>{t('search')}</Label>
        <Input type="textarea" name="search" rules={{ required: true }} rows={5} />
      </FormGroup>
      <FormAction align="right">
        <Button type="submit" color="primary" submitting={submitting}>
          {t('common:actions.submit')}
        </Button>
        <ButtonCancel disabled={submitting} onClick={onDismiss} />
      </FormAction>
    </Form>
  );
};

export default StatSearchForm;
