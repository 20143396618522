import React, { useEffect, useState } from 'react';
import { useTranslate } from '@hooks';
import { isEqual } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CardFeatured } from '@dotone/react-ui-core';

const defaultOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    height: 440,
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  accessibility: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        connectorColor: 'silver',
      },
    },
  },
  series: [],
  credits: {
    enabled: false,
  },
};

const ClicksByDevice = ({ ourVisitors, isLoading }) => {
  const { t } = useTranslate('dashboard', 'totalClicksPerDevices');
  const [options, setOptions] = useState({
    ...defaultOptions,
    title: {
      text: t('differentDevices'),
    },
  });

  useEffect(() => {
    if (ourVisitors) {
      const data = Object.keys(ourVisitors).map((key) => ({
        name: t(key),
        y: ourVisitors[key],
      }));

      setOptions((prev) => {
        if (data && !isEqual(data, prev.series?.[0]?.data, data)) {
          return {
            ...prev,
            series: [
              {
                name: t('dashboard:performanceStatistics.clicks'),
                data,
              },
            ],
          };
        }
        return prev;
      });
    }
  }, [t, ourVisitors]);

  return (
    <CardFeatured title={t('title')} data={ourVisitors} loading={isLoading}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </CardFeatured>
  );
};

export default ClicksByDevice;
