import React, { useState } from 'react';
import { useCurrentUser, useTranslate } from '@hooks';
import { TablePaginated } from '@dotone/react-ui-core';
import { AffiliateLogFormModal } from '../AffiliateLogs';
import { formatAvatar } from '../Affiliates/formatters';
import { formatAffiliateName, formatNotes } from '../formatters';
import {
  formatOfferName,
  formatStatus,
  formatDefaultPayouts,
  formatCustomPayouts,
  formatJoinDate,
} from './formatters';
import FilterForm from './CampaignFilterForm';
import FilterList from './CampaignFilterList';

const getColumns = ({ t, currentUser, onNoteOpen }) => [
  {
    dataField: 'offer',
    text: t('::common:model.offer'),
    formatter: formatOfferName,
  },
  {
    dataField: 'affiliate',
    text: t('::common:model.affiliate'),
    formatter: (cell) => formatAffiliateName(cell, { currentUser }),
  },
  {
    dataField: 'affiliate.avatarCdnUrl',
    text: t('avatar'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: (cell, row) => formatAvatar({ currentUser, cell, row: row.affiliate }),
  },
  {
    dataField: 'defaultPayouts',
    text: t('defaultPayouts'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: formatDefaultPayouts,
  },
  {
    dataField: 'customPayouts',
    text: t('customPayouts'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: formatCustomPayouts,
  },
  {
    dataField: 'approvalStatus',
    text: t('::common:model.status'),
    style: { maxWidth: 140 },
    formatter: (cell, row) => formatStatus(cell, row, currentUser),
  },
  {
    dataField: 'createdAt',
    text: t('joinDate'),
    headerAlign: 'center',
    align: 'center',
    formatter: formatJoinDate({ t }),
  },
  {
    dataField: 'logs',
    text: t('::common:notes'),
    classes: 'hover-overlay',
    formatter: (cell, row) => formatNotes({ row, cell, onNoteOpen }),
  },
];

const CampaignTable = (props) => {
  const { t } = useTranslate('campaign', 'model');
  const { currentUser } = useCurrentUser();
  const [current, setCurrent] = useState({});

  const handleToggle = () => {
    setCurrent({});
  };

  const columns = getColumns({ t, currentUser, onNoteOpen: setCurrent });

  return (
    <>
      <TablePaginated
        remote
        condensed
        responsive
        hover
        classes="stylish-table"
        keyField="id"
        columns={columns}
        filterRenderer={(filterProps) => <FilterForm {...filterProps} />}
        filterListRenderer={(filters) => <FilterList filters={filters} />}
        {...props}
      />
      {current?.id && (
        <AffiliateLogFormModal
          defaultValues={{ ownerId: current?.id, ownerType: 'AffiliateOffer' }}
          isOpen={!!current?.id}
          onToggle={handleToggle}
          items={current?.logs || []}
        />
      )}
    </>
  );
};

export default CampaignTable;
