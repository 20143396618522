import API from './api';

export default class EasyStoreSetupApi {
  static get(params) {
    return API.get('/advertisers/easy_store_setups/find', params);
  }

  static create(params) {
    return API.post(`/advertisers/easy_store_setups`, params);
  }
}
