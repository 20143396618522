import React, { useEffect } from 'react';
import { Label, Row, Col, Form } from 'reactstrap';
import {
  FormGroup as BsFormGroup,
  AlertPopover,
  FormAction,
  Button,
  ButtonCancel,
  StatApprovalSelect,
} from '@dotone/react-ui-core';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { useDispatch } from 'react-redux';
import { bulkUpdateStats } from '@actions';

const FormGroup = ({ label, hintProps, ...otherProps }) => (
  <BsFormGroup row>
    <Col md={4} className="text-left text-md-right">
      <Label className="text-sm text-nowrap">
        {label}
        {hintProps && <AlertPopover {...hintProps} />}
      </Label>
    </Col>
    <Col md={8} {...otherProps} />
  </BsFormGroup>
);

const StatBulkEditForm = ({ ids, onCancel, ...otherProps }) => {
  const dispatch = useDispatch();
  const { handleSubmit, connectForm } = useForm({ defaultValues: {} });

  const onSubmit = (values) => {
    dispatch(bulkUpdateStats(ids, values));
  };

  const { t } = useTranslate('common');

  const { isLoading, status } = useShallowEqualSelector(({ statBulkUpdate }) => statBulkUpdate);

  useEffect(() => {
    if (!isLoading && status === 'success') {
      onCancel();
    }
  }, [isLoading, status, onCancel]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} {...otherProps}>
      <Row className="form-inner">
        {connectForm(
          <Col>
            <Col md={12}>
              <FormGroup label={t('stat:filter.status')}>
                <StatApprovalSelect final name="approval" />
              </FormGroup>
            </Col>
            <Col>
              <FormAction align="right">
                <Button type="submit" variant="save" color="primary" submitting={isLoading}>
                  {t('actions.submit')}
                </Button>
                {onCancel && <ButtonCancel disabled={isLoading} onClick={onCancel} />}
              </FormAction>
            </Col>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default StatBulkEditForm;
