import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useTranslate, useFilterSelector } from '@hooks';
import { fetchTopPublishers } from '@actions';
import moment from 'moment';
import { toDate } from '@dotone/react-ui-core/utils';
import { CardFeatured } from '@dotone/react-ui-core';
import PublisherTable from './PublisherTable';

const TopPublishers = () => {
  const { t } = useTranslate('dashboard', 'topPublishers');
  const dispatch = useDispatch();
  const { currentUser, currency, timeZone } = useCurrentUser();

  const { data, isLoading } = useFilterSelector(({ topPublishers }) => topPublishers, {
    currency,
    timeZone,
  });

  useEffect(() => {
    if (currentUser.id && !data.length) {
      dispatch(fetchTopPublishers({ currency, timeZone }));
    }
  }, [dispatch, currentUser.id, data.length, currency, timeZone]);

  const path = {
    pathname: '/advertisers/reports/affiliate-stats',
    query: {
      startDate: toDate(moment().subtract(29, 'days')),
      endDate: toDate(moment()),
    },
  };

  return (
    <CardFeatured title={t('title')} data={data} loading={isLoading} path={path}>
      <PublisherTable data={data} loading={isLoading} />
    </CardFeatured>
  );
};

export default TopPublishers;
