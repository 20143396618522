import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useShallowEqualSelector } from '@hooks';
import { getAuthToken } from '@dotone/react-ui-core/utils';
import { Container } from 'reactstrap';
import { FlexContainer, LoaderContainer, Footer } from '@dotone/react-ui-core';
import NavbarPublic from './NavbarPublic';

const LayoutPublic = ({ title, navProps, loading, children, ...otherProps }) => {
  const router = useRouter();

  const { isAuthenticated, redirectTo } = useShallowEqualSelector(({ auth }) => auth);

  useEffect(() => {
    if (isAuthenticated || getAuthToken('network')) {
      router.push(redirectTo || '/advertisers');
    }
  }, [router, isAuthenticated, redirectTo]);

  return (
    <>
      <Head>
        <title>Affiliates.One{title && ` - ${title}`}</title>
        <script defer src="https://cdn.vbtrax.com/javascripts/va.js" />
        <script defer src="https://cdn.vbtrax.com/javascripts/va.register.js" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {!isAuthenticated && (
        <>
          <NavbarPublic {...navProps} />
          <FlexContainer className="flex-grow-1" align="center" justify="center">
            <LoaderContainer loading={loading}>
              <Container fluid className="py-4" {...otherProps}>
                {children}
              </Container>
            </LoaderContainer>
          </FlexContainer>
          <Footer className="text-center p-3" />
        </>
      )}
      {isAuthenticated && <LoaderContainer loading />}
    </>
  );
};

export default LayoutPublic;
