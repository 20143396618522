import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useTranslate, useShallowEqualSelector } from '@hooks';
import { updateMissingOrder } from '@actions';
import {
  missingOrderStatusColorMap as colorMap,
  missingOrderStatuses as statuses,
} from '@dotone/react-ui-core/constants';
import { missingOrderStatusSummaries } from '@constants';
import classnames from 'classnames';
import { camelCase, first } from 'lodash';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { FormAction, Input, Button, ButtonCancel, CurrencyInput } from '@dotone/react-ui-core';
import { MissingOrderSummarySelect } from '../forms';

const MissingOrderApprovalForm = ({ defaultValues = {}, type, onCancel, otherProps }) => {
  const { t } = useTranslate('missingOrder', 'model');
  const dispatch = useDispatch();
  const payout = first(defaultValues.offer.payouts);
  const { isLoading, status } = useShallowEqualSelector(
    ({ missingOrderUpdate }) => missingOrderUpdate
  );

  const calculateTruePay = useCallback(
    (orderTotalAmount) =>
      payout?.convType === 'CPS' ? (payout.value * orderTotalAmount) / 100 : payout.value,
    [payout?.convType, payout.value]
  );

  const { handleSubmit, connectForm, setValue, watch } = useForm({
    defaultValues: {
      ...defaultValues,
      statusReason:
        type === statuses.REJECTED ? t('form.statusReasonRejected') : defaultValues.statusReason,
      status: type,
      truePay: calculateTruePay(defaultValues.orderTotal),
    },
  });
  const statusSummaries = missingOrderStatusSummaries[type];
  const total = watch('orderTotal');
  const statusSummary = watch('statusSummary');

  const handleSubmitForm = ({ id, ...values }) => {
    const newValues = {
      ...values,
      statusSummary:
        type === statuses.APPROVED ? statusSummaries.ORDER_ADDED : values.statusSummary,
    };
    dispatch(updateMissingOrder(id, newValues));
  };

  useEffect(() => {
    if (status === 'success' && onCancel) onCancel();
  }, [status, onCancel]);

  useEffect(() => {
    setValue('truePay', calculateTruePay(total));
  }, [total, setValue, calculateTruePay]);

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} {...otherProps}>
      <div className={`border border-${colorMap[type]}`}>
        <div className={`bg-${colorMap[type]} text-white mb-3 p-2`}>
          {t(`form.${camelCase(type)}`)}
        </div>
        <div className="mx-3">
          <div className="form-inner">
            {connectForm(
              <>
                <FormGroup row>
                  <Col md={6}>
                    <Label>{t('form.orderTotal')}*</Label>
                    <CurrencyInput
                      name="orderTotal"
                      placeholder={t('form.orderTotal')}
                      rules={{ required: true }}
                      decimalScale={2}
                    />
                  </Col>
                  <Col md={6}>
                    <Label>{t('order:model.truePay')}</Label>
                    <CurrencyInput
                      name="truePay"
                      placeholder={t('order:model.truePay')}
                      rules={{ required: true }}
                      decimalScale={2}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={12}>
                    <Label>{t('statusSummary')}*</Label>
                    <MissingOrderSummarySelect
                      name="statusSummary"
                      type={type}
                      rules={{ required: true }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  {(statusSummary === statusSummaries.OTHERS ||
                    statusSummary === statusSummaries.ORDER_ADDED_DETAILED) && (
                    <Col md={12}>
                      <Label>{t('form.statusReason')}*</Label>
                      <Input
                        type="textarea"
                        name="statusReason"
                        rules={{ required: true }}
                        rows={6}
                      />
                    </Col>
                  )}
                </FormGroup>
              </>
            )}
          </div>
        </div>
      </div>
      <FormAction align="right">
        <p
          className={classnames('text-danger d-none text-left', {
            'd-block': type === statuses.APPROVED,
          })}
        >
          {t('missingOrder:hints.attention')}
        </p>
        <Button type="submit" variant="save" color="primary" submitting={isLoading}>
          {t('common:actions.submit')}
        </Button>
        {onCancel && <ButtonCancel disabled={isLoading} onClick={onCancel} />}
      </FormAction>
    </Form>
  );
};

export default MissingOrderApprovalForm;
