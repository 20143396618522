import React from 'react';
import { useTranslate } from '@hooks';
import { omit } from 'lodash';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import {
  FormFilter as Form,
  SubsectionTitle,
  DateRangePicker,
  StatApprovalSelect,
} from '@dotone/react-ui-core';
import { OfferSearchInput } from '../forms';

const StatFilterForm = ({ defaultValues: givenDefaultValues, ...otherProps }) => {
  const { t } = useTranslate('stat', 'filter');

  const defaultValues = omit(givenDefaultValues, ['searchKey']);

  return (
    <Form dateable defaultValues={defaultValues} {...otherProps}>
      <Row>
        <Col lg={12}>
          <SubsectionTitle>{t('common:filterBy')}</SubsectionTitle>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('common:date.date')}</Label>
            <DateRangePicker names={{ from: 'startDate', to: 'endDate' }} />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('offers')}</Label>
            <OfferSearchInput multi name="offerIds" />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('status')}</Label>
            <StatApprovalSelect multi name="approvals" />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default StatFilterForm;
