import useTranslation from 'next-translate/useTranslation';
import { useTranslate as useSharedTranslate } from '@dotone/react-ui-core/hooks';
import { snakeCase } from 'lodash';
import { translate } from '@dotone/react-ui-core/utils';

// Get translation directly from nested translation
const useTranslate = (defaultNs, nestedNs, tOptions = {}) => {
  const { t: tDefault, lang } = useTranslation(defaultNs);
  const { t: tShared } = useSharedTranslate(defaultNs, nestedNs);
  const { fallbackNs } = tOptions;

  const t = (key, query, options = {}) => {
    return translate(key, query, { ...options, ...{ tDefault, tShared, nestedNs, fallbackNs } });
  };

  const tHint = (givenKey, query = {}, options = {}) => {
    const { id, ...otherQuery } = query;
    const overriden = givenKey.indexOf(':') > -1;
    let key = givenKey;

    if (!overriden) {
      if (nestedNs) key = [nestedNs, key].join('.');
      key = `${defaultNs}:${key}`;
    }

    const title = t(`${key}.title`, otherQuery, options);
    const content = t(`${key}.content`, otherQuery, options);
    let result = { tKey: key, title, content };

    if (id) result = { ...result, target: `${snakeCase(key)}-${id}` };

    return result;
  };

  return {
    lang,
    locale: lang,
    t,
    tHint,
    tHtml: (key, query = {}, options = {}) => t(key, { ...query, html: true }, options),
    tShared,
  };
};

export default useTranslate;
