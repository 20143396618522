import React from 'react';
import { localeNames } from '@dotone/react-ui-core/constants';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import {
  DateTime,
  FileStatusBadge,
  Currency,
  CreativeTimePeriod,
  ButtonCircle,
  AffiliateLogPreview,
  OfferVariantStatusBadge,
  ConversionTypeBadge,
  Text,
} from '@dotone/react-ui-core';

export const formatCreativeTimePeriod = (creative, props) =>
  creative ? <CreativeTimePeriod creative={creative} {...props} /> : null;

export const formatFileStatus = (cell) => <FileStatusBadge className="d-block" value={cell} />;

export const formatDateTime = () =>
  function RenderDateTime(cell) {
    return cell && <DateTime value={cell} className="text-nowrap" />;
  };

export const formatCurrency = (currency) =>
  function RenderCurrency(cell) {
    return <Currency round={false} currency={currency} value={cell} />;
  };

export const formatNotes = ({ cell, row, onNoteOpen }) => (
  <>
    <div className="td-overlay p-2">
      <ButtonCircle variant="add" color="primary" size="sm" onClick={() => onNoteOpen(row)} />
    </div>
    <AffiliateLogPreview values={cell} />
  </>
);

export const formatAffiliateName = (affiliate, { currentUser }) => {
  if (currentUser.pro || affiliate.direct) return getNameWithId(affiliate);
  if (currentUser.partialPro) return getNameWithId(affiliate, 'firstName');
  return affiliate.id;
};

export const formatOfferStatus = (cell) => cell && <OfferVariantStatusBadge value={cell} />;

export const formatConvType = (cell) => cell && <ConversionTypeBadge value={cell} />;

export const formatDaysReturn = (cell, { order }, t) => {
  if (!order) return null;

  const { daysSinceOrder, daysReturnPastDue } = order;

  return daysReturnPastDue ? t('confirmOrder') : cell - daysSinceOrder;
};

export const formatCreativeLocales = (cell) =>
  cell?.map((locale) => (
    <Text key={locale} nowrap>
      {localeNames[String(locale)]}
    </Text>
  ));
