import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocalizedDataSelector, useTranslate } from '@hooks';
import { fetchCategoryGroups } from '@actions';
import { toSelectOptions } from '@dotone/react-ui-core/utils';
import { Input } from '@dotone/react-ui-core';

const CategoryGroupSelect = (props) => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const categoryGroups = useLocalizedDataSelector(({ categoryGroupList }) => categoryGroupList);
  const {
    formProps: { watch },
    name,
    rules: { maxLength },
  } = props;

  const value = watch(name);

  const options = toSelectOptions(categoryGroups, { label: 'name' });

  useEffect(() => {
    if (dispatch && locale && !categoryGroups.length) {
      dispatch(fetchCategoryGroups(locale));
    }
  }, [dispatch, locale, categoryGroups.length]);

  return (
    <Input
      type="select"
      options={options}
      isOptionDisabled={() => value && maxLength && value.length >= maxLength}
      {...props}
    />
  );
};

export default CategoryGroupSelect;
