import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useFilterSelector, useTranslate } from '@hooks';
import { fetchPerformanceStats } from '@actions';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { toNumberWithDelimeter } from '@dotone/react-ui-core/utils';
import { ButtonGroup, Container, Row, Col } from 'reactstrap';
import { ChevronUp, ChevronDown } from 'react-feather';
import {
  FlexContainer,
  Button,
  Text,
  CardFeatured,
  Currency,
  Percentage,
} from '@dotone/react-ui-core';

const durations = ['day', 'month', 'year'];

const DataDisplay = ({
  label,
  value,
  growth,
  tHintKey,
  isCurrency = false,
  showGrowth = false,
}) => {
  const { tHint } = useTranslate();
  const Icon = growth >= 0 ? ChevronUp : ChevronDown;
  const color = growth >= 0 ? 'text-success' : 'text-danger';

  return (
    <Col className="text-center mt-3 mb-sm-5 mb-md-0 mx-2">
      <Text
        hintProps={tHintKey ? { ...tHint(`dashboard:hints.${tHintKey}`) } : null}
        className="mb-1 text-nowrap"
      >
        {label}
      </Text>
      <h4 className={`font-weight-bold ${growth > 0 && 'text-info'}`}>
        {isCurrency ? <Currency value={value} /> : toNumberWithDelimeter(value, { round: true })}
      </h4>
      <h4 className={classnames(color, 'text-nowrap')}>
        {showGrowth && (
          <>
            {<Icon className="mr-1" />}
            <Percentage value={growth} className="d-inline-block" />
          </>
        )}
      </h4>
    </Col>
  );
};

const PerformanceStats = () => {
  const { t, tHint } = useTranslate('dashboard', 'performanceStatistics');
  const dispatch = useDispatch();
  const [duration, setDuration] = useState('day');
  const { currentUser, currency, timeZone } = useCurrentUser();

  const { data, isLoading } = useFilterSelector(({ performanceStats }) => performanceStats, {
    currency,
    timeZone,
  });

  const empty = isEmpty(data);
  const { current, previous, growth } = data;

  const handleDurationChange = (value) => {
    setDuration(value);
    dispatch(fetchPerformanceStats({ duration: value, currency, timeZone }));
  };

  useEffect(() => {
    if (currentUser.id && empty) {
      dispatch(fetchPerformanceStats({ duration, currency, timeZone }));
    }
  }, [dispatch, currentUser.id, empty, duration, currency, timeZone]);

  const footer = (
    <Text muted size="xs">
      {t('statUpdated')}
    </Text>
  );

  return (
    <CardFeatured
      title={t('title')}
      hintProps={tHint('dashboard:hints.performanceStatistics')}
      data={data}
      loading={isLoading}
      footer={footer}
      actions={
        <ButtonGroup className="ml-auto">
          {durations.map((option) => (
            <Button
              key={option}
              outline={duration !== option}
              color="primary"
              active={duration === option}
              disabled={isLoading}
              className="btn-rounded px-3"
              onClick={() => handleDurationChange(option)}
            >
              {t(option)}
            </Button>
          ))}
        </ButtonGroup>
      }
    >
      <div className="py-3 custom-overflow-x">
        <FlexContainer className="ml-3">
          {duration === 'day' && <Text weight="bold">{t('today')}</Text>}
          {duration === 'year' && <Text weight="bold">{t('thisYear')}</Text>}
          {duration === 'month' && <Text weight="bold">{t('thisMonth')}</Text>}
        </FlexContainer>
        <Container fluid>
          <Row className="flex-lg-nowrap flex-wrap">
            <DataDisplay
              label={t('clicks')}
              showGrowth
              tHintKey="performanceStatisticsClicks"
              value={current?.clicks}
              growth={growth?.clicks}
            />
            <DataDisplay
              label={t('pendingOrders')}
              showGrowth
              tHintKey="performanceStatisticsPendingOrders"
              value={current?.pendingConversions}
              growth={growth?.pendingConversions}
            />
            <DataDisplay
              label={t('confirmedOrders')}
              showGrowth
              tHintKey="performanceStatisticsConfirmedOrders"
              value={current?.confirmedConversions}
              growth={growth?.confirmedConversions}
            />
            <DataDisplay
              label={t('totalOrders')}
              showGrowth
              tHintKey="performanceStatisticsTotalOrders"
              value={current?.totalConversions}
              growth={growth?.totalConversions}
            />
            <DataDisplay
              label={t('revenue')}
              isCurrency
              showGrowth
              tHintKey="performanceStatisticsRevenue"
              value={current?.orderTotal}
              growth={growth?.orderTotal}
            />
          </Row>
        </Container>
        <FlexContainer className="ml-3">
          {duration === 'day' && <Text weight="bold">{t('yesterday')}</Text>}
          {duration === 'year' && <Text weight="bold">{t('previousYear')}</Text>}
          {duration === 'month' && <Text weight="bold">{t('previousMonth')}</Text>}
        </FlexContainer>
        <Container fluid>
          <Row className="flex-lg-nowrap flex-wrap">
            <DataDisplay label={t('clicks')} value={previous?.clicks} />
            <DataDisplay label={t('pendingOrders')} value={previous?.pendingConversions} />
            <DataDisplay label={t('confirmedOrders')} value={previous?.confirmedConversions} />
            <DataDisplay label={t('totalOrders')} value={previous?.totalConversions} />
            <DataDisplay label={t('revenue')} isCurrency value={previous?.orderTotal} />
          </Row>
        </Container>
      </div>
    </CardFeatured>
  );
};

export default PerformanceStats;
