import { dashboardTypes as actionTypes } from '@actions';
import { commonState, commonReducer } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default(),
};

const collectionState = {
  ...commonState.default({ collection: true }),
};

export const accountOverview = (state = defaultState, action) =>
  commonReducer(actionTypes.ACCOUNT_OVERVIEW, state, action);

export const performanceStats = (state = defaultState, action) =>
  commonReducer(actionTypes.PERFORMANCE_STATS, state, action);

export const exposures = (state = defaultState, action) =>
  commonReducer(actionTypes.EXPOSURES, state, action);

export const commissionBalances = (state = defaultState, action) =>
  commonReducer(actionTypes.COMMISSION_BALANCES, state, action);

export const recentConversions = (state = collectionState, action) =>
  commonReducer(actionTypes.RECENT_CONVERSIONS, state, action);

export const recentFeedsAffiliate = (state = collectionState, action) =>
  commonReducer(actionTypes.RECENT_FEEDS_AFFILIATE, state, action);

export const recentFeedsNetwork = (state = collectionState, action) =>
  commonReducer(actionTypes.RECENT_FEEDS_NETWORK, state, action);

export const topPublishers = (state = collectionState, action) =>
  commonReducer(actionTypes.TOP_PUBLISHERS, state, action);

export const totalVisitors = (state = defaultState, action) =>
  commonReducer(actionTypes.TOTAL_VISITORS, state, action);

export const monthlyStats = (state = defaultState, action) =>
  commonReducer(actionTypes.MONTHLY_STATS, state, action);
