import React, { useState } from 'react';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useSearchSelector } from '@hooks';
import { searchOffers } from '@actions';
import { OfferApi } from '@api';
import { flatten, compact } from 'lodash';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import { AsyncSelect } from '@dotone/react-ui-core';

const OfferSearchInput = ({
  name,
  formProps,
  active = false,
  searchParams = {},
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const { locale } = useCurrentUser();

  const { getValues } = formProps;
  const ids = compact(flatten([getValues(name)]));
  const query = { locale, active, ...searchParams };
  const { data: defaultValue, status } = useSearchSelector(({ offerSearch }) => offerSearch, {
    ids,
    ...query,
  });

  const formatData = (data) => data.map((item) => ({ value: item.id, label: getNameWithId(item) }));

  const handleLoad = (search) => OfferApi.search({ search, limit: 20, ...query });

  useDeepCompareEffect(() => {
    if (ids.length && !defaultValue.length) {
      dispatch(searchOffers({ ids, ...query }));
    }
  }, [dispatch, ids, defaultValue.length, query]);

  useEffectOnce(() => {
    if (!ids.length) {
      handleLoad()
        .then((response) => {
          setOptions(formatData(response.data));
        })
        .catch(() => {});
    }
  });

  return (
    (status === 'success' || !ids.length) && (
      <AsyncSelect
        simple
        name={name}
        defaultValue={defaultValue}
        options={options}
        formatter={formatData}
        onOptionsChange={setOptions}
        onLoad={handleLoad}
        formProps={formProps}
        {...otherProps}
      />
    )
  );
};

export default React.memo(OfferSearchInput);
