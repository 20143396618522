import API from './api';

export default class MissingOrderApi {
  static download(params) {
    return API.post('/advertisers/missing_orders/download', params);
  }

  static getList(params) {
    return API.get('/advertisers/missing_orders', params);
  }

  static update(id, params) {
    return API.put(`/advertisers/missing_orders/${id}`, params);
  }
}
