import React from 'react';
import { useTranslate } from '@hooks';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import { Table, NextLink as Link } from '@dotone/react-ui-core';

const formatLink = (ageFirst, ageLast) =>
  function RenderLink(cell, row) {
    return (
      <Link
        href={{
          pathname: `/advertisers/transactions/pending/by-offer/${row.offerId}`,
          query: { ageFirst, ageLast },
        }}
      >
        {cell}
      </Link>
    );
  };

const formatOffer = (cell, row) => getNameWithId(row.offer);

const getColumns = ({ t }) => [
  {
    dataField: 'offerId',
    text: t('offer'),
    formatter: formatOffer,
  },
  {
    dataField: 'zeroToThirtyDays',
    text: t('zeroToThirtyDays'),
    headerAlign: 'right',
    classes: 'text-right',
    formatter: formatLink(0, 30),
  },
  {
    dataField: 'thirtyToSixtyDays',
    text: t('thirtyToSixtyDays'),
    formatter: formatLink(31, 60),
    headerAlign: 'right',
    classes: 'text-right',
  },
  {
    dataField: 'sixtyToOneEightyDays',
    text: t('sixtyToOneEightyDays'),
    formatter: formatLink(61, 180),
    headerAlign: 'right',
    classes: 'text-right',
  },
  {
    dataField: 'oneEightyAndOlderDays',
    text: t('oneEightyAndOlderDays'),
    formatter: formatLink(181, 730),
    headerAlign: 'right',
    classes: 'text-right',
  },
];

const StatPendingTable = (props) => {
  const { t } = useTranslate('stat', 'pending');

  const columns = getColumns({ t });

  const tableProps = {
    classes: 'text-sm stylish-table',
    columns,
    responsive: true,
    condensed: true,
    hover: true,
    keyField: 'offerId',
    remote: true,
    ...props,
  };

  return <Table {...tableProps} />;
};

export default StatPendingTable;
