import API from './api';

export default class AffiliateApi {
  static getList(params) {
    return API.get('/advertisers/affiliates', params);
  }

  static update(id, params) {
    return API.put(`/advertisers/affiliates/${id}`, { affiliate: params });
  }

  static getRecent(params) {
    return API.get('advertisers/affiliates/recent', params);
  }

  static search(params) {
    return API.get('/advertisers/affiliates/search', params);
  }
}
