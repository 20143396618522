import { userTypes as actionTypes } from '@actions';
import { commonState, commonReducer } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default(),
};

export const currentUser = (state = defaultState, action) =>
  commonReducer(actionTypes.CURRENT, state, action);

export const userRegister = (state = defaultState, action) =>
  commonReducer(actionTypes.REGISTER, state, action);

export const userUpdate = (state = defaultState, action) =>
  commonReducer(actionTypes.UPDATE, state, action);
