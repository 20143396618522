import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useLocalizedDataSelector } from '@hooks';
import { fetchTimezones } from '@actions';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const TimezoneDropdown = ({ nav = true, value, onChange, ...otherProps }) => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const timezones = useLocalizedDataSelector(({ timezoneList }) => timezoneList);
  const current = timezones.find((timezone) => timezone.gmt === value);

  useEffect(() => {
    if (!timezones.length) {
      dispatch(fetchTimezones(locale));
    }
  }, [dispatch, locale, timezones.length]);

  return (
    !!current && (
      <UncontrolledDropdown nav={nav} {...otherProps}>
        <DropdownToggle nav={nav} caret>
          {current.gmtStringShort}
        </DropdownToggle>
        <DropdownMenu right className="pre-scrollable">
          {timezones.map((timezone) => (
            <DropdownItem
              key={timezone.id}
              disabled={current.id === timezone.id}
              onClick={() => onChange(timezone.id)}
            >
              {timezone.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  );
};

export default React.memo(TimezoneDropdown);
