import API from './api';

const requestOptions = { dateTimeParams: ['activeDateStart', 'activeDateEnd'] };

export default class ImageCreativeApi {
  static getList(params) {
    return API.get('/advertisers/image_creatives', params);
  }

  static create(params) {
    return API.post('/advertisers/image_creatives', params, {}, requestOptions);
  }

  static update(id, params) {
    return API.put(`/advertisers/image_creatives/${id}`, params, {}, requestOptions);
  }

  static bulkUpdate(params) {
    return API.put('/advertisers/image_creatives/update_bulk', params, {}, requestOptions);
  }
}
