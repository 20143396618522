import React, { useState } from 'react';
import { useTranslate } from '@hooks';
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Collapse } from 'reactstrap';
import { offerVariantStatuses } from '@dotone/react-ui-core/constants';
import { FlexContainer, OfferVariantStatusBadge } from '@dotone/react-ui-core';
import { ChevronDown, ChevronUp } from 'react-feather';
import OfferCardInfo from './OfferCardInfo';
import OfferCardSidebar from './OfferCardSidebar';

const OfferCard = ({ offer, ...otherProps }) => {
  const isSuspended = offer.status === offerVariantStatuses.SUSPENDED;
  const [isOpen, setIsOpen] = useState(!isSuspended);
  const { t } = useTranslate('common');

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card {...otherProps}>
      <CardHeader onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
        <FlexContainer justify="space-between">
          <CardTitle>
            <span className="mr-3">{offer.id}</span>
            <span>{offer.name}</span>
          </CardTitle>
          <CardTitle>
            <span className="mr-1">{t('status')}:</span>
            <OfferVariantStatusBadge value={offer.status} className="text-rg" />
            <span className="ml-2">{isOpen ? <ChevronDown /> : <ChevronUp />}</span>
          </CardTitle>
        </FlexContainer>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <Row>
            <Col sm={2}>
              <OfferCardSidebar offer={offer} />
            </Col>
            <Col sm={10}>
              <OfferCardInfo offer={offer} />
            </Col>
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default OfferCard;
