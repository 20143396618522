import { ChatRoomApi } from '@api';
import { chatRoomTypes as actionTypes } from './types';

export const fetchChatRooms = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  ChatRoomApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const createChatRoom = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  ChatRoomApi.create(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'prepend',
      });

      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload: response,
        message: 'created',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        message: error.statusText,
      });
    });
};

export const getChatRoom = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.GET.LOADING,
  });

  ChatRoomApi.get(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.GET.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
