import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import { affiliateLogTypes, authTypes } from '@actions';
import { find } from 'lodash';
import { addToList } from '@dotone/react-ui-core/utils';
import * as RootReducers from './reducers';

const bindMiddleware = (middleware) => applyMiddleware(...middleware);

const combinedReducer = combineReducers(RootReducers);

const reducer = (state, action) => {
  let nextState = { ...state };

  if (action.type === authTypes.DEAUTH) {
    return combinedReducer(undefined, action);
  }

  if (action.type === affiliateLogTypes.CREATE.SUCCESS) {
    const log = action.payload.data;

    if (log.ownerType === 'Affiliate') {
      let affiliate = find(nextState.affiliateList.data, { id: log.ownerId });
      if (affiliate) {
        affiliate = { ...affiliate, logs: [log, ...affiliate.logs] };

        nextState = {
          ...nextState,
          affiliateList: {
            ...nextState.affiliateList,
            data: addToList(nextState.affiliateList.data, affiliate),
          },
        };
      }
    } else if (log.ownerType === 'AffiliateOffer') {
      let campaign = find(nextState.campaignList.data, { id: log.ownerId });
      if (campaign) {
        campaign = { ...campaign, logs: [log, ...campaign.logs] };

        nextState = {
          ...nextState,
          campaignList: {
            ...nextState.campaignList,
            data: addToList(nextState.campaignList.data, campaign),
          },
        };
      }
    }
  }

  return combinedReducer(nextState, action);
};

const initStore = () => createStore(reducer, bindMiddleware([thunkMiddleware]));

const wrapper = createWrapper(initStore);

export default wrapper;
