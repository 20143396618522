import React, { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useDispatch } from 'react-redux';
import { useForm } from '@dotone/react-ui-core/hooks';
import {
  useTranslate,
  useCurrentUser,
  useShallowEqualSelector,
  useLocalizedDataSelector,
  useSearchSelector,
} from '@hooks';
import { createTextCreative, updateTextCreative } from '@actions';
import classnames from 'classnames';
import { Row, Col, Form } from 'reactstrap';
import {
  FormGroup as BsFormGroup,
  Input,
  AlertPopover,
  DateTimeSelect,
  ActivePeriodDateTimePicker,
  ControlGroup,
  ModalWithInitialButton as Modal,
  LocaleCheckInput,
  FileUploader,
  TextCreativePreview,
  TextCreativeDealScopeRadioGroup,
  CardBasic,
  Label,
  DeepLinkInput,
} from '@dotone/react-ui-core';
import { CategorySelect, CurrencySelect, OfferSearchInput, OfferVariantRadioGroup } from '../forms';

const FormGroup = ({ label, required = false, hintProps, ...otherProps }) => (
  <BsFormGroup row>
    <Col md={4} className="text-left text-md-right">
      <Label className="text-custom text-nowrap">
        {required && '*'}
        {label}
        {hintProps && <AlertPopover {...hintProps} />}
      </Label>
    </Col>
    <Col md={8} {...otherProps} />
  </BsFormGroup>
);

const TextCreativeFormButton = ({ textCreative = {}, buttonProps, ...otherProps }) => {
  const dispatch = useDispatch();
  const { t, locale } = useTranslate('textCreative', 'model');
  const { currentUser } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const currencies = useLocalizedDataSelector(({ currencyList }) => currencyList);

  const isEdit = !!textCreative.id;

  const defaultValues = isEdit
    ? {
        ...textCreative,
        offerVariantId: textCreative.offerVariantId?.toString(),
      }
    : {};

  const { handleSubmit, connectForm, reset, watch, setValue } = useForm({ defaultValues });

  const { submitting, isSubmitSuccess } = useShallowEqualSelector(
    ({ textCreativeCreate: create, textCreativeUpdate: update }) => ({
      isSubmitSuccess: isEdit ? update.status === 'success' : create.status === 'success',
      submitting: isEdit ? update.isLoading : create.isLoading,
    })
  );

  const [
    offerId,
    offerVariantId,
    title,
    content1,
    couponCode,
    originalPrice,
    discountPrice,
    buttonText,
    currencyId,
    imageUrl,
  ] = watch([
    'offerId',
    'offerVariantId',
    'title',
    'content1',
    'couponCode',
    'originalPrice',
    'discountPrice',
    'buttonText',
    'currencyId',
    'imageUrl',
  ]);

  const { data: offers } = useSearchSelector(({ offerSearch }) => offerSearch, {
    ids: [offerId],
    locale,
    active: !isEdit,
    offerVariants: true,
  });

  const creative = {
    buttonText,
    content1,
    couponCode,
    discountPrice,
    imageUrl,
    originalPrice,
    title,
    currency: currencies.find((currency) => currency.id.toString() === currencyId),
  };

  // Setup for toggling offer variant selection
  const selectedOffer =
    offerId && offers.find((offer) => offer.id.toString() === offerId.toString());
  const offerVariants = selectedOffer?.offerVariants || [];
  const offerVariant = offerVariants.find((x) => String(x.id) === String(offerVariantId));
  const destinationUrl = selectedOffer?.destinationUrl || offerVariant?.destinationUrl;

  const handleSubmitForm = (values) => {
    const params = { ...values, imageCdn: values.imageUrl };

    if (isEdit) {
      dispatch(updateTextCreative(textCreative.id, params));
    } else {
      dispatch(createTextCreative(params));
    }
  };

  const onImageUpload = (values) => {
    setValue('imageUrl', values.cdnUrl);
  };

  useDeepCompareEffect(() => {
    // If only one offer variant available, auto-select it
    if (selectedOffer?.id && offerVariants.length === 1) {
      reset({
        ...defaultValues,
        offerVariantId: offerVariants[0].id.toString(),
        offerId: selectedOffer?.id,
      });
    }
  }, [offerVariants, selectedOffer?.id, defaultValues, reset]);

  useEffect(() => {
    if (!submitting && isSubmitSuccess) {
      setIsOpen(false);
    }
  }, [submitting, isSubmitSuccess]);

  useEffect(() => {
    const ids = selectedOffer?.categories?.map(({ id }) => id) || [];
    if (ids.length > 0 && !textCreative.id) {
      setValue('categoryIds', ids);
    }
  }, [selectedOffer?.categories, setValue, textCreative.id]);

  return (
    <Modal
      dense
      size="fullscreen"
      title={isEdit ? t('textCreative:actions.edit') : t('textCreative:actions.add')}
      buttonProps={{
        className: classnames({ 'btn-rounded': !isEdit, 'p-0 text-primary': isEdit }),
        color: isEdit ? 'link' : 'primary',
        size: 'sm',
        id: isEdit ? `edit-text-creative-button-${textCreative.id}` : '',
        title: isEdit && t('::textCreative:actions.edit'),
        variant: isEdit ? 'edit' : 'add',
        ...buttonProps,
      }}
      scrollable
      footer
      footerVariant="submit"
      submitting={submitting}
      onSubmit={handleSubmit(handleSubmitForm)}
      isOpen={isOpen}
      onToggle={setIsOpen}
    >
      <Form {...otherProps}>
        {connectForm(
          <Row>
            <Col xs={12} lg={5}>
              <CardBasic className="border border-light " title={t('landingPageTitle')}>
                <FormGroup label={t('locales')} required>
                  <LocaleCheckInput name="locales" rules={{ required: true }} />
                </FormGroup>
                <FormGroup label={t('offer')} required>
                  <OfferSearchInput
                    name="offerId"
                    active={!isEdit}
                    searchParams={{ offerVariants: true }}
                    required
                  />
                </FormGroup>
                {!!offerVariants.length && (
                  <FormGroup label={t('::common:model.offerVariant')} required>
                    <OfferVariantRadioGroup
                      name="offerVariantId"
                      offerVariants={offerVariants}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                )}
                <FormGroup label={t('dealScope')} required>
                  <TextCreativeDealScopeRadioGroup
                    name="dealScope"
                    className="d-inline-block mr-3"
                    rules={{ required: true }}
                  />
                </FormGroup>
                <FormGroup label={t('categoryIds')}>
                  <ControlGroup name="categoryIds">
                    <CategorySelect name="categoryIds" multi rules={{ maxLength: 5 }} />
                  </ControlGroup>
                </FormGroup>
              </CardBasic>
              <CardBasic className="border border-light " title={t('campaignPeriodTitle')}>
                <FormGroup label={t('publishedAt')}>
                  <DateTimeSelect
                    name="publishedAt"
                    closeOnSelect={false}
                    future
                    timeFormat="HH:mm"
                    inputProps={{ autoComplete: 'off' }}
                  />
                </FormGroup>
                <ActivePeriodDateTimePicker
                  names={{
                    start: 'activeDateStart',
                    end: 'activeDateEnd',
                    infinity: 'isInfinityTime',
                  }}
                />
              </CardBasic>
              <CardBasic className="border border-light " title={t('creativeTitle')}>
                <FormGroup label={t('creativeName')} required>
                  <Input
                    type="text"
                    name="creativeName"
                    rules={{ required: true, maxLength: 20 }}
                  />
                </FormGroup>
                <FormGroup label={t('title')} required>
                  <Input type="text" name="title" rules={{ required: true, maxLength: 20 }} />
                </FormGroup>
                <FormGroup label={t('content1')} required>
                  <Input type="text" name="content1" rules={{ required: true, maxLength: 25 }} />
                </FormGroup>
                <FormGroup label={t('couponCode')}>
                  <Input type="text" name="couponCode" />
                </FormGroup>
                <FormGroup label={t('currency')}>
                  <CurrencySelect name="currencyId" />
                </FormGroup>
                <FormGroup label={t('originalPrice')}>
                  <Input type="text" name="originalPrice" />
                </FormGroup>
                <FormGroup label={t('discountPrice')}>
                  <Input type="text" name="discountPrice" />
                </FormGroup>
                {offerVariant?.deeplinkable && (
                  <FormGroup label={t('customLandingPage')} required>
                    <DeepLinkInput
                      name="customLandingPage"
                      domain={destinationUrl}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                )}
                <FormGroup label={t('buttonText')} required>
                  <Input type="text" name="buttonText" rules={{ required: true }} />
                </FormGroup>
              </CardBasic>
              <CardBasic className="border border-light" title={t('imageUploadTitle')}>
                <FileUploader
                  image
                  path={`text_creative/network/${currentUser.id}`}
                  onSuccess={onImageUpload}
                  previewLinks={imageUrl}
                />
                <Input type="hidden" name="imageUrl" rules={{ required: true }} />
              </CardBasic>
            </Col>
            <Col xs={12} lg={7}>
              <TextCreativePreview creative={creative} />
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default TextCreativeFormButton;
