import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@hooks';
import { deauthenticate } from '@actions';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  LogOut as LogoutIcon,
  User as UserIcon,
  DollarSign as DollarSignIcon,
} from 'react-feather';
import { FlexContainer, Text, ImageCircle, NextLink } from '@dotone/react-ui-core';

const AccountDropdown = ({ nav = false, currentUser, ...otherProps }) => {
  const { t } = useTranslate('common', 'navbar');
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(deauthenticate());
  };

  return (
    <UncontrolledDropdown nav={nav} {...otherProps}>
      <DropdownToggle nav={nav} caret={!nav}>
        {nav && (
          <FlexContainer align="center">
            <Text className="mr-2 text-truncate" style={{ maxWidth: 80 }}>
              {currentUser.name || currentUser.contactName}
            </Text>
            <ImageCircle avatar src={currentUser.avatarCdnUrl} />
          </FlexContainer>
        )}
        {!nav && <Text truncate>{currentUser.name || currentUser.contactName}</Text>}
      </DropdownToggle>
      <DropdownMenu right>
        {nav && (
          <>
            <FlexContainer className="p-3 mb-2" background="primary" align="center" color="white">
              <ImageCircle avatar src={currentUser.avatarCdnUrl} width={60} />
              <div className="ml-2">
                <h4 className="text-nowrap m-0">{currentUser.name || currentUser.contactName}</h4>
                <div>{currentUser.contactEmail}</div>
                <div>ID: {currentUser.id}</div>
              </div>
            </FlexContainer>
          </>
        )}
        <DropdownItem tag={NextLink} href="/advertisers/profile">
          <UserIcon size={18} className="text-primary mr-2" />
          {t('myProfile')}
        </DropdownItem>
        <DropdownItem tag={NextLink} href="/advertisers/balances">
          <DollarSignIcon size={18} className="text-primary mr-2" />
          {t('myBalance')}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={handleLogout}>
          <LogoutIcon size={18} className="text-danger mr-2" />
          {t('logout')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default React.memo(AccountDropdown);
