import { createSelector } from 'reselect';
import useShallowEqualSelector from './useShallowEqualSelector';

export default function useSearchSelector(selector, params = {}) {
  const searchKey = JSON.stringify(params);
  const searchSelector = createSelector(selector, (state) => {
    return { ...state, data: state.data[searchKey] || [] };
  });

  return useShallowEqualSelector(searchSelector);
}
