import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocalizedDataSelector, useTranslate } from '@hooks';
import { fetchMediaCategories } from '@actions';
import { sortBy } from 'lodash';
import { Input } from '@dotone/react-ui-core';

const MediaCategorySelect = (props) => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const data = useLocalizedDataSelector(({ mediaCategoryList }) => mediaCategoryList);

  let options = [];

  data.forEach((mediaCategory) => {
    const childOptions = mediaCategory.childCategories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
    options.push({
      label: mediaCategory.name,
      options: sortBy(childOptions, ['label']),
    });
  });

  options = sortBy(options, ['label']);

  useEffect(() => {
    if (!data.length) {
      dispatch(fetchMediaCategories(locale));
    }
  }, [dispatch, data.length, locale]);

  return <Input type="select" grouped options={options} {...props} />;
};

export default MediaCategorySelect;
