import { campaignTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true }),
  pagination,
};

const defaultState = {
  ...commonState.default(),
};

export const campaignList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const campaignUpdate = (state = defaultState, action) =>
  commonReducer(actionTypes.UPDATE, state, action);
