import React from 'react';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { useTranslate } from '@hooks';
import { ExternalLink, ImageCircle, TextBlock } from '@dotone/react-ui-core';
import ModalAvatar from './UserModalAvatar';
import ModalPassword from './UserModalPassword';

const UserCardSidebar = ({ user, ...otherProps }) => {
  const { t } = useTranslate('network', 'model');
  const {
    avatarCdnUrl,
    brands,
    categoryGroups,
    contactName,
    companyUrl,
    contactPhone,
    country,
    contactEmail,
    name,
  } = user;

  return (
    <Card {...otherProps}>
      <CardBody>
        <center className="mt-4">
          <ImageCircle avatar src={avatarCdnUrl} width={150} />
          <ModalAvatar user={user} />
          <CardTitle className="mb-2">{contactName}</CardTitle>
          <CardSubtitle className="mb-2 text-muted">{name}</CardSubtitle>
        </center>
      </CardBody>
      <CardBody className="border-top">
        <TextBlock label={t('contactEmail')} content={contactEmail} className="my-3" />
        <TextBlock
          label={t('contactPhone')}
          content={contactPhone && contactPhone}
          className="my-3"
        />
        <TextBlock
          label={t('password')}
          content="***********"
          actions={<ModalPassword />}
          className="my-3"
        />
        <TextBlock label={t('country')} content={country?.name} className="my-3" />
        <TextBlock
          label={t('companyUrl')}
          content={
            companyUrl && (
              <ExternalLink href={companyUrl} className="text-break">
                {companyUrl}
              </ExternalLink>
            )
          }
          className="my-3"
        />
        <TextBlock label={t('brands')} content={brands.join(', ')} className="my-3" />
        <TextBlock
          label={t('categoryGroups')}
          content={categoryGroups.map((group) => group.name).join(', ')}
          className="my-3"
        />
      </CardBody>
    </Card>
  );
};

export default React.memo(UserCardSidebar);
