import React, { useState, useEffect } from 'react';
import { Edit as EditIcon } from 'react-feather';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { ModalWithInitialButton as Modal } from '@dotone/react-ui-core';
import Form from './UserForm';

const UserModalForm = ({ user, ...otherProps }) => {
  const { t } = useTranslate('profile', 'edit');
  const [isOpen, setOpen] = useState(false);
  const { status } = useShallowEqualSelector(({ userUpdate }) => userUpdate);

  useEffect(() => {
    if (status === 'success') {
      setOpen(false);
    }
  }, [status]);

  return (
    <Modal
      title={t('title')}
      isOpen={isOpen}
      onToggle={setOpen}
      footer={false}
      buttonProps={{
        outline: true,
        color: 'primary',
        children: (
          <>
            <EditIcon size={16} className="mr-1" />
            {t('common:actions.edit')}
          </>
        ),
      }}
      {...otherProps}
    >
      <Form user={user} />
    </Modal>
  );
};

export default React.memo(UserModalForm);
