import { UploadApi } from '@api';
import { uploadTypes as actionTypes } from './types';

export const fetchUploads = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  UploadApi.getList(params)
    .then((payload) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const createUpload = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  UploadApi.create(params)
    .then((payload) => {
      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
