import { CampaignApi } from '@api';
import { campaignTypes as actionTypes } from './types';

export const fetchCampaigns = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  CampaignApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const updateCampaign = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  CampaignApi.update(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
