import API from './api';

export default class ChatRoomApi {
  static create(params) {
    return API.post('/chat_rooms', params);
  }

  static get(id, params) {
    return API.get(`/chat_rooms/${id}`, params);
  }

  static getList(params) {
    return API.get('/chat_rooms', params);
  }
}
