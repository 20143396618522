import React from 'react';
import { useTranslate } from '@hooks';
import { toArray } from '@dotone/react-ui-core/utils';
import { FilterBlock, FilterList } from '@dotone/react-ui-core';
import { OfferFilterBlock } from '../filters';

const StatFilterList = ({ filters, ...otherProps }) => {
  const { t, tShared } = useTranslate('stat', 'filter');
  const { offerIds = [], approvals, startDate, endDate } = filters;

  return (
    <FilterList {...otherProps}>
      <FilterBlock list={false} label={t('startDate')} items={toArray(startDate)} />
      <FilterBlock list={false} label={t('endDate')} items={toArray(endDate)} />
      <OfferFilterBlock ids={offerIds} />
      <FilterBlock
        label={t('status')}
        items={toArray(approvals)}
        listProps={{ field: false, t: (key) => tShared(`stat:approval.${key}`) }}
      />
    </FilterList>
  );
};

export default React.memo(StatFilterList);
