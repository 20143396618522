import API from './api';

export default class CampaignApi {
  static getList(params) {
    return API.get('/advertisers/affiliate_offers', params);
  }

  static update(id, params) {
    return API.put(`/advertisers/affiliate_offers/${id}`, { affiliateOffer: params });
  }
}
