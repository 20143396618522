import React, { useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Edit2 as EditAltIcon } from 'react-feather';
import { Modal } from '@dotone/react-ui-core';
import { useTranslate } from '@hooks';
import StatBulkEditForm from './StatBulkEditForm';

const StatBulkActionDropdown = ({ ids, ...otherProps }) => {
  const { t } = useTranslate('common');
  const [editOpen, setEditOpen] = useState(false);

  const handleEditToggle = () => {
    setEditOpen(!editOpen);
  };

  return (
    <>
      <UncontrolledDropdown data-tour="bulk-edit" {...otherProps}>
        <DropdownToggle
          className="btn-rounded"
          caret
          color={ids.length ? 'info' : 'secondary'}
          disabled={!ids.length}
        >
          <EditAltIcon size={16} className="mr-1" />
          {t('bulkActions')}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleEditToggle}>{t('actions.edit')}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <Modal
        scrollable={false}
        isOpen={editOpen}
        title={t('common:actions.bulkEdit')}
        size="lg"
        footer={false}
        onToggle={handleEditToggle}
      >
        <StatBulkEditForm ids={ids} onCancel={handleEditToggle} />
      </Modal>
    </>
  );
};

export default StatBulkActionDropdown;
