import { AffiliateLogApi } from '@api';
import { affiliateLogTypes as actionTypes } from './types';

export const createAffiliateLog = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  AffiliateLogApi.create({ affiliateLog: params })
    .then((response) => {
      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
