import React from 'react';
import { useTranslate } from '@hooks';
import { camelCase } from 'lodash';
import { TablePaginated, Currency, Date } from '@dotone/react-ui-core';

export const formatCurrency = (cell) => <Currency value={cell} />;

export const formatDate = (cell) => <Date value={cell} />;

const getColumns = ({ t, tShared }) => [
  {
    dataField: 'id',
    text: t('id'),
  },
  {
    dataField: 'recordedAt',
    text: t('recordedAt'),
    formatter: formatDate,
  },
  {
    dataField: 'recordType',
    text: t('recordType'),
    headerStyle: { width: '20%' },
    formatter: (cell) => cell && tShared(`advertiserBalance:recordType.${camelCase(cell)}`),
  },
  {
    dataField: 'credit',
    text: t('credit'),
    formatter: formatCurrency,
    headerAlign: 'right',
    classes: 'text-right text-primary',
  },
  {
    dataField: 'debit',
    text: t('debit'),
    formatter: formatCurrency,
    headerAlign: 'right',
    classes: 'text-right text-danger',
  },
  {
    dataField: 'salesTax',
    text: t('salesTax'),
    formatter: formatCurrency,
    headerAlign: 'right',
    classes: 'text-right text-warning',
  },
];

const BalanceTable = (props) => {
  const { t, tShared } = useTranslate('advertiserBalance', 'model');

  const columns = getColumns({ t, tShared });

  return (
    <TablePaginated
      remote
      responsive
      hover
      condensed
      classes="stylish-table"
      keyField="id"
      columns={columns}
      {...props}
    />
  );
};

export default BalanceTable;
