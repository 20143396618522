import API from './api';

export default class PasswordApi {
  static reset(params) {
    return API.post('/advertisers/password/reset', params);
  }

  static create(params) {
    return API.post('/advertisers/password', params);
  }

  static update(params) {
    return API.put('/advertisers/password', params);
  }
}
