import React from 'react';
import classnames from 'classnames';

const Logo = ({ className, isPublic = false, ...otherProps }) => (
  <img
    src={isPublic ? '/logo-white.png' : '/logo.png'}
    className={classnames('img-fluid', className)}
    alt="Affiliates.One"
    {...otherProps}
  />
);

export default Logo;
