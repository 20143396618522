import React, { useState } from 'react';
import { useTranslate } from '@hooks';
import { missingOrderStatuses as statuses } from '@dotone/react-ui-core/constants';
import { camelCase } from 'lodash';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { CheckCircle, XCircle } from 'react-feather';
import { Modal } from '@dotone/react-ui-core';
import MissingOrderApprovalForm from './MissingOrderApprovalForm';

const iconMap = {
  [statuses.APPROVED]: <CheckCircle size={16} className="mr-2" />,
  [statuses.REJECTED]: <XCircle size={16} className="mr-2" />,
};

const MissingOrderStatusDropdown = ({ missingOrder, ...otherProps }) => {
  const { tShared } = useTranslate('missingOrder');
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  const handleToggle = () => setOpen(!isOpen);

  const handleClick = (value) => () => {
    setSelected(value);
    setOpen(true);
  };

  return (
    <>
      <UncontrolledDropdown {...otherProps}>
        <DropdownToggle caret size="sm" color="danger">
          {tShared(`status.attentionReq`)}
        </DropdownToggle>
        <DropdownMenu>
          {Object.keys(iconMap).map((value) => (
            <DropdownItem key={value} onClick={handleClick(value)}>
              {iconMap[value]}
              {tShared(`status.${camelCase(value)}`)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {selected && (
        <Modal
          title={tShared(`status.${camelCase(selected)}`)}
          isOpen={isOpen}
          onToggle={handleToggle}
          footer={false}
          size="lg"
        >
          <MissingOrderApprovalForm
            type={selected}
            defaultValues={missingOrder}
            onCancel={handleToggle}
          />
        </Modal>
      )}
    </>
  );
};

export default MissingOrderStatusDropdown;
