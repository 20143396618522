import API from './api';

export default class UploadApi {
  static getList(params) {
    return API.get('/advertisers/uploads', params);
  }

  static create(params) {
    return API.post('/advertisers/uploads', params);
  }
}
