import { EasyStoreSetupApi } from '@api';
import { easyStoreSetupTypes as actionTypes } from './types';

export const getEasyStoreSetup = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CURRENT.LOADING,
  });

  EasyStoreSetupApi.get(params)
    .then((response) => {
      dispatch({
        type: actionTypes.CURRENT.SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.CURRENT.FAILURE,
      });
    });
};

export const createEasyStoreSetup = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  EasyStoreSetupApi.create(params)
    .then((response) => {
      dispatch({
        type: actionTypes.CURRENT.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
