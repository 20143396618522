import React from 'react';
import { useTranslate } from '@hooks';
import { Row, Col, FormGroup } from 'reactstrap';
import { map } from 'lodash';
import { FormColumn as Form, Input, SubsectionTitle } from '@dotone/react-ui-core';
import { statFilterColumns, statFilterColumnsClick, statDataTypes } from '@constants';

const StatColumnForm = ({ defaultValues, dataType, ...otherProps }) => {
  const { t } = useTranslate('stat', 'model');
  const columns = dataType === statDataTypes.CLICKS ? statFilterColumnsClick : statFilterColumns;

  return (
    <Form defaultValues={defaultValues} {...otherProps}>
      <Row>
        {map(columns, (items, group) => (
          <Col key={group} md={6} lg={3}>
            <SubsectionTitle tag="h6">{t(`stat:columnGroup.${group}`)}</SubsectionTitle>
            <FormGroup>
              {items.map((item) => (
                <Input key={item} name={`columns.${item}`} type="checkbox" label={t(item)} />
              ))}
            </FormGroup>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default StatColumnForm;
