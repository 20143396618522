import { pick } from 'lodash';

export const statDataTypes = Object.freeze({
  CAPTURED: 'captured',
  CLICKS: 'clicks',
  CONVERTED: 'converted',
  PUBLISHED: 'published',
});

export const statDefaultColumnsClick = Object.freeze([
  'transactionId',
  'recordedAt',
  'capturedAt',
  'publishedAt',
  'convertedAt',
  'offerId',
  'offer.name',
  'affiliateId',
]);

export const statDefaultColumns = Object.freeze([
  ...statDefaultColumnsClick,
  'orderNumber',
  'orderTotal',
  'advUniqId',
  'stepLabel',
  'truePay',
  'approval',
]);

export const statFilterColumns = Object.freeze({
  campaign: ['trueConvType', 'offerStatus'],
  orders: ['stepLabel', 'orderDaysReturn'],
  subid: ['subid1', 'subid2', 'subid3', 'subid4', 'subid5'],
  tracking: ['ipAddress', 'ipCountry', 'isp'],
  client: ['deviceType', 'deviceBrand', 'deviceModel', 'browser', 'browserVersion'],
});

export const statFilterColumnsClick = pick(statFilterColumns, ['subid', 'tracking', 'client']);
