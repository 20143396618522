import { createSelector } from 'reselect';
import useShallowEqualSelector from './useShallowEqualSelector';
import useTranslate from './useTranslate';

export default function useLocalizedDataSelector(selector) {
  const { locale } = useTranslate();
  const localizedSelector = createSelector(selector, (state) => state[locale].data);

  return useShallowEqualSelector(localizedSelector);
}
