import React from 'react';
import { useTranslate } from '@hooks';
import { ModalWithInitialButton as Modal } from '@dotone/react-ui-core';
import OrderForm from './OrderForm';

const OrderFormModal = ({ order, ...otherProps }) => {
  const { t } = useTranslate('order', 'model');

  return (
    <Modal
      title={t('form.title', { orderNumber: order.orderNumber })}
      dense
      size="xl"
      footer={false}
      scrollable={false}
      form={<OrderForm defaultValues={order} />}
      buttonProps={{ children: order.orderNumber, color: 'link', className: 'btn-sm p-0' }}
      {...otherProps}
    />
  );
};

export default OrderFormModal;
