import { DownloadApi } from '@api';
import { downloadTypes as actionTypes } from './types';

export const fetchDownloads = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  DownloadApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const deleteDownload = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE.LOADING,
  });

  DownloadApi.delete(id, params)
    .then((payload) => {
      dispatch({
        type: actionTypes.DELETE.SUCCESS,
        payload,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.DELETE.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
