import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useCurrentUser, useFilterSelector } from '@hooks';
import { fetchMonthlyStats } from '@actions';
import { sum } from 'lodash';
import { ColumnChart, FlexContainer, Text, Currency } from '@dotone/react-ui-core';

const MonthlyStat = (props) => {
  const { t, tHint } = useTranslate('dashboard', 'hints');
  const dispatch = useDispatch();
  const { currentUser, timeZone, currency } = useCurrentUser();
  const { data, isLoading } = useFilterSelector(({ monthlyStats }) => monthlyStats, {
    timeZone,
    currency,
  });

  useEffect(() => {
    if (currentUser.id) {
      dispatch(fetchMonthlyStats({ timeZone, currency }));
    }
  }, [dispatch, currentUser.id, timeZone, currency]);

  return (
    <FlexContainer {...props}>
      {!isLoading && data.thisMonth && data.lastMonth && (
        <>
          <FlexContainer>
            <FlexContainer column>
              <Text
                hintProps={{
                  tKey: 'thisMonthRevenue',
                  ...tHint('dashboard:hints.thisMonthRevenue'),
                }}
              >
                {t('thisMonthRevenue.title')}
              </Text>
              <Currency value={sum(data.thisMonth)} className="text-success" />
            </FlexContainer>
            <ColumnChart
              data={data.thisMonth.slice(-7)}
              chart={{
                type: 'column',
                backgroundColor: 'rgba(0,0,0,0)',
                height: 50,
                width: 100,
              }}
            />
          </FlexContainer>
          <FlexContainer className="ml-3">
            <FlexContainer column>
              <Text
                hintProps={{
                  tKey: 'lastMonthRevenue',
                  ...tHint('dashboard:hints.lastMonthRevenue'),
                }}
              >
                {t('lastMonthRevenue.title')}
              </Text>
              <Currency value={sum(data.lastMonth)} className="text-primary" />
            </FlexContainer>
            <ColumnChart
              data={data.lastMonth.slice(-7)}
              chart={{
                type: 'column',
                backgroundColor: 'rgba(0,0,0,0)',
                height: 50,
                width: 100,
              }}
            />
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};

export default MonthlyStat;
