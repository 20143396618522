export const statSummaryDimensions = [
  'offerId',
  'affiliateId',
  'imageCreativeId',
  'textCreativeId',
];

export const statSummaryDefaultColumns = {
  performance: [
    'clicks',
    'captured',
    'publishedConversions',
    'pendingConversions',
    'rejectedConversions',
    'orderTotal',
    'totalTruePay',
    'conversionPercentage',
    'truePayEpc',
  ],
  conversion: [
    'captured',
    'publishedConversions',
    'pendingConversions',
    'rejectedConversions',
    'orderTotal',
    'totalTruePay',
    'pendingTruePay',
    'publishedTruePay',
    'rejectedTruePay',
  ],
  finalizedConversion: [
    'captured',
    'publishedConversions',
    'rejectedConversions',
    'orderTotal',
    'totalTruePay',
    'publishedTruePay',
    'rejectedTruePay',
  ],
};

const columns = Object.freeze({
  dimensions: statSummaryDimensions,
  impressions: ['impressions'],
  clicks: ['clicks', 'truePayEpc'],
  conversions: [
    'captured',
    'pendingConversions',
    'publishedConversions',
    'rejectedConversions',
    'conversionPercentage',
  ],
  payouts: ['orderTotal', 'totalTruePay', 'pendingTruePay', 'publishedTruePay', 'rejectedTruePay'],
});

export const statSummaryColumns = Object.freeze({
  performance: columns,
  conversion: {
    dimensions: columns.dimensions,
    conversions: ['captured', 'pendingConversions', 'publishedConversions', 'rejectedConversions'],
    payouts: columns.payouts,
  },
  finalizedConversion: {
    dimensions: columns.dimensions,
    conversions: ['captured', 'publishedConversions', 'rejectedConversions'],
    payouts: ['orderTotal', 'totalTruePay', 'publishedTruePay', 'rejectedTruePay'],
  },
});
