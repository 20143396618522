import React, { useState } from 'react';
import { useTranslate } from '@hooks';
import {
  creativeStatuses,
  missingOrderStatuses,
  statApprovals,
} from '@dotone/react-ui-core/constants';
import moment from 'moment';
import NextLink from 'next/link';
import { CardText, Row, Col, ListGroup, ListGroupItem as BsListGroupItem } from 'reactstrap';
import {
  ImageBrand,
  Date,
  ColumnChart,
  Text,
  AlertPopover,
  FlexContainer,
} from '@dotone/react-ui-core';
import OfferLandingPageModal from './OfferLandingPageModal';

const ListGroupItem = (props) => <BsListGroupItem tag="a" action {...props} />;

const Link = ({ children, ...props }) => (
  <NextLink {...props}>
    <ListGroupItem>{children}</ListGroupItem>
  </NextLink>
);

const Block = (props) => <div className="mb-2 d-block" {...props} />;

const OfferCardSidebar = ({ offer, ...otherProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslate('offer', 'model');
  const { tHint } = useTranslate('offer', 'hints');
  const { brandImageMediumUrl, shortDescription, publishedDate, detailViewsLastMonth } = offer;

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <Row {...otherProps}>
      <Col xs={12} className="text-center">
        <>
          <Block>
            <ImageBrand
              src={brandImageMediumUrl}
              size="md"
              style={{ width: 120 }}
              className="mb-1 mx-auto"
            />
            <CardText className="text-sm">{shortDescription}</CardText>
          </Block>
          <Block>
            <FlexContainer align="center" justify="center" className="font-18 mt-1">
              <p className="f-14 mb-1 mw-60">
                {offer.categories != null && (
                  <Text size="sm">
                    {offer.categories.map((category) => category.name).join(' | ')}
                  </Text>
                )}
              </p>
            </FlexContainer>
          </Block>
          <Block>
            <Date
              value={publishedDate}
              hintProps={{
                ...tHint('publishedDate'),
                target: `publish-date-${offer.id}`,
              }}
              className="text-dark-light font-medium"
            />
          </Block>
          <Block>
            <OfferLandingPageModal isOpen={isOpen} handleToggle={handleToggle} {...offer} />

            <ListGroup flush className="mt-3">
              <ListGroupItem onClick={handleToggle}>
                {t('landingPages', { count: offer.offerVariants.length })}
              </ListGroupItem>
              <Link
                href={{
                  pathname: '/advertisers/banners',
                  query: { offerIds: offer.id, statuses: creativeStatuses.ACTIVE },
                }}
              >
                {t('addBanners', { count: offer.totalActiveImageCreatives })}
              </Link>
              <Link
                href={{
                  pathname: '/advertisers/promo-deals',
                  query: { offerIds: offer.id, statuses: creativeStatuses.ACTIVE },
                }}
              >
                {t('addPromoAndDeals', { count: offer.totalActiveTextCreatives })}
              </Link>
              <Link
                href={{
                  pathname: '/advertisers/transactions/captured',
                  query: {
                    offerIds: offer.id,
                    startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
                    approvals: statApprovals.PENDING,
                  },
                }}
              >
                {t('pendingTransactions', { count: offer.pendingTransactions || 0 })}
              </Link>
              <Link
                href={{
                  pathname: `/advertisers/order-inquiries`,
                  query: {
                    offerIds: offer.id,
                    statuses: missingOrderStatuses.CONFIRMING,
                  },
                }}
              >
                {t('orderInquiries', { count: offer.totalMissingOrders })}
              </Link>
            </ListGroup>
          </Block>
          <Block>
            <FlexContainer align="center" justify="center">
              <ColumnChart
                data={detailViewsLastMonth}
                chart={{
                  backgroundColor: 'rgba(0,0,0,0)',
                  height: 60,
                  width: 140,
                }}
              />
              <AlertPopover {...tHint('impressionChart')} target={`column-chart-${offer.id}`} />
            </FlexContainer>
          </Block>
        </>
      </Col>
    </Row>
  );
};

export default React.memo(OfferCardSidebar);
