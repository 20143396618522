import API from './api';

export default class ApiKeyApi {
  static getList(params) {
    return API.get('/api_keys', params);
  }

  static create(params) {
    return API.post('/api_keys', params);
  }

  static update(id, params) {
    return API.put(`/api_keys/${id}`, params);
  }

  static delete(id, params) {
    return API.delete(`/api_keys/${id}`, params);
  }
}
