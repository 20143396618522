import useShallowEqualSelector from './useShallowEqualSelector';

const useCurrentUser = () => {
  const currentUser = useShallowEqualSelector(({ currentUser: { data } }) => data);
  const { locale, currency, timeZone } = currentUser;

  return {
    currentUser,
    locale,
    currency,
    timeZone,
  };
};

export default useCurrentUser;
