import { ChatMessageApi } from '@api';
import { chatMessageTypes as actionTypes } from './types';

export const fetchChatMessages = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  ChatMessageApi.getList(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const createChatMessage = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  ChatMessageApi.create(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'prepend',
      });

      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload: response,
      });

      dispatch({
        type: actionTypes.LIST_UPLOADED_IMAGES.SUCCESS,
        payload: { data: [] },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        message: error.statusText,
      });
    });
};

export const fetchUploadedImageList = (files) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST_UPLOADED_IMAGES.LOADING,
  });

  dispatch({
    type: actionTypes.LIST_UPLOADED_IMAGES.SUCCESS,
    payload: { data: files },
  });
};

export const removeUploadedChatImage = (files) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST_UPLOADED_IMAGES.SUCCESS,
    payload: { data: files },
  });
};

export const resetChatMessage = () => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.SUCCESS,
    payload: { data: [] },
  });
};
