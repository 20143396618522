import React from 'react';
import { useTranslate } from '@hooks';
import { Alert, ExternalLink, Modal, Input, CardStackable } from '@dotone/react-ui-core';

const OfferLandingPageModal = ({ destinationUrl, offerVariants, isOpen, handleToggle }) => {
  const { t } = useTranslate('offer', 'landingPageModal');

  const printOfferVariant = (offerVariant) =>
    [`[${offerVariant.id}]`, offerVariant.fullName].join(' ');

  const handleClick = (e) => {
    e.target.select();
  };

  return (
    <Modal
      title={t('title')}
      size="lg"
      footer={false}
      scrollable={false}
      isOpen={isOpen}
      onToggle={handleToggle}
    >
      <CardStackable title={t('browseUrl')}>
        <Alert variant="info">{t('browseUrlNote')}</Alert>
        <Input type="textarea" readOnly rows={3} value={destinationUrl} onClick={handleClick} />
        <div className="mt-3 text-right">
          <ExternalLink href={destinationUrl} />
        </div>
      </CardStackable>
      <CardStackable title={`${t('destinationUrl')} - ${printOfferVariant(offerVariants[0])}`}>
        <Alert variant="info">{t('destinationUrlNote')}</Alert>
        <Input
          type="textarea"
          readOnly
          rows={3}
          value={offerVariants[0].destinationUrl}
          onClick={handleClick}
        />
        <div className="mt-3 text-right">
          <ExternalLink href={offerVariants[0].destinationUrl} />
        </div>
      </CardStackable>
      {offerVariants.slice(1).map((offerVariant, index) => (
        <CardStackable
          key={index}
          title={`${t('alternativeUrl', { index: index + 1 })} - ${printOfferVariant(
            offerVariant
          )}`}
        >
          {index === 0 && <Alert variant="info">{t('alternativeUrlNote')}</Alert>}
          <Input
            type="textarea"
            readOnly
            rows={3}
            value={offerVariant.destinationUrl}
            onClick={handleClick}
          />
          <div className="mt-3 text-right">
            <ExternalLink href={offerVariant.destinationUrl} />
          </div>
        </CardStackable>
      ))}
      <Alert variant="warning">{t('note')}</Alert>
    </Modal>
  );
};

export default OfferLandingPageModal;
