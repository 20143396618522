import React, { useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useTranslate, useShallowEqualSelector } from '@hooks';
import { registerUser } from '@actions';
import { envVars } from '@constants';
import { withHttps } from '@utils';
import { Form, Row, Col } from 'reactstrap';
import Script from 'next/script';

import NextIcon from 'mdi-react/ChevronDoubleRightIcon';
import { arrayToFormFields, formFieldsToArray } from '@dotone/react-ui-core/utils';
import {
  FormGroup as BsFormGroup,
  Label,
  Input,
  InputArray,
  InputGroup,
  LocaleSelect,
  Button,
} from '@dotone/react-ui-core';
import { CountrySelect, CategoryGroupSelect } from '../forms';

const trackingParamNames = [
  'sourceid',
  'subid',
  'vtm_stat_id',
  'channel_id',
  'campaign_id',
  'campaign_locale',
  'campaign_target',
];

/* global VA */
const getVaCookie = (key) => VA.getCookie(key);

const FormGroup = ({ label, required = false, children, ...otherProps }) => (
  <BsFormGroup {...otherProps}>
    {label && <Label required={required}>{label}</Label>}
    {children}
  </BsFormGroup>
);

const UserRegisterForm = (props) => {
  const { t, locale } = useTranslate('network', 'model');
  const router = useRouter();
  const dispatch = useDispatch();
  const {
    status,
    data = { message: {} },
    isLoading,
  } = useShallowEqualSelector(({ userRegister }) => userRegister);
  const { connectForm, handleSubmit, setError } = useForm({
    defaultValues: { brands: arrayToFormFields([]) },
  });

  const handleSubmitForm = (values) => {
    const trackingParams = {};

    if (typeof VA !== 'undefined') {
      trackingParamNames.forEach((key) => {
        if (getVaCookie(key)) trackingParams[key] = getVaCookie(key);
      });
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(envVars.googleRecaptchaSiteKey, { action: 'advertiser/register' })
        .then((token) => {
          dispatch(
            registerUser(
              {
                ...values,
                ...trackingParams,
                brands: formFieldsToArray(values.brands),
                companyUrl: withHttps(values.companyUrl),
              },
              { g_recaptcha_response: token, locale, ...trackingParams }
            )
          );
        });
    });
  };

  useDeepCompareEffect(() => {
    if (status === 'error' && data.message) {
      Object.keys(data.message).forEach((key) =>
        setError(key, { type: 'custom', message: data.message[key] })
      );
    }
  }, [status, data.message, setError]);

  useEffect(() => {
    if (status === 'success') {
      router.push('/advertisers/register/success');
    }
  }, [status, router]);

  return (
    <>
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${envVars.googleRecaptchaSiteKey}`}
        strategy="beforeInteractive"
        id="google-recaptcha"
      />
      <Form onSubmit={handleSubmit(handleSubmitForm)} {...props}>
        {connectForm(
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Input
                  name="name"
                  placeholder={`${t('name')}*`}
                  rules={{ required: true, maxLength: 60 }}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Input
                  name="contactName"
                  placeholder={`${t('contactName')}*`}
                  rules={{ required: true, maxLength: 20 }}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="email"
                  name="contactEmail"
                  placeholder={`${t('contactEmail')}*`}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Input
                  name="contactPhone"
                  type="tel"
                  placeholder={`${t('contactPhone')}*`}
                  rules={{ required: true, maxLength: 15 }}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <LocaleSelect
                  includeBlank
                  name="locale"
                  placeholder={`${t('locale')}*`}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <CountrySelect
                  name="countryId"
                  placeholder={`${t('country')}*`}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup>
                <InputGroup
                  name="companyUrl"
                  prepend="https://"
                  inputProps={{
                    type: 'text',
                    placeholder: `${t('companyUrl')}*`,
                    rules: { required: true },
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup>
                <InputArray
                  label={<Label>{t('brands')}</Label>}
                  name="brands"
                  addClassName="d-flex align-items-end"
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup label={`${t('categoryGroups')}`} required>
                <CategoryGroupSelect
                  name="categoryGroupIds"
                  multi
                  rules={{ required: true, maxLength: 5 }}
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup>
                <Input
                  type="textarea"
                  rows={3}
                  name="clientNotes"
                  placeholder={t('clientNotes')}
                  rules={{ maxLength: 300 }}
                />
              </FormGroup>
            </Col>
            <Col sm={{ size: 6, offset: 6 }}>
              <FormGroup align="right">
                <Button
                  block
                  type="submit"
                  color="primary"
                  submitting={isLoading}
                  className="btn-rounded"
                >
                  {t('network:actions.submitApplication')}
                  <NextIcon />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
};

export default UserRegisterForm;
