import React from 'react';
import { useTranslate } from '@hooks';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { FormFilter as Form, SubsectionTitle, DateRangePicker } from '@dotone/react-ui-core';
import { OfferSearchInput } from '../forms';

const AffiliationFilterForm = ({ defaultValues, ...otherProps }) => {
  const { t } = useTranslate('report', 'filter');

  return (
    <Form dateable defaultValues={defaultValues} {...otherProps}>
      <Row>
        <Col lg={12}>
          <SubsectionTitle>{t('common:filterBy')}</SubsectionTitle>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('common:date.date')}</Label>
            <DateRangePicker forReport names={{ from: 'startDate', to: 'endDate' }} />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('offerId')}</Label>
            <OfferSearchInput multi name="offerIds" />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default AffiliationFilterForm;
