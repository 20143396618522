import { ContactApi } from '@api';
import { contactTypes as actionTypes } from './types';

export const createContact = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  ContactApi.create(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload: response,
        message: 'created',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        payload: { data: error.data },
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const fetchContacts = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  ContactApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const updateContact = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  ContactApi.update(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        payload: response,
        message: 'updated',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        payload: { data: error.data },
        message: `notify:general.errors.${error.status}`,
      });
    });
};
