const keys = {
  '/advertisers/transactions/captured': 'stat',
  '/advertisers/transactions/published': 'stat',
  '/advertisers/transactions/confirmed': 'stat',
};

const clickDropdownToggle = (name, state) => {
  const button = document.querySelector(`[data-tour="${name}-dropdown"] .dropdown-toggle`);
  if (button) {
    button.dataset.tourState = state;
    button.click();
  }
};

const actions = {
  openFilter: () => clickDropdownToggle('filter', 'open'),
  closeFilter: () => clickDropdownToggle('filter', 'close'),
  openColumn: () => clickDropdownToggle('column', 'open'),
  closeColumn: () => clickDropdownToggle('column', 'close'),
  openSearch: () => clickDropdownToggle('search', 'open'),
  closeSearch: () => clickDropdownToggle('search', 'close'),
};

const commonSteps = {
  add: (t, value) => ({
    selector: '[data-tour="add"]',
    content: t('tour:stepAdd', { value }),
  }),
  bulkEdit: (t) => ({
    selector: '[data-tour="bulk-edit"]',
    content: t('tour:stepBulkEdit'),
  }),
  column: (t) => ({
    selector: '[data-tour="column-dropdown"]',
    content: t('tour:stepColumn'),
    action: actions.closeColumn,
  }),
  columnForm: (t) => ({
    selector: '[data-tour="column-dropdown"] .dropdown-menu',
    content: t('tour:stepColumnForm'),
    observe: '[data-tour="column-dropdown"]',
    highlightedSelectors: ['[data-tour="column-dropdown"] .dropdown-menu'],
    action: actions.openColumn,
  }),
  download: (t) => ({
    selector: '[data-tour="download"]',
    content: t('tour:stepDownload'),
  }),
  filter: (t) => ({
    selector: '[data-tour="filter-dropdown"]',
    content: t('tour:stepFilter'),
    action: actions.closeFilter,
  }),
  filterForm: (t) => ({
    selector: '[data-tour="filter-dropdown"]',
    content: t('tour:stepFilterForm'),
    observe: '[data-tour="filter-dropdown"]',
    highlightedSelectors: ['[data-tour="filter-dropdown"] .dropdown-menu'],
    action: actions.openFilter,
  }),
  search: (t) => ({
    selector: '[data-tour="search-dropdown"]',
    content: t('tour:stepSearch'),
    action: actions.closeSearch,
  }),
  searchForm: (t) => ({
    selector: '[data-tour="search-dropdown"] .dropdown-menu',
    content: t('tour:stepSearchForm'),
    observe: '[data-tour="search-dropdown"]',
    highlightedSelectors: ['[data-tour="search-dropdown"] .dropdown-menu'],
    action: actions.openSearch,
  }),
};

const statSteps = (t) => [
  commonSteps.filter(t),
  commonSteps.filterForm(t),
  commonSteps.column(t),
  commonSteps.columnForm(t),
  commonSteps.search(t),
  commonSteps.searchForm(t),
  {
    ...commonSteps.download(t),
    action: actions.closeSearch,
  },
  commonSteps.bulkEdit(t),
];

const steps = {
  '/advertisers': (t) => [
    {
      selector: '[data-tour="dashboard"]',
      content: t('tour:dashboard.step1'),
    },
    {
      selector: '[data-tour="feed"]',
      content: t('tour:dashboard.step2'),
    },
    {
      selector: '[data-tour="account-overview"]',
      content: t('tour:dashboard.step3'),
    },
    {
      selector: '[data-tour="performance-stats"]',
      content: t('tour:dashboard.step4'),
    },
    {
      selector: '[data-tour="exposure"]',
      content: t('tour:dashboard.step5'),
    },
    {
      selector: '[data-tour="commission-balance"]',
      content: t('tour:dashboard.step6'),
    },
    {
      selector: '[data-tour="recent-conversions"]',
      content: t('tour:dashboard.step7'),
    },
    {
      selector: '[data-tour="top-publisher"]',
      content: t('tour:dashboard.step8'),
    },
    {
      selector: '[data-tour="recent-publisher"]',
      content: t('tour:dashboard.step9'),
    },
    {
      selector: '[data-tour="country-clicks"]',
      content: t('tour:dashboard.step10'),
    },
    {
      selector: '[data-tour="device-clicks"]',
      content: t('tour:dashboard.step11'),
    },
  ],
  '/advertisers/banners': (t) => [
    {
      selector: '.react-bootstrap-table',
      content: t('tour:imageCreative.step1'),
    },
    commonSteps.filter(t),
    commonSteps.filterForm(t),
    {
      ...commonSteps.add(t, t('tour:imageCreative.label')),
      action: actions.closeFilter,
    },
    commonSteps.bulkEdit(t),
  ],
  '/advertisers/promo-deals': (t) => [
    {
      selector: '.react-bootstrap-table',
      content: t('tour:textCreative.step1'),
    },
    commonSteps.filter(t),
    commonSteps.filterForm(t),
    {
      ...commonSteps.add(t, t('tour:textCreative.label')),
      action: actions.closeFilter,
    },
    commonSteps.bulkEdit(t),
  ],
  '/advertisers/transactions/clicks': (t) => [
    {
      selector: '.react-bootstrap-table',
      content: t('tour:stat.clicks.step1'),
    },
    commonSteps.filter(t),
    commonSteps.filterForm(t),
    commonSteps.column(t),
    commonSteps.columnForm(t),
    commonSteps.search(t),
    commonSteps.searchForm(t),
    {
      ...commonSteps.download(t),
      action: actions.closeSearch,
    },
  ],
  '/advertisers/transactions/captured': (t) => [
    {
      selector: '.react-bootstrap-table',
      content: t('tour:stat.captured.step1'),
    },
    ...statSteps(t),
  ],
  '/advertisers/transactions/published': (t) => [
    {
      selector: '.react-bootstrap-table',
      content: t('tour:stat.published.step1'),
    },
    ...statSteps(t),
  ],
  '/advertisers/transactions/confirmed': (t) => [
    {
      selector: '.react-bootstrap-table',
      content: t('tour:stat.confirmed.step1'),
    },
    ...statSteps(t),
  ],
};

const filterAvailableStepsInDOM = (selectedSteps) => {
  return selectedSteps.filter(({ selector }) => {
    return document.querySelector(selector);
  });
};

export const getTourSteps = (path, t) => {
  const current = steps[path];

  if (current) {
    const selectedContent = filterAvailableStepsInDOM(current(t));

    const step = [keys[path] || path, selectedContent];

    return step;
  }

  return [];
};

export const hasTourSteps = (path, t) => {
  const current = steps[path];

  return !!current && !!filterAvailableStepsInDOM(current(t)).length;
};
