const downloadFile = (url) => {
  const element = document.createElement('a');
  element.href = url;
  element.target = '_blank';
  const fileName = url.split('/').pop();

  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  window.URL.revokeObjectURL(url);
  element.remove();
};

const createFileAndDownload = (data, filename) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`);
  element.setAttribute('download', filename);

  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export { downloadFile, createFileAndDownload };
