import { productTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true }),
  pagination,
};

export const productList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST, state, action);
