import React from 'react';
import { useTranslate } from '@hooks';
import { TablePaginated, ButtonFlat } from '@dotone/react-ui-core';
import { createFileAndDownload, downloadFile } from '@utils';
import { formatDateTime, formatFileStatus } from '../formatters';

const formatActions = (onDownload) =>
  function RenderActions(cell, row) {
    return (
      <ButtonFlat
        variant="download"
        disabled={!row.cdnUrl}
        iconProps={{ size: 18 }}
        className="mr-2"
        onClick={() => onDownload(row)}
      />
    );
  };

const formatDownloadError = (t) =>
  function RenderDownloadError(cell, row) {
    const error = ['Warning', 'Error'].includes(row.status);
    const filename = `${row.status}.txt`;

    if (error) {
      return (
        <ButtonFlat
          color="danger"
          size="sm"
          onClick={() => {
            createFileAndDownload(row.errorDetails, filename);
          }}
        >
          {t('downloadFile')}
        </ButtonFlat>
      );
    }

    return null;
  };

const getColumns = ({ t, onDownload }) => [
  {
    dataField: 'id',
    text: t('id'),
  },
  {
    dataField: 'descriptions',
    text: t('descriptions'),
  },
  {
    dataField: 'createdAt',
    text: t('createdAt'),
    formatter: formatDateTime(),
  },
  {
    dataField: 'status',
    text: t('status'),
    formatter: formatFileStatus,
  },
  {
    dataField: 'errorDetails',
    text: t('errorDetails'),
    formatter: formatDownloadError(t),
  },
  {
    dataField: 'uploadedBy',
    text: t('uploadedBy'),
  },
  {
    dataField: 'actions',
    text: t('file'),
    classes: 'align-middle text-center',
    isDummyField: true,
    formatter: formatActions(onDownload),
  },
];

const UploadTable = (props) => {
  const { t } = useTranslate('upload', 'model');

  const onDownload = (prop) => {
    downloadFile(prop.cdnUrl);
  };

  const columns = getColumns({ t, onDownload });
  const tableProps = {
    remote: true,
    searchable: false,
    responsive: true,
    condensed: true,
    hover: true,
    classes: 'text-sm stylish-table',
    keyField: 'id',
    columns,
    ...props,
  };

  return <TablePaginated {...tableProps} />;
};

export default UploadTable;
