import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useCurrentUser, useFilterSelector } from '@hooks';
import { fetchCommissionBalances } from '@actions';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { Col, Container, Row } from 'reactstrap';
import { Currency, Text, NextLink, Date, CardFeatured } from '@dotone/react-ui-core';

const CommissionAndBalance = () => {
  const { t, tHint } = useTranslate('dashboard', 'commissionAndBalance');
  const { currentUser, timeZone, currency } = useCurrentUser();
  const dispatch = useDispatch();

  const { data, isLoading } = useFilterSelector(({ commissionBalances }) => commissionBalances, {
    timeZone,
    currency,
  });

  const empty = isEmpty(data);

  const {
    balanceLastUpdate,
    currentCreditBalance,
    currentEstimatedCommissions,
    estimatedBalance,
    pendingConversions,
  } = data;

  useEffect(() => {
    if (currentUser.id && empty) {
      dispatch(fetchCommissionBalances(timeZone, currency));
    }
  }, [dispatch, currentUser.id, empty, timeZone, currency]);

  const footer = (
    <Text nowrap muted size="xs">
      {balanceLastUpdate ? (
        <>
          {t('lastUpdated')}
          <Date fromNow value={balanceLastUpdate} className="d-inline-block ml-1" />
        </>
      ) : (
        t('noBalance')
      )}
    </Text>
  );

  return (
    <CardFeatured
      title={t('title')}
      hintProps={tHint('dashboard:hints.commissionAndBalance')}
      data={data}
      loading={isLoading}
      footer={footer}
    >
      <Container fluid>
        <Row className="pt-4">
          <Col className="text-center">
            <Text
              hintProps={{
                tKey: 'creditBalance',
                ...tHint('dashboard:hints.creditBalance'),
              }}
              className="text-nowrap"
            >
              {t('currentCreditBalance')}
            </Text>
            <Currency
              value={currentCreditBalance}
              className={classnames('h2', {
                'text-danger': currentCreditBalance < 0,
              })}
              currencyProps={{
                className: 'text-lg text-muted font-weight-normal mr-1',
              }}
            />
          </Col>
          <Col className="text-center">
            <Text
              hintProps={{
                tKey: 'estimatedCommissions',
                ...tHint('dashboard:hints.estimatedCommissions'),
              }}
              className="text-nowrap"
            >
              {t('currentEstimatedCommissions')}
            </Text>
            <Currency
              value={currentEstimatedCommissions}
              className={classnames('h2', {
                'text-danger': currentEstimatedCommissions < 0,
              })}
              currencyProps={{
                className: 'text-lg text-muted font-weight-normal mr-1',
              }}
            />
          </Col>
          <Col className="text-center">
            <Text
              hintProps={{ tKey: 'estimatedBalance', ...tHint('dashboard:hints.estimatedBalance') }}
              className="text-nowrap"
            >
              {t('estimatedBalance')}
            </Text>
            <Currency
              value={estimatedBalance}
              className={classnames('h2', {
                'text-danger': estimatedBalance < 0,
              })}
              currencyProps={{
                className: 'text-lg text-muted font-weight-normal mr-1',
              }}
            />
          </Col>
        </Row>
        <Row className="pb-4 pt-2">
          <Col>
            <NextLink
              href={{
                pathname: '/advertisers/transactions/pending',
              }}
            >
              <Text muted className="text-center mt-3">
                {t('pendingConversions', { value: pendingConversions })}
              </Text>
            </NextLink>
          </Col>
        </Row>
      </Container>
    </CardFeatured>
  );
};

export default CommissionAndBalance;
