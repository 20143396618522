import React, { useEffect, useState } from 'react';
import { useTranslate } from '@hooks';
import dynamic from 'next/dynamic';
import { upperCase, lowerCase } from 'lodash';
import { CardFeatured } from '@dotone/react-ui-core';

const VectorMap = dynamic(() => import('@react-jvectormap/core').then((mod) => mod.VectorMap), {
  ssr: false,
});

const ClicksByCountry = ({ totalVisitors, isLoading }) => {
  const { t } = useTranslate('dashboard', 'totalClicksPerCountries');
  const [world, setWorld] = useState();
  const [series, setSeries] = useState({
    regions: [
      {
        values: {},
        scale: ['#E5E5E5', '#0071A4'],
        normalizeFunction: 'polynomial',
        attribute: 'fill',
        legend: {
          vertical: true,
          title: t('legend'),
        },
      },
    ],
  });

  useEffect(() => {
    async function getWorld() {
      const { worldMill } = await import('@react-jvectormap/world');
      setWorld(worldMill);
    }

    getWorld();
  }, []);

  useEffect(() => {
    if (totalVisitors) {
      const values = {};
      Object.keys(totalVisitors).forEach((key) => {
        values[upperCase(key)] = totalVisitors[key];
      });
      setSeries((prev) => ({
        ...prev,
        regions: prev.regions.map((region) => ({
          ...region,
          values,
        })),
      }));
    }
  }, [totalVisitors]);

  return (
    <CardFeatured title={t('title')} data={totalVisitors} loading={isLoading}>
      <div style={{ height: 440 }}>
        {world && (
          <VectorMap
            map={world}
            series={series}
            onRegionTipShow={(e, el, code) => {
              el.html(`${el.html()} - ${totalVisitors[lowerCase(code)] || 0}`);
            }}
            backgroundColor="#fff"
            borderColor="#ccc"
            borderOpacity={0.9}
            borderWidth={1}
            zoomOnScroll={false}
            color="#ddd"
            regionStyle={{
              initial: {
                fill: 'rgba(0,0,0,0.1)',
              },
            }}
            markersSelectable
            markerStyle={{
              initial: {
                r: 8,
                fill: '#26c6da',
                'fill-opacity': 1,
                stroke: 'rgba(0,0,0,0.2)',
                'stroke-width': 0,
                'stroke-opacity': 1,
              },
            }}
            hoverColor="#79e580"
            hoverOpacity={null}
            normalizeFunction="linear"
            scaleColors={['#fff', '#ccc']}
            selectedColor="#c9dfaf"
            selectedRegion={[]}
            showTooltip
            insets={{
              top: 0,
              height: 440,
              left: 0,
            }}
            height={440}
            width={440}
            projection={{
              type: 'mill',
              centralMeridian: 11.5,
            }}
          />
        )}
      </div>
    </CardFeatured>
  );
};

export default ClicksByCountry;
