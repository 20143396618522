import React from 'react';
import { useTranslate } from '@hooks';
import { camelCase, compact } from 'lodash';
import { toBooleanArray } from '@dotone/react-ui-core/utils';
import { FilterBlock, FilterList } from '@dotone/react-ui-core';
import { AffiliateFilterBlock, OfferFilterBlock } from '../filters';

const CampaignFilterList = ({ filters, ...otherProps }) => {
  const { t } = useTranslate('campaign', 'model');
  const { tShared } = useTranslate('affiliate');
  const {
    offerIds = [],
    affiliateIds = [],
    approvalStatuses,
    isCustomPayout,
    payScheduleFrom,
    payScheduleTo,
  } = filters;

  const translate = (nestedNs, items = []) => {
    return items.map((item) => tShared(`${nestedNs}.${camelCase(item)}`));
  };

  return (
    <FilterList {...otherProps}>
      <OfferFilterBlock ids={offerIds} />
      <AffiliateFilterBlock ids={affiliateIds} />
      <FilterBlock
        label={t('::common:model.status')}
        items={translate('approvalStatus', approvalStatuses)}
        list={false}
      />
      <FilterBlock
        list={false}
        label={t('isCustomPayout')}
        items={toBooleanArray(isCustomPayout).map((v) => (v ? t('common:yes') : t('common:no')))}
      />
      <FilterBlock
        label={t('payScheduleExpiration')}
        items={[compact([payScheduleFrom, payScheduleTo]).join(' - ')]}
        list={false}
      />
    </FilterList>
  );
};

export default React.memo(CampaignFilterList);
