import React from 'react';
import { useTranslate } from '@hooks';
import { TablePaginated } from '@dotone/react-ui-core';
import MissingOrderFilterForm from './MissingOrderFilterForm';
import MissingOrderFilterList from './MissingOrderFilterList';
import {
  formatClickTime,
  formatConfirmingAt,
  formatDevice,
  formatOffer,
  formatOrderTotal,
  formatPaymentMethod,
  formatQuestionType,
  formatResult,
  formatScreenshotCdnUrl,
  formatStatus,
  formatTransactionStatus,
  formatNotes,
  formatDateTime,
  formatOrderNumber,
} from './formatters';

const getColumns = ({ t, tShared }) => [
  {
    dataField: 'id',
    text: t('id'),
    headerStyle: {
      minWidth: 'auto',
    },
  },
  {
    dataField: 'status',
    text: t('status'),
    headerAlign: 'center',
    formatter: formatStatus,
  },
  {
    dataField: 'transactionStatus',
    text: t('transactionStatus'),
    headerAlign: 'center',
    formatter: formatTransactionStatus,
  },
  {
    dataField: 'confirmingAt',
    text: t('confirmingAt'),
    classes: 'text-nowrap',
    formatter: (cell) => formatConfirmingAt(cell, t),
  },
  {
    dataField: 'affiliateId',
    text: t('affiliateName'),
    className: 'text-nowrap',
  },
  {
    dataField: 'questionType',
    text: t('questionType'),
    formatter: (cell) => formatQuestionType(cell, tShared),
  },
  {
    dataField: 'offer',
    text: t('offer'),
    headerStyle: {
      minWidth: '10rem',
    },
    formatter: formatOffer,
  },
  {
    dataField: 'orderNumber',
    text: t('orderNumber'),
    formatter: formatOrderNumber,
  },
  {
    dataField: 'orderTime',
    text: t('orderTime'),
    classes: 'text-nowrap',
    formatter: formatDateTime,
  },
  {
    dataField: 'orderTotal',
    text: t('orderTotal'),
    formatter: formatOrderTotal,
  },
  {
    dataField: 'paymentMethod',
    text: t('paymentMethod'),
    formatter: (cell) => formatPaymentMethod(cell, tShared),
  },
  {
    dataField: 'clickTime',
    text: t('clickTime'),
    classes: 'text-nowrap',
    formatter: (cell) => formatClickTime(cell, t),
  },
  {
    dataField: 'device',
    text: t('device'),
    formatter: (cell) => formatDevice(cell, tShared),
  },
  {
    dataField: 'screenshotCdnUrl',
    text: t('screenshotUrl'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: (cell) => formatScreenshotCdnUrl(cell, t),
  },
  {
    dataField: 'notes',
    text: t('notes'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: (cell) => formatNotes(cell, t),
  },
  {
    dataField: 'result',
    isDummyField: true,
    text: t('result'),
    formatter: formatResult,
  },
];

const MissingOrderTable = (props) => {
  const { t, tShared } = useTranslate('missingOrder', 'model');
  let columns = getColumns({ t, tShared });
  columns = columns.map((col) => ({
    ...col,
    style: { minWidth: 'auto' },
    headerStyle: {
      verticalAlign: 'middle',
      ...col.headerStyle,
    },
  }));

  return (
    <TablePaginated
      remote
      condensed
      hover
      responsive
      filterListRenderer={(filters) => <MissingOrderFilterList filters={filters} />}
      filterRenderer={(filterProps) => <MissingOrderFilterForm {...filterProps} />}
      headerClasses="text-wrap align-middle"
      classes="stylish-table text-sm"
      columns={columns}
      keyField="id"
      {...props}
    />
  );
};

export default MissingOrderTable;
