import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@hooks';
import { updateTextCreative } from '@actions/textCreativeActions';
import {
  TablePaginated,
  Modal,
  TextCreativePreview,
  FlexContainer,
  Text,
  DateTime,
} from '@dotone/react-ui-core';
import { formatCreativeTimePeriod, formatDateTime, formatCreativeLocales } from '../formatters';
import FilterForm from './TextCreativeFilterForm';
import FilterList from './TextCreativeFilterList';
import LandingPageModal from './TextCreativeLandingPageModal';
import RejectReasonModal from './TextCreativeRejectReasonModal';
import {
  formatOfferName,
  formatFeeContent,
  formatPreview,
  formatActions,
  formatImageCreativePreview,
} from './formatters';

export const formatActivePeriod = (row, t) => (
  <>
    {formatCreativeTimePeriod(row)}
    {row.publishedAt && (
      <div className="mt-2 text-warning">
        <div>{t('publishedAt')}</div>
        <DateTime value={row.publishedAt} className="text-nowrap" />
      </div>
    )}
  </>
);

const getColumns = ({ t, onChangeStatus, onPreview, onLandingPage, onRejectReason }) => [
  {
    dataField: 'id',
    text: t('id'),
    headerAlign: 'center',
    classes: 'text-center',
    hidden: true,
  },
  {
    dataField: 'createdAt',
    text: t('activeDateEnd'),
    headerAlign: 'center',
    classes: 'text-nowrap text-center',
    formatter: formatDateTime(),
  },
  {
    dataField: 'isInfinityTime',
    text: t('activePeriod'),
    formatter: (cell, row) => formatActivePeriod(row, t),
  },
  {
    dataField: 'offer.name',
    text: t('::common:model.offer'),
    formatter: formatOfferName,
  },
  {
    dataField: 'idWithName',
    text: t('feedName'),
  },
  {
    dataField: 'locales',
    text: t('locales'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: (cell) => formatCreativeLocales(cell),
  },
  {
    dataField: 'feedContent',
    text: t('content'),
    formatter: formatFeeContent,
  },
  {
    dataField: 'imageUrl',
    text: t('imageUrl'),
    formatter: formatImageCreativePreview,
    headerAlign: 'center',
    headerStyle: { width: '8%' },
  },
  {
    dataField: 'preview',
    text: t('preview'),
    headerAlign: 'center',
    headerStyle: { width: '8%' },
    classes: 'text-center',
    formatter: formatPreview(onPreview, onLandingPage, t),
  },
  {
    dataField: 'actions',
    text: '',
    headerStyle: { width: '8%' },
    classes: 'text-center',
    formatter: formatActions(t, onChangeStatus, onRejectReason),
  },
];

const TextCreativeTable = (props) => {
  const { t } = useTranslate('textCreative', 'model');
  const dispatch = useDispatch();
  const [previewOpened, setPreviewOpened] = useState(false);
  const [current, setCurrent] = useState();
  const [landingPageOpen, setLandingPageOpen] = useState(false);
  const [rejectReasonOpen, setRejectReasonOpen] = useState(false);

  const handleRejectReasonToggle = (textCreative) => {
    setCurrent(textCreative);
    setRejectReasonOpen(!rejectReasonOpen);
  };

  const handleLandingPageToggle = (textCreative) => {
    setCurrent(textCreative);
    setLandingPageOpen(!landingPageOpen);
  };

  const handleTogglePreview = () => {
    setPreviewOpened(!previewOpened);
  };

  const handlePreview = (textCreative) => {
    setCurrent(textCreative);
    handleTogglePreview();
  };

  const handleChangeStatus = (textCreative, status) => {
    dispatch(updateTextCreative(textCreative.id, { status }));
  };

  const columns = getColumns({
    t,
    onChangeStatus: handleChangeStatus,
    onPreview: handlePreview,
    onLandingPage: handleLandingPageToggle,
    onRejectReason: handleRejectReasonToggle,
  });

  const tableProps = {
    classes: 'text-sm stylish-table',
    columns,
    condensed: true,
    responsive: true,
    filterListRenderer: (filters) => <FilterList filters={filters} />,
    filterRenderer: (filterProps) => <FilterForm {...filterProps} />,
    hover: true,
    keyField: 'id',
    remote: true,
    searchable: false,
    selectable: true,
    ...props,
  };

  return (
    <>
      <TablePaginated {...tableProps} />
      <Modal
        title={
          <FlexContainer>
            <div className="mr-4">{t('preview')}</div>
            <Text size="sm" weight="normal">
              {t('textCreative:previewSubtitle')}
            </Text>
          </FlexContainer>
        }
        size="xlg"
        isOpen={previewOpened}
        footer={false}
        scrollable
        onToggle={handleTogglePreview}
      >
        <TextCreativePreview creative={current} full />
      </Modal>
      <LandingPageModal
        isOpen={landingPageOpen}
        textCreative={current}
        handleToggle={handleLandingPageToggle}
      />
      <RejectReasonModal
        isOpen={rejectReasonOpen}
        textCreative={current}
        handleToggle={handleRejectReasonToggle}
      />
    </>
  );
};

export default TextCreativeTable;
