import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@hooks';
import { updateUser } from '@actions';
import { ImageCropperModal } from '@dotone/react-ui-core';

const UserModalAvatar = ({ user }) => {
  const { t } = useTranslate('common');
  const dispatch = useDispatch();

  const handleImageUpload = ({ cdnUrl }) => {
    dispatch(
      updateUser(user.id, {
        avatarCdnUrl: cdnUrl,
      })
    );
  };

  return (
    <ImageCropperModal
      title={t('actions.editAvatar')}
      hint={t('hints.editAvatar')}
      path={`network/${user.id}/avatar`}
      buttonProps={{ className: 'bg-white px-1 py-0', style: { marginTop: -35, marginLeft: 70 } }}
      onSuccess={handleImageUpload}
    />
  );
};

export default React.memo(UserModalAvatar);
