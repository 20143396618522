import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRecentAffiliates } from '@actions';
import { useFilterSelector, useTranslate, useCurrentUser } from '@hooks';
import { orderBy } from 'lodash';
import { CardFeatured } from '@dotone/react-ui-core';
import { AffiliateTableMini } from '../Affiliates';

const RecentAffiliateList = () => {
  const { t } = useTranslate('dashboard', 'recentAffiliates');
  const dispatch = useDispatch();
  const { currentUser, locale, timeZone } = useCurrentUser();

  const { data, isLoading } = useFilterSelector(({ affiliateRecentList }) => affiliateRecentList, {
    timeZone,
  });

  useEffect(() => {
    if (currentUser.id && !data.length) {
      dispatch(fetchRecentAffiliates({ locale, timeZone }));
    }
  }, [dispatch, currentUser.id, locale, timeZone, data.length]);

  return (
    <CardFeatured title={t('title')} data={data} loading={isLoading} path="/advertisers/affiliates">
      <AffiliateTableMini data={orderBy(data, 'captured', 'desc')} loading={isLoading} />
    </CardFeatured>
  );
};

export default RecentAffiliateList;
