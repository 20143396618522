import React, { useState } from 'react';
import { partition, chain } from 'lodash';
import {
  DateTime,
  ImageCircle,
  FlexContainer,
  Text,
  MediaCategoryIcon,
  ButtonCircle,
  AffiliateLogPreview,
  AffiliateStatusBadge,
} from '@dotone/react-ui-core';
import { ChevronsRight } from 'react-feather';
import AffiliateLabelDropdown from './AffiliateLabelDropdown';
import AffiliateDetailModal from './AffiliateDetailModal';
import AffiliateSiteInfoModal from './AffiliateSiteInfoModal';

const Empty = () => <Text muted>--</Text>;

export const formatAvatar = ({ currentUser, width = 60, cell, row }) => {
  function RenderAvatar() {
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = () => setIsOpen(!isOpen);

    const avatar = <ImageCircle avatar src={cell} width={width} className="border border-light" />;

    if (currentUser.pro || row.direct || currentUser.partialPro) {
      return (
        <>
          <a onClick={onToggle}>{avatar}</a>
          <AffiliateDetailModal affiliate={row} onCancel={onToggle} isOpen={isOpen} />
        </>
      );
    }

    return avatar;
  }

  return <RenderAvatar />;
};

export const formatAffiliateType = (cell, { siteInfos }) => {
  const mediaCategories = chain(siteInfos)
    .filter((siteInfo) => !!siteInfo.mediaCategory)
    .map((siteInfo) => siteInfo.mediaCategory)
    .uniqBy('id')
    .value();

  if (mediaCategories.length) {
    return (
      <ul className="m-0 p-0 list-unstyled">
        {mediaCategories.map((mediaCategory) => (
          <li key={mediaCategory.id} className="text-nowrap">
            {mediaCategory.parentCategoryName} <ChevronsRight size={12} /> {mediaCategory.name}
          </li>
        ))}
      </ul>
    );
  }

  return <Empty />;
};

export const formatNotes = ({ onNoteOpen }) =>
  function RenderNotes(cell, row) {
    return (
      <>
        <div className="td-overlay p-2">
          <ButtonCircle variant="add" color="primary" size="sm" onClick={() => onNoteOpen(row)} />
        </div>
        <AffiliateLogPreview values={cell} />
      </>
    );
  };

const ListMediaCategories = ({ title, items, onSiteInfoOpen }) => (
  <div className="my-2">
    <Text muted>{title}</Text>
    <FlexContainer wrap>
      {items.length ? (
        items.map((siteInfo) => (
          <div key={siteInfo.id} className="mr-2 mt-2">
            <a onClick={() => onSiteInfoOpen && onSiteInfoOpen(siteInfo)}>
              <MediaCategoryIcon
                size="md"
                mediaCategory={siteInfo.mediaCategory}
                id={`mc-${siteInfo.id}`}
              />
            </a>
          </div>
        ))
      ) : (
        <Empty />
      )}
    </FlexContainer>
  </div>
);

export const formatWebsite = ({ t, currentUser, cell, row }) => {
  function RenderWebsite() {
    const [activeSiteInfo, setSiteInfo] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const viewable = currentUser.pro || row.direct || currentUser.partialPro;

    const onToggle = () => {
      setIsOpen(!isOpen);
    };

    const onSiteInfoClick = (siteInfo) => {
      setSiteInfo(siteInfo);
      setIsOpen(true);
    };

    let siteInfos = cell.filter((siteInfo) => !!siteInfo.mediaCategory);

    if (!viewable) {
      siteInfos = chain(siteInfos)
        .sortBy((siteInfo) => (siteInfo.verified ? 0 : 1))
        .uniqBy((siteInfo) => siteInfo.mediaCategory.id)
        .value();
    }
    const [verified, unverified] = partition(siteInfos, (siteInfo) => siteInfo.verified);

    return siteInfos.length ? (
      <>
        <ListMediaCategories
          title={t('verified')}
          items={verified}
          onSiteInfoOpen={viewable ? onSiteInfoClick : null}
        />
        <ListMediaCategories
          title={t('unverified')}
          items={unverified}
          onSiteInfoOpen={viewable ? onSiteInfoClick : null}
        />
        {viewable && (
          <AffiliateSiteInfoModal siteInfo={activeSiteInfo} onCancel={onToggle} isOpen={isOpen} />
        )}
      </>
    ) : (
      <Empty />
    );
  }

  return <RenderWebsite />;
};

export const formatLabel = (value, row) => <AffiliateLabelDropdown value={value} affiliate={row} />;

export const formatLastJoinedAt = (t) =>
  function RenderActivity(lastJoinedAt) {
    return (
      <div className="text-nowrap text-center">
        <Text muted>{t('lastJoinedAt')}</Text>
        <DateTime value={lastJoinedAt} className="font-bold" />
      </div>
    );
  };

export const formatStatus = (value) => <AffiliateStatusBadge value={value} />;

export const formatAffiliateStats = (row) => (
  <div>
    <span>{row.clicks > 0 ? row.clicks : '--'}</span>
    <span>/</span>
    <span>{row.captured > 0 ? row.captured : '--'}</span>
  </div>
);
