import { apiKeyTypes as actionTypes } from '@actions';
import { commonState, commonReducer } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true }),
};

const defaultState = {
  ...commonState.default(),
};

export const apiKeyList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const apiKeyCreate = (state = defaultState, action) =>
  commonReducer(actionTypes.CREATE, state, action);

export const apiKeyUpdate = (state = defaultState, action) =>
  commonReducer(actionTypes.UPDATE, state, action);

export const apiKeyDelete = (state = defaultState, action) =>
  commonReducer(actionTypes.DELETE, state, action);
