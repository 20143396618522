import React from 'react';
import { useTranslate } from '@hooks';
import { missingOrderStatuses } from '@dotone/react-ui-core/constants';
import classnames from 'classnames';
import { MessageSquare as MessageIcon } from 'react-feather';
import {
  Form,
  ModalWithInitialButton as Modal,
  CurrencyInput,
  PercentageInput,
  DateTime,
  StatApprovalBadge,
} from '@dotone/react-ui-core';
import { Col, Row, Table } from 'reactstrap';

const MissingOrderResultButton = ({ missingOrder, ...otherProps }) => {
  const { t } = useTranslate('missingOrder', 'model');
  const { status, consideredCompleted, statusReason, order, orderTime } = missingOrder;

  return (
    consideredCompleted &&
    (order || statusReason) && (
      <Modal
        title={
          order ? t('missingOrder:resultModal.title', { id: missingOrder.id }) : t('statusReason')
        }
        size={order ? 'xl' : 'md'}
        dense
        footerVariant="close"
        buttonProps={{
          color: 'link',
          className: classnames('text-sm p-0', {
            'text-danger': status === missingOrderStatuses.REJECTED,
          }),
          children: (
            <>
              <MessageIcon size={16} className="mr-1" />
              {t('common:actions.seeDetails')}
            </>
          ),
        }}
        {...otherProps}
      >
        {order ? (
          <Row>
            <Col lg={4}>
              <Table>
                <thead>
                  <tr>
                    <th colSpan={2}>{t('order')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('orderTime')}</td>
                    <td>
                      <DateTime value={orderTime} />
                    </td>
                  </tr>
                  <tr>
                    <td>{t('order:model.orderNumber')}</td>
                    <td>{order?.orderNumber}</td>
                  </tr>
                  <tr>
                    <td>{t('order:model.stepName')}</td>
                    <td>{order?.stepName}</td>
                  </tr>
                  <tr>
                    <td>{t('status')}</td>
                    <td>
                      <StatApprovalBadge value={missingOrder.transactionStatus} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Form defaultValues={order}>
                <Table>
                  <thead>
                    <tr>
                      <th colSpan={3}>{t('order:model.truePay')}</th>
                    </tr>
                    <tr>
                      <th>{t('orderTotal')}</th>
                      <th>{t('order:model.trueShare')}</th>
                      <th>{t('order:model.truePay')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <CurrencyInput name="total" disabled />
                      </td>
                      <td>
                        <PercentageInput name="trueShare" disabled />
                      </td>
                      <td>
                        <CurrencyInput name="truePay" disabled />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Col>
          </Row>
        ) : (
          statusReason
        )}
      </Modal>
    )
  );
};

export default MissingOrderResultButton;
