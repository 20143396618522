import { MissingOrderApi } from '@api';
import { missingOrderTypes as actionTypes, downloadTypes } from './types';

export const downloadMissingOrder = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.DOWNLOAD.LOADING,
  });

  MissingOrderApi.download(params)
    .then((response) => {
      dispatch({
        type: downloadTypes.LIST.HYDRATE,
        payload: response,
      });

      dispatch({
        type: actionTypes.DOWNLOAD.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.DOWNLOAD.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const fetchMissingOrders = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  MissingOrderApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const updateMissingOrder = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  MissingOrderApi.update(id, { missingOrder: params })
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: 'notify:general.save.error',
      });
    });
};
