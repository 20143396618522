import React, { useState } from 'react';
import { useTranslate } from '@hooks';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Users as ManagerIcon } from 'react-feather';
import { FlexContainer, Text, Modal, ImageCircle, ManagerCard } from '@dotone/react-ui-core';

const ManagerDropdown = ({ current = {}, managers = [], onChange, ...otherProps }) => {
  const { t } = useTranslate('common', 'navbar');
  const [isOpen, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <UncontrolledDropdown {...otherProps}>
        <DropdownToggle caret>
          <ManagerIcon size={16} className="mr-2" />
          {t('managers')}
        </DropdownToggle>
        <DropdownMenu right>
          {managers.map((manager) => (
            <DropdownItem
              key={manager.id}
              onClick={handleToggle}
              onMouseEnter={() => onChange(manager)}
            >
              <FlexContainer align="center">
                <ImageCircle avatar src={manager.avatarCdnUrl} width={25} className="mr-1" />
                <Text truncate>{manager.fullName}</Text>
              </FlexContainer>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {current.id && (
        <Modal isOpen={isOpen} header={false} footerVariant="close" onToggle={handleToggle}>
          <ManagerCard manager={current} />
        </Modal>
      )}
    </>
  );
};

export default ManagerDropdown;
