import React from 'react';
import { useTranslate } from '@hooks';
import { toArray } from '@dotone/react-ui-core/utils';
import { FilterBlock, FilterList } from '@dotone/react-ui-core';

const FeedFilterList = ({ filters, ...otherProps }) => {
  const { t, tShared } = useTranslate('feed', 'model');
  const { feedTypes, role } = filters;

  return (
    <FilterList {...otherProps}>
      <FilterBlock
        label={t('feedType')}
        items={toArray(feedTypes)}
        listProps={{ field: false, t: (key) => tShared(`feedType.${key}`) }}
      />
      <FilterBlock
        label={t('role')}
        items={toArray(role)}
        listProps={{ field: false, t: (key) => tShared(`common:appRole.${key}`) }}
      />
    </FilterList>
  );
};

export default React.memo(FeedFilterList);
