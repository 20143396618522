import React from 'react';
import { useTranslate } from '@hooks';
import { sum } from 'lodash';
import {
  TablePaginated,
  ImageWithPreview,
  ModalWithInitialButton as Modal,
  ColumnChart,
  ExternalLink,
  CreativeStatusBadge,
} from '@dotone/react-ui-core';
import { formatCreativeTimePeriod, formatDateTime, formatCreativeLocales } from '../formatters';
import FilterForm from './ImageCreativeFilterForm';
import Form from './ImageCreativeForm';
import FilterList from './ImageCreativeFilterList';

const formatOfferName = (cell, row) => (
  <>
    ({row.offer.id}) {cell}
  </>
);

const formatImageCreativePreview = (cell, row) => (
  <>
    <ImageWithPreview src={cell} previewSrc={cell} alt={`Banner: {row.id}`} size={100} />
    {row.width}x{row.height}
  </>
);

const formatDestinationUrl = (cell) => cell && <ExternalLink href={cell} />;

const formatImageCreativeStatus = (cell) => <CreativeStatusBadge value={cell} />;

const formatActions = ({ t, row }) => (
  <Modal
    dense
    buttonProps={{ variant: 'edit', color: 'link' }}
    title={t('imageCreative:actions.edit')}
    size="xl"
    footer={false}
    scrollable={false}
    form={<Form imageCreative={row} />}
  />
);

const formatStats = (cell) => (
  <div>
    <ColumnChart
      data={cell}
      chart={{
        backgroundColor: 'rgba(0,0,0,0)',
        height: 60,
        width: 140,
      }}
    />
    <div>{sum(cell)}</div>
  </div>
);

const getColumns = ({ t }) => [
  {
    dataField: 'id',
    text: t('id'),
  },
  {
    dataField: 'createdAt',
    text: t('createdAt'),
    formatter: formatDateTime(),
  },
  {
    dataField: 'updatedAt',
    text: t('updatedAt'),
    formatter: formatDateTime(),
  },
  {
    dataField: 'locales',
    text: t('locales'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: (cell) => formatCreativeLocales(cell),
  },
  {
    dataField: 'offer.name',
    text: t('offerName'),
    formatter: formatOfferName,
  },
  {
    dataField: 'isInfinityTime',
    text: t('activePeriod'),
    formatter: (cell, row) => formatCreativeTimePeriod(row),
  },
  {
    dataField: 'clientUrl',
    text: t('clientUrl'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: formatDestinationUrl,
  },
  {
    dataField: 'cdnUrl',
    text: t('cdnUrl'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: formatImageCreativePreview,
  },
  {
    dataField: 'downloadCounts',
    text: t('downloadCounts'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: formatStats,
  },
  {
    dataField: 'status',
    text: t('status'),
    headerAlign: 'center',
    classes: 'text-center',
    formatter: formatImageCreativeStatus,
  },
  {
    dataField: 'statusReason',
    text: t('statusReason'),
  },
  {
    dataField: 'actions',
    text: '',
    headerAlign: 'center',
    classes: 'text-center',
    formatter: (cell, row) => formatActions({ t, row }),
  },
];

const ImageCreativeTable = (props) => {
  const { t } = useTranslate('imageCreative', 'model');

  const columns = getColumns({ t });

  const tableProps = {
    classes: 'stylish-table text-sm',
    columns,
    condensed: true,
    filterListRenderer: (filters) => <FilterList filters={filters} />,
    filterRenderer: (filterProps) => <FilterForm {...filterProps} />,
    responsive: true,
    hover: true,
    keyField: 'id',
    remote: true,
    searchable: false,
    selectable: true,
    ...props,
  };

  return <TablePaginated {...tableProps} />;
};

export default ImageCreativeTable;
