import React from 'react';
import { useTranslate } from '@hooks';
import { toArray } from '@dotone/react-ui-core/utils';
import { FilterBlock, FilterList } from '@dotone/react-ui-core';
import { OfferFilterBlock } from '../filters';

const StatSummaryFilterList = ({ periodical = false, filters, reportType, ...otherProps }) => {
  const { t } = useTranslate('report', 'filter');

  const {
    dimension = 'offerId',
    startDate,
    endDate,
    offerIds = [],
    affiliateIds,
    imageCreativeIds,
    textCreativeIds,
  } = filters;

  return (
    <FilterList {...otherProps}>
      {!periodical && reportType !== 'performance' && (
        <FilterBlock list={false} label={t('dimension')} items={toArray(t(dimension))} />
      )}
      <FilterBlock list={false} label={t('startDate')} items={toArray(startDate)} />
      <FilterBlock list={false} label={t('endDate')} items={toArray(endDate)} />
      <OfferFilterBlock ids={offerIds} />
      <FilterBlock list={false} label={t('affiliateId')} items={toArray(affiliateIds)} />
      <FilterBlock list={false} label={t('imageCreativeId')} items={toArray(imageCreativeIds)} />
      <FilterBlock list={false} label={t('textCreativeId')} items={toArray(textCreativeIds)} />
    </FilterList>
  );
};

export default React.memo(StatSummaryFilterList);
