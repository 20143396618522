import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@hooks';
import { updateApiKey, deleteApiKey } from '@actions';
import { apiKeyStatuses as statuses } from '@dotone/react-ui-core/constants';
import classnames from 'classnames';
import { camelCase } from 'lodash';
import { Key as KeyIcon } from 'react-feather';
import { Table, Input, InputClipboard, ButtonFlat, Date } from '@dotone/react-ui-core';

const formatCreatedAt = (cell) => <Date value={cell} />;

const formatValue = (cell) => <InputClipboard value={cell} prepend={<KeyIcon size={16} />} />;

const formatLastUsed = (cell, t) =>
  cell ? <Date value={cell} format="YYYY-MM-DD hh:mm A" /> : t('common:na');

const formatStatus = (row, tShared, onUpdate) => {
  const { id, status } = row;
  const active = status === statuses.ACTIVE;

  const handleToggleActive = () => {
    onUpdate({ ...row, status: active ? statuses.INACTIVE : statuses.ACTIVE });
  };

  return (
    <Input
      type="switch"
      name={`status${id}`}
      checked={active}
      className={classnames('font-medium', { 'text-dark-primary': active })}
      label={tShared(camelCase(status))}
      onChange={handleToggleActive}
    />
  );
};

const formatActions = (row, t, onDelete) => {
  const handleDelete = () => {
    onDelete(row);
  };

  return <ButtonFlat confirm variant="delete" color="danger" onClick={handleDelete} />;
};

const getColumns = ({ t, tShared, onUpdate, onDelete }) => [
  {
    dataField: 'id',
    text: 'ID',
    hidden: true,
  },
  {
    dataField: 'createdAt',
    text: t('createdAt'),
    formatter: formatCreatedAt,
  },
  {
    dataField: 'value',
    text: t('apiKey'),
    formatter: formatValue,
  },
  {
    dataField: 'lastUsedAt',
    text: t('lastUsedAt'),
    classes: 'text-nowrap',
    formatter: (cell) => formatLastUsed(cell, t),
  },
  {
    dataField: 'status',
    text: t('common:model.status'),
    formatter: (cell, row) => formatStatus(row, tShared, onUpdate),
  },
  {
    dataField: 'actions',
    text: '',
    isDummyField: true,
    formatter: (cell, row) => formatActions(row, t, onDelete),
  },
];

const ApiKeyTable = (props) => {
  const { t } = useTranslate('apiKey', 'model');
  const { tShared } = useTranslate('apiKey', 'status');
  const dispatch = useDispatch();

  const handleUpdate = ({ id, ...params }) => {
    dispatch(updateApiKey(id, params));
  };

  const handleDelete = ({ id }) => {
    dispatch(deleteApiKey(id));
  };

  const columns = getColumns({ t, tShared, onUpdate: handleUpdate, onDelete: handleDelete });

  return (
    <Table
      remote
      responsive
      condensed
      hover
      classes="stylish-table text-custom"
      keyField="id"
      columns={columns}
      {...props}
    />
  );
};

export default ApiKeyTable;
