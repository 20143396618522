import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { createEasyStoreSetup } from '@actions';
import { envVars } from '@constants';
import { FormGroup, Label, Form } from 'reactstrap';
import { Button, FormAction } from '@dotone/react-ui-core';
import { OfferSearchInput } from '../forms';

const EasyStoreSetupForm = ({ defaultValues = {}, onCancel, ...otherProps }) => {
  const { t } = useTranslate('easyStoreSetup');
  const dispatch = useDispatch();
  const { isLoading } = useShallowEqualSelector(({ easyStoreSetupCreate }) => easyStoreSetupCreate);

  const { connectForm, handleSubmit, watch } = useForm({ defaultValues });
  const offerId = watch('offerId');

  const authorize = () => {
    const params = new URLSearchParams({
      app_id: envVars.easyStoreAppId,
      redirect_uri: `${window.location.origin}/advertisers/oauth/easy-store/callback`,
      scope:
        'read_orders,read_products,write_script_tags,read_script_tags,write_snippets,read_snippets',
    });

    const url = new URL('https://admin.easystore.co');
    url.pathname = '/oauth/authorize';
    url.search = params.toString();

    window.open(
      url.toString(),
      null,
      'width=800,height=800,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no'
    );
  };

  useEffect(() => {
    const callback = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      try {
        const { code, provider, type, shop } = event.data;
        if (type === 'oauth' && provider === 'easy-store') {
          dispatch(createEasyStoreSetup({ easyStoreSetup: { code, offerId, storeDomain: shop } }));
        }
      } catch (err) {
        // do nothing
      }
    };

    window.addEventListener('message', callback);

    return () => window.removeEventListener('message', callback);
  }, [dispatch, offerId]);

  return (
    <Form onSubmit={handleSubmit(authorize)} {...otherProps}>
      {connectForm(
        <div className="form-inner">
          <FormGroup>
            <Label required>{t('form.offer')}</Label>
            <OfferSearchInput name="offerId" required />
          </FormGroup>
        </div>
      )}
      <FormAction align="right">
        <Button variant="save" color="primary" submitting={isLoading}>
          {t('actions.install')}
        </Button>
      </FormAction>
    </Form>
  );
};

export default EasyStoreSetupForm;
