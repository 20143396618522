import API from './api';

export default class DownloadApi {
  static getList(params) {
    return API.get('/advertisers/downloads', params);
  }

  static delete(id, params) {
    return API.delete(`/advertisers/downloads/${id}`, params);
  }
}
