import API from './api';

export default class ContactApi {
  static create(params) {
    return API.post('/advertisers/contact_lists', { contactList: params });
  }

  static getList(params) {
    return API.get('/advertisers/contact_lists', params);
  }

  static update(id, params) {
    return API.put(`/advertisers/contact_lists/${id}`, { contactList: params });
  }
}
