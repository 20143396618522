import React from 'react';
import { conversionTypes, campaignStatuses } from '@dotone/react-ui-core/constants';
import { camelCase, compact } from 'lodash';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import {
  Text,
  FlexContainer,
  Date,
  DateRange,
  Currency,
  Percentage,
  DateTime,
  CampaignStatusBadge,
} from '@dotone/react-ui-core';
import CampaignStatusDropdown from './CampaignStatusDropdown';

export const formatOfferName = (cell) => getNameWithId(cell);

export const formatStatus = (cell, row, currentUser) => {
  if (currentUser.pro) {
    return <CampaignStatusDropdown campaign={row} value={cell} />;
  }
  return <CampaignStatusBadge value={cell} />;
};

const Payout = ({ convType, value, dates, muted = false, className }) => {
  return (
    <center className={className} style={{ opacity: muted ? '50%' : '100%' }}>
      <FlexContainer justify="center" className="text-lg font-weight-bold">
        <Text className="text-uppercase mr-1">{convType}</Text>
        {convType === conversionTypes.CPS ? (
          <Percentage value={value} />
        ) : (
          <Currency value={value} />
        )}
      </FlexContainer>
      {dates && (
        <div className="text-sm text-nowrap">
          (<DateRange values={compact(dates)} className="d-inline-block" />)
        </div>
      )}
    </center>
  );
};

export const formatDefaultPayouts = (cell) => {
  const { type, originalValue, startsAt, endsAt } = cell || {};

  return (
    (cell &&
      (type === 'limited' ? (
        <>
          <Payout {...cell} dates={[startsAt, endsAt]} className="text-danger" />
          <Payout muted {...cell} value={originalValue} />
        </>
      ) : (
        <Payout {...cell} />
      ))) ||
    '--'
  );
};

export const formatCustomPayouts = (cell) => {
  const { type, startsAt, endsAt } = cell || {};

  return (
    (cell &&
      (type === 'limited' ? (
        <Payout {...cell} dates={[startsAt, endsAt]} className="text-danger" />
      ) : (
        <Payout {...cell} className="text-danger" />
      ))) ||
    '--'
  );
};

export const formatJoinDate = ({ t }) =>
  function RenderJoinDate(cell, row) {
    return (
      <>
        {row.approvalStatus !== campaignStatuses.PENDING && row.approvalStatusChangedAt && (
          <div className="font-bold">
            {t(`${camelCase(row.approvalStatus)}On`)}
            <DateTime value={row.approvalStatusChangedAt} className="mb-2 text-nowrap" />
          </div>
        )}
        <div className="text-muted text-sm">
          {t('appliedOn')}
          <Date value={cell} />
        </div>
      </>
    );
  };
