import React from 'react';
import { TextBlock } from '@dotone/react-ui-core';

const OfferDataCell = ({
  label,
  hintProps,
  className = 'text-center',
  children,
  ...otherProps
}) => (
  <td className={className} {...otherProps}>
    <TextBlock
      invert
      label={label || hintProps?.title}
      labelProps={{ hintProps }}
      contentProps={{ className: 'text-md font-bold' }}
    >
      {children}
    </TextBlock>
  </td>
);

export default OfferDataCell;
