import React from 'react';
import { camelCase } from 'lodash';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import { missingOrderStatuses as statuses } from '@dotone/react-ui-core/constants';
import {
  Currency,
  ModalWithInitialButton as Modal,
  DateTime,
  StatApprovalBadge,
  MissingOrderStatusBadge as StatusBadge,
} from '@dotone/react-ui-core';
import ResultButton from './MissingOrderResultButton';
import MissingOrderStatusDropdown from './MissingOrderStatusDropdown';
import { OrderFormModal } from '../Orders';

const previewButtonProps = (t) => ({
  variant: 'preview',
  color: 'link',
  size: 'sm',
  className: 'text-sm p-0',
  children: t('common:actions.seeDetails'),
});

export const formatQuestionType = (cell, t) => t(`questionType.${camelCase(cell)}`);

export const formatOffer = (cell) => cell && getNameWithId(cell);

export const formatDateTime = (cell) => cell && <DateTime value={cell} />;

export const formatOrderTotal = (cell) => <Currency value={cell} />;

export const formatPaymentMethod = (cell, t) => cell && t(`paymentMethod.${camelCase(cell)}`);

export const formatConfirmingAt = (cell, t) => (cell ? formatDateTime(cell) : t('common:na'));

export const formatClickTime = (cell, t) => (cell ? formatDateTime(cell) : t('common:na'));

export const formatDevice = (cell, t) => cell && t(`device.${camelCase(cell)}`);

export const formatScreenshotCdnUrl = (cell, t) =>
  cell && (
    <Modal footerVariant="close" buttonProps={previewButtonProps(t)} title={t('screenshotUrl')}>
      <center>
        <img className="img-fluid" src={cell} alt={cell} />
      </center>
    </Modal>
  );

export const formatNotes = (cell, t) =>
  cell && (
    <Modal
      dense
      title={t('notes')}
      size="md"
      footerVariant="close"
      buttonProps={previewButtonProps(t)}
    >
      {cell}
    </Modal>
  );

export const formatStatus = (cell, row) => {
  if (cell === statuses.CONFIRMING) {
    return <MissingOrderStatusDropdown missingOrder={row} className="d-block" />;
  }

  const value = row.consideredCompleted ? statuses.COMPLETED : cell;
  return <StatusBadge value={value} className="d-block" />;
};

export const formatTransactionStatus = (cell) => {
  return cell && <StatApprovalBadge value={cell} className="d-block" />;
};

export const formatResult = (cell, row) => <ResultButton missingOrder={row} />;

export const formatOrderNumber = (cell, row) =>
  row.order ? <OrderFormModal order={row.order} /> : cell;
