import React from 'react';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { updateUser } from '@actions';
import { arrayToFormFields, formFieldsToArray } from '@dotone/react-ui-core/utils';
import { withHttps } from '@utils';
import { FormGroup, Form } from 'reactstrap';
import {
  InputGroup,
  FormAction,
  Input,
  Button,
  AlertPopover,
  Text,
  InputArray,
  Label,
} from '@dotone/react-ui-core';
import { CountrySelect, CategoryGroupSelect } from '../forms';

const UserForm = ({ user, ...otherProps }) => {
  const { t } = useTranslate('profile');
  const tModel = useTranslate('network', 'model').t;
  const dispatch = useDispatch();

  const defaultValues = {
    ...user,
    brands: arrayToFormFields(user.brands || []),
  };

  const { connectForm, handleSubmit, setError } = useForm({ defaultValues });

  const {
    isLoading,
    status,
    data = { message: {} },
  } = useShallowEqualSelector(({ userUpdate }) => userUpdate);

  const handleSubmitForm = (values) => {
    dispatch(
      updateUser(user.id, {
        ...values,
        brands: formFieldsToArray(values.brands),
        companyUrl: withHttps(values.companyUrl),
      })
    );
  };

  useDeepCompareEffect(() => {
    if (status === 'error' && data.message) {
      Object.keys(data.message).forEach((key) =>
        setError(key, { type: 'custom', message: data.message[key] })
      );
    }
  }, [status, data.message, setError, user]);

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} {...otherProps}>
      {connectForm(
        <>
          <FormGroup>
            <Label for="name">{tModel('name')}</Label>
            <AlertPopover html t={t} tKey="edit.hints.companyName" iconProps={{ size: 14 }} />
            <Input name="name" placeholder={tModel('name')} disabled />
          </FormGroup>

          <FormGroup>
            <Label for="contactEmail" required>
              {tModel('contactEmail')}
            </Label>
            <Input
              name="contactEmail"
              placeholder={tModel('contactEmail')}
              rules={{ required: true }}
            />
            <Text size="sm" color="info">
              {t('reminder')}
            </Text>
          </FormGroup>

          <FormGroup>
            <Label for="contactName" required>
              {tModel('contactName')}
            </Label>
            <Input
              name="contactName"
              placeholder={tModel('contactName')}
              rules={{ required: true }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="contactPhone" required>
              {tModel('contactPhone')}
            </Label>
            <Input
              name="contactPhone"
              id="contactPhone"
              placeholder={tModel('contactPhone')}
              rules={{ required: true }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="countryId" required>
              {tModel('country')}
            </Label>
            <CountrySelect
              name="countryId"
              id="countryId"
              placeholder={tModel('country')}
              rules={{ required: true }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="companyUrl" required>
              {tModel('companyUrl')}
            </Label>
            <InputGroup
              name="companyUrl"
              prepend="https://"
              inputProps={{
                type: 'text',
                placeholder: tModel('companyUrl'),
                rules: { required: true },
              }}
            />
          </FormGroup>

          <FormGroup label={tModel('brands')}>
            <InputArray
              label={<Label className="mr-auto">{tModel('brands')}</Label>}
              name="brands"
              addClassName="d-flex align-items-end"
            />
          </FormGroup>

          <FormGroup label={tModel('categoryGroups')}>
            <Label for="categoryGroupIds" required>
              {tModel('categoryGroups')}
            </Label>
            <CategoryGroupSelect
              name="categoryGroupIds"
              multi
              rules={{ required: true, maxLength: 5 }}
            />
          </FormGroup>
          <FormAction align="right">
            <Button variant="save" color="primary" submitting={isLoading}>
              {t('edit.submit')}
            </Button>
          </FormAction>
        </>
      )}
    </Form>
  );
};

export default UserForm;
