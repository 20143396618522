import React from 'react';
import { useTranslate } from '@hooks';
import { toArray } from '@dotone/react-ui-core/utils';
import { missingOrderStatusTKey } from '@dotone/react-ui-core/helpers';
import { FilterBlock, FilterList } from '@dotone/react-ui-core';

const MissingOrderFilterList = ({ filters, ...otherProps }) => {
  const { t, tShared } = useTranslate('missingOrder', 'model');
  const { statuses } = filters;

  const translate = (key) => {
    const tKey = missingOrderStatusTKey('network', key);

    return tShared(`status.${tKey}`);
  };

  return (
    <FilterList {...otherProps}>
      <FilterBlock
        label={t('status')}
        items={toArray(statuses)}
        listProps={{ field: false, t: translate }}
      />
    </FilterList>
  );
};

export default React.memo(MissingOrderFilterList);
