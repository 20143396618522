import { CountryApi } from '@api';
import { countryTypes as actionTypes } from './types';

export const fetchCountries = (locale) => (dispatch) => {
  dispatch({
    type: actionTypes.LOADING,
  });

  CountryApi.getList({ locale })
    .then((response) => {
      dispatch({
        type: actionTypes.SUCCESS,
        payload: { [locale]: response },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
