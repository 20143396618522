import React from 'react';
import { useTranslate } from '@hooks';
import { missingOrderStatusSummaries as summaries } from '@constants';
import { camelCase } from 'lodash';
import { Input } from '@dotone/react-ui-core';

const MissingOrderSummarySelect = ({ type, ...otherProps }) => {
  const { t } = useTranslate('missingOrder', 'statusSummary');

  const options = Object.values(summaries[type]).map((value) => ({
    label: t(`${camelCase(type)}.${camelCase(value)}`),
    value,
  }));

  return <Input type="select" options={options} {...otherProps} multi={false} />;
};

export default MissingOrderSummarySelect;
