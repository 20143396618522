import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useLocalizedDataSelector } from '@hooks';
import { fetchCurrencies } from '@actions';
import { toSelectOptions } from '@dotone/react-ui-core/utils';
import { Input } from '@dotone/react-ui-core';

const CurrencySelect = ({ codes, ...otherProps }) => {
  const { locale } = useTranslate('common');
  const dispatch = useDispatch();
  let currencies = useLocalizedDataSelector(({ currencyList }) => currencyList);
  if (codes) {
    currencies = currencies.filter((currency) => codes.indexOf(currency.code) > -1);
  }
  const options = toSelectOptions(currencies, { label: 'name' });

  useEffect(() => {
    if (!currencies.length) {
      dispatch(fetchCurrencies(locale));
    }
  }, [dispatch, locale, currencies.length]);

  return !!currencies.length && <Input type="select" options={options} {...otherProps} />;
};

export default CurrencySelect;
