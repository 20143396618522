import Router from 'next/router';
import { PasswordApi } from '@api';
import { passwordTypes as actionTypes } from './types';

export const resetPassword = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.RESET.LOADING,
  });

  const { redirectTo, ...data } = params;

  PasswordApi.reset(data)
    .then(() => {
      dispatch({
        type: actionTypes.RESET.SUCCESS,
        message: 'sent',
      });
      Router.push(redirectTo || '/advertisers/login');
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.RESET.FAILURE,
        message: error.statusText,
      });
    });
};

export const createPassword = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  const { redirectTo, ...data } = params;

  PasswordApi.create(data)
    .then(() => {
      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        message: 'updated',
      });
      Router.push(redirectTo || '/advertisers/login');
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        message: error.statusText,
      });
    });
};

export const updatePassword = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  PasswordApi.update(params)
    .then(() => {
      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        message: 'updated',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: error.statusText,
      });
    });
};
