import API from './api';

export default class OfferApi {
  static getList(params) {
    return API.get('/advertisers/network_offers', params);
  }

  static search(params) {
    return API.get('/advertisers/network_offers/search', params);
  }
}
