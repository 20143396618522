import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useCurrentUser, useFilterSelector } from '@hooks';
import { fetchRecentConversions } from '@actions';
import moment from 'moment';
import { toDate } from '@dotone/react-ui-core/utils';
import { CardFeatured } from '@dotone/react-ui-core';
import ConversionTable from './ConversionTable';

const RecentConversions = () => {
  const { t, tHint } = useTranslate('dashboard', 'recentConversions');
  const dispatch = useDispatch();
  const { currentUser, currency, timeZone, locale } = useCurrentUser();

  const { isLoading, data } = useFilterSelector(({ recentConversions }) => recentConversions, {
    currency,
    timeZone,
    locale,
  });

  useEffect(() => {
    if (currentUser.id && !data.length) {
      dispatch(fetchRecentConversions({ currency, timeZone, locale }));
    }
  }, [dispatch, currentUser.id, data.length, currency, timeZone, locale]);

  const path = {
    pathname: '/advertisers/transactions/captured',
    query: {
      endDate: toDate(moment()),
      startDate: toDate(moment().subtract(1, 'month')),
    },
  };

  return (
    <CardFeatured
      title={t('title')}
      hintProps={tHint('dashboard:hints.recentConversions')}
      path={path}
      data={data}
      loading={isLoading}
    >
      <ConversionTable data={data} loading={isLoading} />
    </CardFeatured>
  );
};

export default RecentConversions;
