import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useShallowEqualSelector } from '@hooks';
import { updateAffiliate } from '@actions';
import { camelCase } from 'lodash';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FlexContainer } from '@dotone/react-ui-core';
import {
  affiliateLabels as labels,
  affiliateLabelColorMap as colorMap,
} from '@dotone/react-ui-core/constants';

const AffiliateLabelDropdown = ({ value, affiliate, ...otherProps }) => {
  const { t, tShared } = useTranslate('affiliate', 'table');
  const dispatch = useDispatch();
  const { isLoading } = useShallowEqualSelector(({ affiliateUpdate }) => affiliateUpdate);
  const { id } = affiliate;

  const handleClick = (label) => {
    dispatch(updateAffiliate(id, { label }));
  };

  return (
    <FlexContainer>
      <UncontrolledDropdown className="flex-grow-1" {...otherProps}>
        <DropdownToggle block caret color={colorMap[value]} disabled={isLoading} className="py-0">
          {value ? tShared(`label.${camelCase(value)}`) : t('addLabel')}
        </DropdownToggle>
        <DropdownMenu>
          {Object.values(labels).map((status) => (
            <DropdownItem
              key={status}
              tag="button"
              disabled={status === value}
              onClick={() => handleClick(status)}
            >
              {tShared(`label.${camelCase(status)}`)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </FlexContainer>
  );
};

export default AffiliateLabelDropdown;
