import React from 'react';
import { useTranslate } from '@hooks';
import { toArray } from '@dotone/react-ui-core/utils';
import { FilterBlock, FilterList } from '@dotone/react-ui-core';
import { OfferFilterBlock } from '../filters';

const AffiliationFilterList = ({ filters, ...otherProps }) => {
  const { t } = useTranslate('report', 'filter');
  const { startDate, endDate, offerIds = [] } = filters;

  return (
    <FilterList {...otherProps}>
      <FilterBlock list={false} label={t('startDate')} items={toArray(startDate)} />
      <FilterBlock list={false} label={t('endDate')} items={toArray(endDate)} />
      <OfferFilterBlock ids={offerIds} />
    </FilterList>
  );
};

export default React.memo(AffiliationFilterList);
