import { MissingOrderApi, OrderApi } from '@api';
import { camelCase, chain, find, upperCase } from 'lodash';
import { statDataTypes } from '@constants';
import { orderTypes as actionTypes, missingOrderTypes, statTypes } from './types';

const hydrateStats = (dispatch, state, response) => {
  Object.values(statDataTypes).forEach((dataType) => {
    const stateName = camelCase(`stat-${dataType}-list`);
    const stats = state[stateName].data;
    const stat = find(stats, { order: { id: response.data.id } });

    if (stat) {
      dispatch({
        type: statTypes[`LIST_${upperCase(dataType)}`].HYDRATE,
        payload: {
          data: [
            {
              ...stat,
              order: response.data,
            },
          ],
        },
        method: 'merge',
        keyField: 'transactionId',
      });
    }
  });
};

const hydrateMissingOrder = (dispatch, state, response) => {
  const ids = chain(state.missingOrderList.data)
    .filter({ orderNumber: response.data.orderNumber })
    .map('id')
    .value();

  if (ids.length) {
    MissingOrderApi.getList({ ids })
      .then(({ data }) => {
        dispatch({
          type: missingOrderTypes.LIST.HYDRATE,
          payload: { data },
          method: 'merge',
        });
      })
      .catch(() => {
        // failed fetch missing order
      });
  }
};

export const updateOrder = (id, params) => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  OrderApi.update(id, { order: params })
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });

      hydrateStats(dispatch, state, response);
      hydrateMissingOrder(dispatch, state, response);
    })
    .catch(() => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: 'notify:general.save.error',
      });
    });
};
