import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'react-use';
import { useTranslate } from '@hooks';
import { getTourSteps } from '@data';

const Component = dynamic(() => import('reactour'), {
  ssr: false,
});

const Tour = ({ isOpen, onToggle }) => {
  const { tHtml } = useTranslate('common');
  const router = useRouter();
  const [key, steps] = getTourSteps(router.pathname, tHtml);
  const [value, setValue] = useLocalStorage(`tour-${key}`, 'false');

  const doNothing = () => {};

  const disableBody = () => {
    document.body.classList.add('tour-on');
  };

  const handleClose = () => {
    document.body.classList.remove('tour-on');
    onToggle();
    setValue('true');
  };

  useEffect(() => {
    if (!isOpen && value === 'false') onToggle();
  }, [value, onToggle, isOpen]);

  return (
    !!steps &&
    !!steps.length &&
    isOpen && (
      <Component
        closeWithMask={false}
        disableInteraction
        rounded={4}
        steps={steps}
        isOpen={isOpen}
        onRequestClose={handleClose}
        onClickMask={doNothing}
        onClickHighlighted={doNothing}
        onAfterOpen={disableBody}
      />
    )
  );
};

export default Tour;
