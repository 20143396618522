import React from 'react';
import { useTranslate } from '@hooks';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { FormFilter as Form, MissingOrderStatusSelect } from '@dotone/react-ui-core';

const MissingOrderFilterForm = (props) => {
  const { t } = useTranslate('missingOrder', 'model');

  return (
    <Form {...props}>
      <Row>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('status')}</Label>
            <MissingOrderStatusSelect multi name="statuses" />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default MissingOrderFilterForm;
