import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useLocalizedDataSelector } from '@hooks';
import { fetchCurrencies } from '@actions';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const CurrencyDropdown = ({ nav = true, value, onChange, ...otherProps }) => {
  const { locale } = useTranslate();
  const dispatch = useDispatch();
  const currencies = useLocalizedDataSelector(({ currencyList }) => currencyList);
  const current = currencies.find((currency) => currency.code === value);

  useEffect(() => {
    if (!currencies.length) {
      dispatch(fetchCurrencies(locale));
    }
  }, [dispatch, locale, currencies.length]);

  return (
    !!current && (
      <UncontrolledDropdown nav={nav} {...otherProps}>
        <DropdownToggle nav={nav} caret>
          {current.code} ({current.symbol})
        </DropdownToggle>
        <DropdownMenu right className="pre-scrollable">
          {currencies.map((currency) => (
            <DropdownItem
              key={currency.id}
              disabled={value === currency.code}
              onClick={() => onChange(currency.id)}
            >
              ({currency.code}) {currency.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  );
};

export default React.memo(CurrencyDropdown);
