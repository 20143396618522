import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useLocalizedDataSelector } from '@hooks';
import { fetchCountries } from '@actions';
import { toGroupedCountryOptions } from '@dotone/react-ui-core/utils';
import { Input } from '@dotone/react-ui-core';

const CountrySelect = (props) => {
  const { locale } = useTranslate();
  const dispatch = useDispatch();
  const countries = useLocalizedDataSelector(({ countryList }) => countryList);
  const options = toGroupedCountryOptions(countries);

  useEffect(() => {
    if (!countries.length) {
      dispatch(fetchCountries(locale));
    }
  }, [dispatch, locale, countries.length]);

  return !!countries.length && <Input grouped type="select" options={options} {...props} />;
};

export default CountrySelect;
