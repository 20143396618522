import React from 'react';
import { useTranslate } from '@hooks';
import { sortBy } from 'lodash';
import { Badge } from '@dotone/react-ui-core';
import DataCell from './OfferDataCell';

const OfferAvailableToolDataCell = ({ offer }) => {
  const { t } = useTranslate('offer', 'card');

  const items = sortBy(
    [
      {
        key: 'adLink',
        available: offer.hasAdLink,
      },
      {
        key: 'dataFeed',
        available: offer.hasDataFeed,
      },
      {
        key: 'deepLink',
        available: offer.deeplinkable,
      },
    ],
    (item) => !item.available
  );

  return (
    <DataCell colSpan="4" className="text-left">
      {items.map(({ key, available }) => {
        const check = available ? 'yes' : 'no';

        const hintProps = {
          target: `availableTool-hint-${key}-${offer.id}`,
          title: t(`${key}.hint.${check}.title`),
          content: t(`${key}.hint.${check}.content`),
          placement: 'bottom',
        };

        return (
          <Badge
            key={key}
            color={available ? 'primary' : 'secondary'}
            id={`availableTool-${offer.id}-${key}`}
            hintProps={hintProps}
            className="mr-1 text-rg"
          >
            {t(`${key}.label`)}
          </Badge>
        );
      })}
    </DataCell>
  );
};

export default React.memo(OfferAvailableToolDataCell);
