import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useCurrentUser, useFilterSelector } from '@hooks';
import { fetchExposures } from '@actions';
import { isEmpty } from 'lodash';
import { toNumberWithDelimeter } from '@dotone/react-ui-core/utils';
import { Container, Row, Col } from 'reactstrap';
import { CardFeatured, Button, Modal, Text, Date } from '@dotone/react-ui-core';
import { TextCreativeFormButton } from '../TextCreatives';
import { ImageCreativeFormButton } from '../ImageCreatives';

const defaultModal = {
  open: false,
  title: '',
  content: '',
};

const DataDisplay = ({
  label,
  lastUpdatedAt,
  total,
  dataTypeHint,
  dateFormat = false,
  children,
}) => {
  const { t, tHint } = useTranslate('dashboard', 'exposure');

  return (
    <Col className="text-center d-flex flex-column mb-4">
      <Text
        hintProps={{ tKey: dataTypeHint, ...tHint(`dashboard:hints.${dataTypeHint}`) }}
        className="text-nowrap"
      >
        {label}
      </Text>
      <Text weight="bold" size="lg">
        {toNumberWithDelimeter(total, { round: true })}
      </Text>
      <Text muted size="xs">
        {lastUpdatedAt ? (
          <Date
            fromNow={!dateFormat}
            value={lastUpdatedAt}
            prepend={t(`${dataTypeHint}.lastUpdated`)}
          />
        ) : (
          t('noActivityYet')
        )}
      </Text>
      <div className="pt-2 mt-auto">{children}</div>
    </Col>
  );
};

const Exposure = () => {
  const { t, tHint } = useTranslate('dashboard', 'exposure');
  const dispatch = useDispatch();
  const { currentUser, timeZone } = useCurrentUser();
  const [modal, setModal] = useState(defaultModal);

  const { data, isLoading } = useFilterSelector(({ exposures }) => exposures, {
    timeZone,
  });

  const empty = isEmpty(data);

  const handleModalToggle = (key) => {
    const newModal = key
      ? {
          open: true,
          title: t(`${key}Modal.title`),
          content: t(`${key}Modal.description`),
        }
      : defaultModal;

    setModal(newModal);
  };

  useEffect(() => {
    if (currentUser.id && empty) {
      dispatch(fetchExposures({ timeZone }));
    }
  }, [dispatch, currentUser.id, empty, timeZone]);

  return (
    <CardFeatured
      title={t('title')}
      hintProps={tHint('dashboard:hints.exposures')}
      data={data}
      loading={isLoading}
    >
      <>
        <Container fluid className="custom-overflow-x">
          <Row className="pt-4 px-2 flex-wrap flex-lg-nowrap">
            <DataDisplay
              label={t('activeOffers.title')}
              dataTypeHint="activeOffers"
              dateFormat
              {...data?.activeOffers}
            >
              <Button onClick={() => handleModalToggle('activeOffers')} color="secondary" outline>
                {t('findOut')}
              </Button>
            </DataDisplay>
            <DataDisplay
              label={t('activeLandingPages.title')}
              dataTypeHint="activeLandingPages"
              {...data?.activeLandingPages}
            >
              <Button
                onClick={() => handleModalToggle('activeLandingPages')}
                color="secondary"
                outline
              >
                {t('findOut')}
              </Button>
            </DataDisplay>
            <DataDisplay
              label={t('activeBanners.title')}
              dataTypeHint="activeBanners"
              {...data?.activeBanners}
            >
              <ImageCreativeFormButton
                color="primary"
                outline
                size="rg"
                disabled={isLoading}
                className=""
              >
                {t('addMore')}
              </ImageCreativeFormButton>
            </DataDisplay>
            <DataDisplay
              label={t('activePromoAndDeals.title')}
              dataTypeHint="activePromoAndDeals"
              {...data?.activePromoAndDeals}
            >
              <TextCreativeFormButton
                color="primary"
                outline
                size="rg"
                disabled={isLoading}
                className=""
                variant=""
              >
                {t('addMore')}
              </TextCreativeFormButton>
            </DataDisplay>
            <DataDisplay
              label={t('activeAffiliates.title')}
              dataTypeHint="activeAffiliates"
              {...data?.activeAffiliates}
            >
              <Button
                onClick={() => handleModalToggle('activeAffiliates')}
                color="secondary"
                outline
              >
                {t('findOut')}
              </Button>
            </DataDisplay>
          </Row>
        </Container>
        <Modal
          isOpen={modal.open}
          title={modal.title}
          footerVariant="close"
          footer
          onToggle={() => handleModalToggle(false)}
          size="lg"
        >
          {modal.content}
        </Modal>
      </>
    </CardFeatured>
  );
};

export default Exposure;
