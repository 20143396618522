import React from 'react';
import { useTranslate } from '@hooks';
import { FlexContainer } from '@dotone/react-ui-core';
import { Table } from 'reactstrap';
import Trans from 'next-translate/Trans';

const SinglePointConversionsTab = () => {
  const { t } = useTranslate('uploadInstruction');
  return (
    <>
      <FlexContainer>
        <span className="text-warning">{t('templateFile')}:</span>
        <ul className="d-flex">
          <li>
            <a
              target="_blank"
              href="https://s3.amazonaws.com/direct-vibrantads-com-cdn/uploads/assign-credit-affiliates.csv"
              rel="noreferrer"
            >
              {t('singlePointTable.approvingRejectingConversions')}
            </a>
          </li>
        </ul>
      </FlexContainer>
      <p>{t('singlePointTable.usage')}</p>
      <Table bordered className="table-overview">
        <thead>
          <tr>
            <th scope="col">{t('tableHeaders.columnNames')}</th>
            <th scope="col">{t('tableHeaders.descriptions')}</th>
            <th scope="col">{t('tableHeaders.notes')}</th>
            <th scope="col">{t('tableHeaders.required')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('singlePointTable.rows.id.columnName')}</td>
            <td>{t('singlePointTable.rows.id.description')}</td>
            <td>{t('singlePointTable.rows.id.notes')}</td>
            <td>
              <Trans
                i18nKey="uploadInstruction:required"
                components={[<strong key="required" />]}
              />
            </td>
          </tr>
          <tr>
            <td>{t('singlePointTable.rows.approval.columnName')}</td>
            <td>{t('singlePointTable.rows.approval.description')}</td>
            <td>
              <Trans
                i18nKey="uploadInstruction:singlePointTable.rows.approval.notes"
                components={[<strong key="singlePointTable.rows.approval.notes" />]}
              />
            </td>
            <td>
              <Trans
                i18nKey="uploadInstruction:required"
                components={[<strong key="required" />]}
              />
            </td>
          </tr>
          <tr>
            <td>{t('singlePointTable.rows.capturedAt.columnName')}</td>
            <td>{t('singlePointTable.rows.capturedAt.description')}</td>
            <td>
              <Trans
                i18nKey="uploadInstruction:singlePointTable.rows.capturedAt.notes.note1"
                components={[<strong key="singlePointTable.rows.capturedAt.notes.note1" />]}
              />
              <br />
              <Trans
                i18nKey="uploadInstruction:singlePointTable.rows.capturedAt.notes.note2"
                components={[<strong key="singlePointTable.rows.capturedAt.notes.note2" />]}
              />
            </td>
            <td>{t('optional')}</td>
          </tr>
          <tr>
            <td>{t('singlePointTable.rows.convertedAt.columnName')}</td>
            <td>{t('singlePointTable.rows.convertedAt.description')}</td>
            <td>
              {t('singlePointTable.rows.convertedAt.notes.note1')}
              <br />
              <Trans
                i18nKey="uploadInstruction:singlePointTable.rows.convertedAt.notes.note2"
                components={[<strong key="singlePointTable.rows.convertedAt.notes.note2" />]}
              />
            </td>
            <td>{t('optional')}</td>
          </tr>
          <tr>
            <td>{t('singlePointTable.rows.truePay.columnName')}</td>
            <td>{t('singlePointTable.rows.truePay.description')}</td>
            <td>
              {t('singlePointTable.rows.truePay.notes.note1')}
              <br />
              {t('singlePointTable.rows.truePay.notes.note2')}
              <br />
              {t('singlePointTable.rows.truePay.notes.note3')}
            </td>
            <td>{t('optional')}</td>
          </tr>
          <tr>
            <td>{t('singlePointTable.rows.affiliateId.columnName')}</td>
            <td>{t('singlePointTable.rows.affiliateId.description')}</td>
            <td>
              <Trans
                i18nKey="uploadInstruction:singlePointTable.rows.affiliateId.notes"
                components={[<strong key="singlePointTable.rows.affiliateId.notes" />]}
              />
            </td>
            <td>{t('optional')}</td>
          </tr>
        </tbody>
      </Table>
      <h5>{t('csvExample')}</h5>
      <Table bordered>
        <thead>
          <tr>
            <th>captured_at ({t('optional')})</th>
            <th>converted_at ({t('optional')})</th>
            <th>offer_id</th>
            <th>order_number ({t('optional')})</th>
            <th>order_total</th>
            <th>true_pay</th>
            <th>step_name ({t('optional')})</th>
            <th>approval</th>
            <th>id</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('dateFormat')}</td>
            <td>{t('dateFormat')}</td>
            <td>2345</td>
            <td>LEAD55678</td>
            <td>100</td>
            <td>2</td>
            <td>default/sale</td>
            <td>Pending/Rejected/Approved</td>
            <td>{t('id')}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default SinglePointConversionsTab;
