import API from './api';

export default class ChatMessageApi {
  static create(id, params) {
    return API.post(`/chat_rooms/${id}/chat_messages`, params);
  }

  static getList(id, params) {
    return API.get(`/chat_rooms/${id}/chat_messages`, params);
  }
}
