import React from 'react';
import { useTranslate } from '@hooks';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import {
  FormFilter as Form,
  SubsectionTitle,
  AppRoleSelect,
  FeedTypeSelect,
} from '@dotone/react-ui-core';

const FeedFilterForm = ({ defaultValues, ...otherProps }) => {
  const { t } = useTranslate('feed', 'model');

  return (
    <Form defaultValues={defaultValues} {...otherProps}>
      <Row>
        <Col lg={12}>
          <SubsectionTitle>{t('common:filterBy')}</SubsectionTitle>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('feedType')}</Label>
            <FeedTypeSelect multi name="feedTypes" />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('role')}</Label>
            <AppRoleSelect name="role" />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default FeedFilterForm;
