import { missingOrderStatuses } from '@dotone/react-ui-core/constants';

export const missingOrderStatusSummaries = {
  [missingOrderStatuses.APPROVED]: {
    ORDER_ADDED: 'Order Added',
    ORDER_ADDED_DETAILED: 'Order Added Detailed',
  },
  [missingOrderStatuses.REJECTED]: {
    ORDER_EXPIRED: 'Order Expired',
    COUPON_INVALID: 'Coupon Invalid',
    WITHOUT_COMMISSION: 'Without Commission',
    NOT_DUE: 'Not Due',
    OTHERS: 'Others',
  },
};
