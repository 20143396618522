import { managerTypes as actionTypes } from '@actions';
import { commonState, commonReducer } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default({ collection: true }),
};

export const managerList = (state = defaultState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const currentManager = (state = { ...defaultState, data: {} }, action) =>
  commonReducer(actionTypes.SET_CURRENT, state, action);
