import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useTableState } from '@dotone/react-ui-core/hooks';
import { useCurrentUser, useShallowEqualSelector, useTranslate } from '@hooks';
import { fetchStatList, downloadStats } from '@actions';
import { statApprovalConsideredFinal } from '@dotone/react-ui-core/constants';
import { statDefaultColumns, statDefaultColumnsClick, statDataTypes } from '@constants';
import { camelCase } from 'lodash';
import { CardPage, DownloadDropdown } from '@dotone/react-ui-core';
import { LayoutProtected as Layout } from '@layouts';
import StatTable from './StatTable';
import StatBulkActionDropdown from './StatBulkActionDropdown';

const StatPage = ({ dataType, filterable = true, ...otherProps }) => {
  const { t } = useTranslate('stat', `pages.${camelCase(dataType)}.index`);
  const [downloading, setDownloading] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const { locale, currency } = currentUser;
  const [tableProps, setTableProps] = useTableState({
    defaultColumns:
      dataType === statDataTypes.CLICKS ? statDefaultColumnsClick : statDefaultColumns,
    columnType: camelCase(`stat-${dataType}`),
    searches: { dataType },
    dateable: true,
  });
  const router = useRouter();
  const { filters, sortField, sortOrder, page, perPage, columns, selected } = tableProps;

  const {
    stat: { data, pagination, isLoading },
    downloadStatus,
  } = useShallowEqualSelector(({ statDownload, ...state }) => ({
    stat: state[camelCase(`stat-${dataType}-list`)],
    downloadStatus: statDownload.status,
  }));

  const query = {
    currency,
    locale,
    page,
    perPage,
    sortField,
    sortOrder,
    dataType,
    ...filters,
  };

  const nonSelectable = data
    .filter((stat) => statApprovalConsideredFinal.network.includes(stat.approval))
    .map((stat) => stat.copyStatId);

  const handleDownload = (downloadFormat) => {
    setDownloading(true);
    dispatch(downloadStats({ ...query, columns, downloadFormat }));
  };

  useDeepCompareEffect(() => {
    if (currentUser.id) {
      dispatch(fetchStatList(query));
    }
  }, [dispatch, currentUser.id, query]);

  useEffect(() => {
    if (downloading && downloadStatus === 'success') {
      router.push('/advertisers/downloads');
    }
  }, [router, downloading, downloadStatus]);

  return (
    <Layout title={t('title')} {...otherProps}>
      <CardPage table strict title={t('title')} subtitle={t('subtitle')}>
        <StatTable
          tableProps={tableProps}
          loading={isLoading}
          data={data}
          pagination={pagination}
          onTableChange={setTableProps}
          dataType={dataType}
          selectRowProps={{ nonSelectable, hideNonSelectable: true }}
          filterable={filterable}
          actions={
            <>
              {dataType !== statDataTypes.CLICKS && <StatBulkActionDropdown ids={selected} />}
              <DownloadDropdown
                disabled={!data.length || isLoading}
                buttonProps={{ submitting: downloading }}
                onClick={handleDownload}
              />
            </>
          }
        />
      </CardPage>
    </Layout>
  );
};

export default StatPage;
