import React from 'react';
import { useTranslate } from '@hooks';
import { statApprovals } from '@dotone/react-ui-core/constants';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import { TablePaginated, NextLink } from '@dotone/react-ui-core';

const formatLink = (cell, row) => (
  <NextLink
    href={{
      pathname: '/advertisers/transactions/captured',
      query: {
        stepName: row.step.name,
        offerIds: row.offer.id,
        approvals: statApprovals.PENDING,
        offerVariantIds: row.offerVariant.id,
        startDate: row.startDate,
        endDate: row.endDate,
      },
    }}
  >
    {cell}
  </NextLink>
);

const formatName = (cell) => getNameWithId(cell, 'fullName');

const getColumns = ({ t }) => [
  {
    dataField: 'offer',
    text: t('offer'),
    formatter: formatName,
  },
  {
    dataField: 'offerVariant',
    text: t('offerVariants'),
    formatter: formatName,
  },
  {
    dataField: 'step.name',
    headerAlign: 'center',
    classes: 'text-center',
    text: t('conversionPoints'),
  },
  {
    dataField: 'pastDue',
    classes: 'text-right',
    text: t('pastDue'),
    headerAlign: 'right',
    formatter: formatLink,
  },
  {
    dataField: 'current',
    classes: 'text-right',
    text: t('current'),
    headerAlign: 'right',
    formatter: formatLink,
  },
];

const StatPendingByOfferTable = (props) => {
  const { t } = useTranslate('stat', 'pending');

  const columns = getColumns({ t });

  const tableProps = {
    classes: 'text-sm stylish-table',
    columns,
    responsive: true,
    condensed: true,
    hover: true,
    keyField: 'id',
    remote: true,
    ...props,
  };

  return <TablePaginated {...tableProps} />;
};

export default StatPendingByOfferTable;
