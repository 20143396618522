import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useShallowEqualSelector } from '@hooks';
import { updateCampaign } from '@actions';
import { camelCase } from 'lodash';
import {
  campaignStatuses,
  campaignStatusColorMap as colorMap,
} from '@dotone/react-ui-core/constants';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FlexContainer, ModalConfirm } from '@dotone/react-ui-core';
import {
  PlayCircle as ActiveIcon,
  PauseCircle as PausedIcon,
  XCircle as RejectedIcon,
} from 'react-feather';

const statuses = [campaignStatuses.ACTIVE, campaignStatuses.PAUSED, campaignStatuses.SUSPENDED];

const iconMap = {
  [campaignStatuses.ACTIVE]: <ActiveIcon size={14} className="mr-1" />,
  [campaignStatuses.PAUSED]: <PausedIcon size={14} className="mr-1" />,
  [campaignStatuses.SUSPENDED]: <RejectedIcon size={14} className="mr-1" />,
};

const CampaignStatusDropdown = ({ value, campaign, ...otherProps }) => {
  const { t } = useTranslate('campaign', 'approvalStatus');
  const dispatch = useDispatch();
  const { isLoading } = useShallowEqualSelector(({ campaignUpdate }) => campaignUpdate);
  const { id } = campaign;

  const [approvalStatus, setApprovalStatus] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleClick = (selectedStatus) => {
    setApprovalStatus(selectedStatus);
    setConfirmOpen(true);
  };

  const handleUpdate = () => {
    dispatch(updateCampaign(id, { approvalStatus }));
  };

  return (
    <FlexContainer>
      <UncontrolledDropdown className="flex-grow-1" {...otherProps}>
        <DropdownToggle block caret color={colorMap[value]} disabled={isLoading} className="py-0">
          {t(camelCase(value))}
        </DropdownToggle>
        <DropdownMenu>
          {statuses.map((status) => (
            <DropdownItem
              key={status}
              tag="button"
              disabled={value === status}
              onClick={() => handleClick(status)}
            >
              {iconMap[status]}
              {t(camelCase(status))}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <ModalConfirm
        message={t('campaign:confirmMessages.approvalStatusChange')}
        isOpen={confirmOpen}
        onConfirm={handleUpdate}
        onDismiss={() => setConfirmOpen(false)}
      />
    </FlexContainer>
  );
};

export default CampaignStatusDropdown;
