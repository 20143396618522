import { authTypes as actionTypes } from '@actions';
import { commonState } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default(),
  isAuthenticated: false,
  token: null,
  redirectTo: null,
  deauthenticating: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        ...commonState.loading,
        deauthenticating: false,
      };
    case actionTypes.SUCCESS:
      return {
        ...state,
        ...commonState.success,
        ...action.payload,
        redirectTo: action.redirectTo,
        isAuthenticated: true,
        message: 'loggedIn',
      };
    case actionTypes.FAILURE:
      return {
        ...state,
        ...commonState.error,
        message: action.message,
      };
    case actionTypes.REAUTH:
      return {
        ...state,
        ...commonState.success,
        isAuthenticated: !!action.token,
        token: action.token,
        redirectTo: action.redirectTo,
      };
    case actionTypes.DEAUTH:
      return {
        ...state,
        ...commonState.success,
        isAuthenticated: false,
        token: null,
        message: 'loggedOut',
        deauthenticating: true,
      };
    case actionTypes.INSTAGRAM:
      return {
        ...state,
        ...commonState.success,
        instagram: action.payload,
      };
    case actionTypes.DISMISS_ALERT:
      return {
        ...state,
        ...commonState.dismiss,
      };
    default:
      return state;
  }
};
