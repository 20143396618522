import React from 'react';
import { useTranslate } from '@hooks';
import { conversionTypes } from '@dotone/react-ui-core/constants';
import classnames from 'classnames';
import { FlexContainer, Currency, Percentage, UncontrolledPopover } from '@dotone/react-ui-core';
import OfferDataCell from './OfferDataCell';

const OfferPayout = ({ offerId, payout, ...otherProps }) => {
  const { t } = useTranslate('offer', 'model');
  const isCustom = payout.type === 'limited';
  const target = `payout-${offerId}`;
  const isShared = payout.convType === conversionTypes.CPS;

  return (
    <OfferDataCell
      label={payout.label}
      className={classnames('text-center', { 'text-danger': isCustom })}
      {...otherProps}
    >
      <FlexContainer align="flex-start" justify="center">
        {isCustom && <span>*</span>}
        {isShared ? (
          <Percentage value={payout.value} className="h3" id={target} />
        ) : (
          <Currency truncate value={payout.value} className="h3" id={target} />
        )}
        {isCustom && (
          <UncontrolledPopover target={target} trigger="hover">
            <div className="d-flex">
              <span className="mr-1">{t('default')}:</span>
              {isShared ? (
                <Percentage value={payout.originalValue} />
              ) : (
                <Currency truncate value={payout.originalValue} />
              )}
            </div>
          </UncontrolledPopover>
        )}
      </FlexContainer>
    </OfferDataCell>
  );
};

export default OfferPayout;
