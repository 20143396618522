import { OfferApi } from '@api';
import { offerTypes as actionTypes } from './types';

export const fetchOffers = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  OfferApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const searchOffers = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH.LOADING,
  });

  const searchKey = JSON.stringify(params);

  OfferApi.search(params)
    .then((response) => {
      dispatch({
        type: actionTypes.SEARCH.SUCCESS,
        payload: { ...response, data: { [searchKey]: response.data } },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SEARCH.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
