import React from 'react';
import {
  Spinner,
  MessageEmpty,
  Pagination,
  FilterDropdown,
  FeedItemList,
} from '@dotone/react-ui-core';
import FilterForm from './FeedFilterForm';
import FilterList from './FeedFilterList';

const FeedList = ({
  data,
  loading = true,
  listProps = {},
  pagination,
  onListChange,
  ...otherProps
}) => {
  const handlePaginationChange = (value) => {
    onListChange({ ...listProps, ...value });
  };

  return (
    <div {...otherProps}>
      <FilterDropdown
        disabled={loading}
        filterProps={listProps}
        onChange={onListChange}
        filterRenderer={(filterProps) => <FilterForm {...filterProps} />}
        filterListRenderer={(filters) => <FilterList filters={filters} />}
        className="mb-3"
      />
      {loading && <Spinner />}
      {!loading &&
        (data.length ? (
          <FeedItemList
            items={data}
            className="mb-3"
            itemProps={{ generateLink: listProps.filters.role === 'Advertiser' }}
          />
        ) : (
          <MessageEmpty />
        ))}
      <Pagination pagination={pagination} onChange={handlePaginationChange} />
    </div>
  );
};

export default FeedList;
