import { textCreativeTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true }),
  pagination,
};

const defaultState = {
  ...commonState.default(),
};

export const textCreativeList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const textCreativeCreate = (state = defaultState, action) =>
  commonReducer(actionTypes.CREATE, state, action);

export const textCreativeUpdate = (state = defaultState, action) =>
  commonReducer(actionTypes.UPDATE, state, action);

export const textCreativeBulkUpdate = (state = collectionState, action) =>
  commonReducer(actionTypes.BULK_UPDATE, state, action);
