import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useLocalizedDataSelector } from '@hooks';
import { fetchCategoryGroups } from '@actions';
import { toSelectOptions } from '@dotone/react-ui-core/utils';
import { Input } from '@dotone/react-ui-core';

const CategorySelect = (props) => {
  const { locale } = useTranslate();
  const dispatch = useDispatch();
  const categories = useLocalizedDataSelector(({ categoryGroupList }) => categoryGroupList);
  const options = categories.map((category) => ({
    label: category.name,
    options: toSelectOptions(category.categories, { label: 'name' }),
  }));

  const {
    formProps: { watch },
    name,
    rules: { maxLength },
  } = props;

  const value = watch(name);

  useEffect(() => {
    if (!categories.length) {
      dispatch(fetchCategoryGroups(locale));
    }
  }, [dispatch, locale, categories.length]);

  return (
    !!categories.length && (
      <Input
        grouped
        type="select"
        options={options}
        isOptionDisabled={() => value && maxLength && value.length >= maxLength}
        {...props}
      />
    )
  );
};

export default CategorySelect;
