import React from 'react';
import BullhornOutlineIcon from 'mdi-react/BullhornOutlineIcon';
import GaugeIcon from 'mdi-react/GaugeIcon';
import WrenchOutlineIcon from 'mdi-react/WrenchOutlineIcon';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import { Users as UsersIcon, HelpCircle as HelpIcon, Key as KeyIcon } from 'react-feather';
import SwapHorizontalCircleOutlineIcon from 'mdi-react/SwapHorizontalCircleOutlineIcon';
import BriefcaseOutlineIcon from 'mdi-react/BriefcaseOutlineIcon';

const iconProps = {
  size: 20,
  className: 'mr-1',
};

const menuItems = [
  {
    label: 'dashboard',
    pathname: '/advertisers',
    icon: <GaugeIcon {...iconProps} />,
  },
  {
    label: 'inventories.inventories',
    icon: <BriefcaseOutlineIcon {...iconProps} />,
    children: [
      {
        label: 'inventories.offers',
        pathname: '/advertisers/offers',
      },
      {
        label: 'inventories.imageCreatives',
        pathname: '/advertisers/banners',
      },
      {
        label: 'inventories.textCreatives',
        pathname: '/advertisers/promo-deals',
      },
    ],
  },
  {
    label: 'announcements',
    pathname: '/advertisers/announcements?role=network',
    icon: <BullhornOutlineIcon {...iconProps} />,
  },
  {
    label: 'transactions.transactions',
    icon: <SwapHorizontalCircleOutlineIcon {...iconProps} />,
    checkActive: (currentPathname) => {
      return currentPathname === '/advertisers/transactions/pending/by-offer/[offerId]';
    },
    children: [
      {
        label: 'transactions.clicks',
        pathname: '/advertisers/transactions/clicks',
      },
      {
        label: 'transactions.captured',
        pathname: '/advertisers/transactions/captured',
      },
      {
        label: 'transactions.published',
        pathname: '/advertisers/transactions/published',
      },
      {
        label: 'transactions.confirmed',
        pathname: '/advertisers/transactions/confirmed',
      },
      {
        label: 'transactions.pending',
        pathname: '/advertisers/transactions/pending',
        checkActive: (currentPathname) =>
          currentPathname === '/advertisers/transactions/pending/by-offer/[offerId]',
      },
      {
        label: 'transactions.orderInquiry',
        pathname: '/advertisers/order-inquiries',
      },
    ],
  },
  {
    label: 'reports.reports',
    icon: <ChartLineIcon {...iconProps} />,
    children: [
      {
        label: 'reports.offerPerformance',
        pathname: '/advertisers/reports/offer-stats',
      },
      {
        label: 'reports.affiliatePerformance',
        pathname: '/advertisers/reports/affiliate-stats',
      },
      {
        label: 'reports.imageCreativePerformance',
        pathname: '/advertisers/reports/banner-stats',
      },
      {
        label: 'reports.textCreativePerformance',
        pathname: '/advertisers/reports/promo-deal-stats',
      },
      {
        label: 'reports.conversion',
        pathname: '/advertisers/reports/conversion-stats',
      },
      {
        label: 'reports.finalizedConversion',
        pathname: '/advertisers/reports/final-stats',
      },
      {
        label: 'reports.affiliation',
        pathname: '/advertisers/reports/affiliation',
      },
    ],
  },
  {
    label: 'affiliates.partnerships',
    icon: <UsersIcon {...iconProps} />,
    children: [
      {
        label: 'affiliates.affiliates',
        pathname: '/advertisers/affiliates',
      },
      {
        label: 'affiliates.campaigns',
        pathname: '/advertisers/campaigns',
      },
    ],
  },
  {
    label: 'data.managements',
    icon: <WrenchOutlineIcon {...iconProps} />,
    children: [
      {
        label: 'data.downloads',
        pathname: '/advertisers/downloads',
      },
      {
        label: 'data.uploads',
        pathname: '/advertisers/uploads',
      },
    ],
  },
  {
    label: 'apiKeys',
    pathname: '/advertisers/api-keys',
    icon: <KeyIcon {...iconProps} />,
  },
  {
    label: 'faq',
    pathname: '/advertisers/faqs',
    icon: <HelpIcon {...iconProps} />,
  },
];

export default (user) => {
  return menuItems.map((menu, index) => {
    if (index === 1 && user.hasProductApi) {
      return {
        ...menu,
        children: [
          ...menu.children,
          {
            label: 'inventories.products',
            pathname: '/advertisers/products',
          },
        ],
      };
    }

    return menu;
  });
};
