import React, { useState, useEffect } from 'react';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { Edit as EditIcon } from 'react-feather';
import { ModalWithInitialButton as Modal } from '@dotone/react-ui-core';
import Form from './UserFormPassword';

const UserModalPassword = (props) => {
  const { t } = useTranslate('password', 'edit');
  const [isOpen, setOpen] = useState(false);
  const { status } = useShallowEqualSelector(({ passwordUpdate }) => passwordUpdate);

  useEffect(() => {
    if (status === 'success') {
      setOpen(false);
    }
  }, [status]);

  return (
    <Modal
      title={t('title')}
      size="md"
      isOpen={isOpen}
      onToggle={setOpen}
      footer={false}
      buttonProps={{
        color: 'link',
        children: <EditIcon size={18} />,
      }}
      {...props}
    >
      <Form className="mx-auto" />
    </Modal>
  );
};

export default React.memo(UserModalPassword);
