import React from 'react';
import { useCurrentUser, useTranslate } from '@hooks';
import { Table } from '@dotone/react-ui-core';
import { formatCurrency } from '../formatters';

const getColumns = ({ t, currency }) => [
  {
    dataField: 'affiliateId',
    text: t('affiliate'),
  },
  {
    dataField: 'clicks',
    text: t('totalClicks'),
    classes: 'text-center',
    headerClasses: 'text-center',
  },
  {
    dataField: 'totalOrders',
    text: t('totalOrders'),
    classes: 'text-center',
    headerClasses: 'text-center',
  },
  {
    dataField: 'totalRevenue',
    text: t('totalRevenue'),
    formatter: formatCurrency(currency),
    headerClasses: 'text-right',
    classes: 'text-right',
  },
];

const PublisherTable = (props) => {
  const { t } = useTranslate('dashboard', 'topPublishers');
  const { currency } = useCurrentUser();

  const columns = getColumns({ t, currency });

  return (
    <Table
      responsive
      remote
      hover
      classes="stylish-table mb-0"
      keyField="affiliateId"
      columns={columns}
      {...props}
    />
  );
};

export default PublisherTable;
