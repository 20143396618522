import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useFilterSelector, useTranslate } from '@hooks';
import { createAffiliateLog } from '@actions';
import { Modal, Input, AffiliateLogList, addToList } from '@dotone/react-ui-core';
import { Form, FormGroup } from 'reactstrap';

const AffiliateLogFormModal = ({ defaultValues = {}, items: givenItems, ...otherProps }) => {
  const { t } = useTranslate('common');
  const dispatch = useDispatch();
  const [items, setItems] = useState(givenItems);

  const { ownerType, ownerId } = defaultValues;
  const { handleSubmit, connectForm, reset } = useForm({ defaultValues });

  const { data, status, isLoading } = useFilterSelector(
    ({ affiliateLogCreate }) => affiliateLogCreate,
    { ownerType, ownerId }
  );

  const handleSubmitForm = (values) => {
    dispatch(createAffiliateLog(values));
  };

  useDeepCompareEffect(() => {
    if (status === 'success' && data.id) {
      setItems((values) => addToList(values, data, true));
      reset();
    }
  }, [status, data]);

  return (
    <Modal
      title={t('notes')}
      footer
      footerVariant="submit"
      submitting={isLoading}
      onSubmit={handleSubmit(handleSubmitForm)}
      size="lg"
      {...otherProps}
    >
      <Form>
        <div className="form-inner">
          <AffiliateLogList items={items} />
        </div>
        {connectForm(
          <FormGroup>
            <Input type="textarea" name="notes" rows={5} rules={{ required: true }} />
          </FormGroup>
        )}
      </Form>
    </Modal>
  );
};

export default AffiliateLogFormModal;
