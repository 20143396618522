import React from 'react';
import { useTranslate } from '@hooks';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import {
  FormFilter as Form,
  SubsectionTitle,
  AffiliateStatusSelect,
  AffiliateLabelSelect,
  AffiliateSourceSelect,
  GenderSelect,
} from '@dotone/react-ui-core';
import { MediaCategorySelect } from '../forms';

const AffiliateFilterForm = (props) => {
  const { t } = useTranslate('affiliate', 'table');

  return (
    <Form {...props}>
      <Row>
        <Col lg={12}>
          <SubsectionTitle>{t('common:filterBy')}</SubsectionTitle>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('status')}</Label>
            <AffiliateStatusSelect multi name="statuses" />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('label')}</Label>
            <AffiliateLabelSelect multi name="labels" />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('affiliateType')}</Label>
            <MediaCategorySelect multi name="mediaCategoryIds" />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('source')}</Label>
            <AffiliateSourceSelect multi name="sources" />
          </FormGroup>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <FormGroup>
            <Label>{t('gender')}</Label>
            <GenderSelect multi name="genders" />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default AffiliateFilterForm;
