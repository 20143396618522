import { createSelector } from 'reselect';
import { isArray, isPlainObject, omit, pick } from 'lodash';
import { where, isObjectShallowMatch } from '@dotone/react-ui-core/utils';
import useShallowEqualSelector from './useShallowEqualSelector';

/*

Checks whether data in current state is the data you want.
If it's not the data you want, returns empty data.

Short version of:
  const { data } = useShallowEqualSelector(({ offerCurrent }) => {
    const { data } = offerCurrent;

    if (
      String(data.id) === String(query.id) &&
      offerCurrent.locale === locale &&
      offerCurrent.currency === currency &&
      offerCurrent.timeZone === timeZone
    ) {
      return offerCurrent;
    }

    return { ...offerCurrent, data: {} };
  });

is:
  const { data } = useFilterSelector(({ offerCurrent }) => offerCurrent, {
    id: query.id,
    locale,
    currency,
    timeZone,
  });

NOTE: Does comparison on only simple filter params, ex:  { offerId: 1, id: 2 }
DOT NOT: pass nested filters, ex: { offerVariant: { id: 1 }}

*/

const stateFields = ['locale', 'currency', 'timeZone', 'ids', 'searchKey', 'stateKey'];

export default function useFilterSelector(selector, filters = {}) {
  const filterSelector = createSelector(selector, (state) => {
    const { data } = state;
    let newData;

    const dataFilters = omit(filters, stateFields);
    const stateFilters = pick(filters, stateFields);
    const stateMatched = isObjectShallowMatch(state, stateFilters);

    if (isArray(data)) {
      if (stateMatched) {
        newData = where(data, dataFilters);
      } else {
        newData = [];
      }
    } else if (isPlainObject(data)) {
      newData = stateMatched && isObjectShallowMatch(data, dataFilters) ? data : {};
    }

    return { ...state, data: newData || data };
  });

  return useShallowEqualSelector(filterSelector);
}
