import { AppConfigApi } from '@api';
import { appConfigTypes } from './types';

export const fetchAppConfig = (params) => (dispatch) => {
  dispatch({
    type: appConfigTypes.LOADING,
  });

  AppConfigApi.get(params)
    .then((response) => {
      dispatch({
        type: appConfigTypes.SUCCESS,
        payload: { ...response, isEmpty: false },
      });
    })
    .catch(() => {
      dispatch({
        type: appConfigTypes.SUCCESS,
        payload: { data: {}, isEmpty: true },
      });
    });
};
