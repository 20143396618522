import { easyStoreSetupTypes as actionTypes } from '@actions';
import { commonState, commonReducer } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default(),
};

export const easyStoreSetupCurrent = (state = defaultState, action) =>
  commonReducer(actionTypes.CURRENT, state, action);

export const easyStoreSetupCreate = (state = defaultState, action) =>
  commonReducer(actionTypes.CREATE, state, action);
