import { imageCreativeTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true }),
  pagination,
};

const defaultState = {
  ...commonState.default(),
};

export const imageCreativeList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const imageCreativeCreate = (state = defaultState, action) =>
  commonReducer(actionTypes.CREATE, state, action);

export const imageCreativeUpdate = (state = defaultState, action) =>
  commonReducer(actionTypes.UPDATE, state, action);

export const imageCreativeBulkUpdate = (state = collectionState, action) =>
  commonReducer(actionTypes.BULK_UPDATE, state, action);
