import { uploadTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default({ collection: true }),
  pagination,
};

export const uploadList = (state = defaultState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const uploadCreate = (state = defaultState, action) =>
  commonReducer(actionTypes.CREATE, state, action);
