import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useFilterSelector } from '@hooks';
import { fetchSiteInfoImpressions } from '@actions';
import { Modal, SiteInfoItem } from '@dotone/react-ui-core';

const AffiliateSiteInfoModal = ({ siteInfo, isOpen, onCancel }) => {
  const { t } = useTranslate('affiliate', 'table');
  const dispatch = useDispatch();

  const { data } = useFilterSelector(({ siteInfoImpressions }) => siteInfoImpressions, {
    stateKey: siteInfo?.id,
  });

  useEffect(() => {
    if (isOpen && siteInfo.impressionAvailable) {
      dispatch(fetchSiteInfoImpressions(siteInfo.id));
    }
  }, [isOpen, siteInfo, dispatch]);

  return (
    <Modal
      title={t('siteInfo')}
      footer
      footerVariant="close"
      isOpen={isOpen}
      onToggle={onCancel}
      size="lg"
    >
      <SiteInfoItem
        siteInfo={{
          ...siteInfo,
          impressions: siteInfo?.impressionAvailable ? data || [] : null,
        }}
      />
    </Modal>
  );
};

export default AffiliateSiteInfoModal;
