import { CategoryGroupApi } from '@api';
import { categoryGroupTypes as actionTypes } from './types';

export const fetchCategoryGroups = (locale) => (dispatch) => {
  dispatch({
    type: actionTypes.LOADING,
  });

  CategoryGroupApi.getList({ locale, categories: true })
    .then((response) => {
      dispatch({
        type: actionTypes.SUCCESS,
        payload: { [locale]: response },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.FAILURE,
        message: `notify:general.errors.${error?.status || '500'}`,
      });
    });
};
