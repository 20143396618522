import React from 'react';
import { useTranslate } from '@hooks';
import { toArray } from '@dotone/react-ui-core/utils';
import { FilterBlock, FilterList } from '@dotone/react-ui-core';
import { OfferFilterBlock } from '../filters';

const TextCreativeFilterList = ({ filters, ...otherProps }) => {
  const { t, tShared } = useTranslate('textCreative', 'model');
  const { offerIds = [], statuses } = filters;

  return (
    <FilterList {...otherProps}>
      <OfferFilterBlock ids={offerIds} />
      <FilterBlock
        label={t('status')}
        items={toArray(statuses)}
        listProps={{ field: false, t: (key) => tShared(`::creative:status.${key}`) }}
      />
    </FilterList>
  );
};

export default React.memo(TextCreativeFilterList);
