import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useTranslate, useFilterSelector } from '@hooks';
import { fetchAccountOverview } from '@actions';
import { isEmpty } from 'lodash';
import { Container, Row, Col } from 'reactstrap';
import { ChevronUp, ChevronDown } from 'react-feather';
import { CardFeatured, ColumnChart, FlexContainer, Text } from '@dotone/react-ui-core';

const DataDisplay = ({ percentage = {}, thisWeek = [], label }) => {
  const Icon = percentage.direction === 'up' ? ChevronUp : ChevronDown;
  const color = percentage.direction === 'up' ? 'text-success' : 'text-danger';

  return (
    <Col className="text-center">
      <FlexContainer column align="center" justify="center">
        <Text className="text-nowrap">{label}</Text>
        <ColumnChart
          data={Object.values(thisWeek)}
          chart={{
            type: 'column',
            backgroundColor: 'rgba(0,0,0,0)',
            height: 90,
            width: 160,
          }}
        />
        <h4 className={`font-medium mb-0 ${color}`}>
          {<Icon className="mr-1" />}
          {percentage.value}
        </h4>
      </FlexContainer>
    </Col>
  );
};

const AccountOverview = () => {
  const { t, tHint } = useTranslate('dashboard', 'accountOverview');
  const dispatch = useDispatch();
  const { currentUser, timeZone } = useCurrentUser();
  const { data, isLoading } = useFilterSelector(({ accountOverview }) => accountOverview, {
    timeZone,
  });
  const emtpy = isEmpty(data);
  const { uniqueOfferView, offerApplication, offerClicks, totalOrders } = data;

  useEffect(() => {
    if (currentUser.id && emtpy) {
      dispatch(fetchAccountOverview({ timeZone }));
    }
  }, [dispatch, currentUser.id, emtpy, timeZone]);

  return (
    <CardFeatured
      title={t('title')}
      hintProps={tHint('dashboard:hints.accountOverview')}
      data={data}
      loading={isLoading}
    >
      <Container fluid>
        <Row className="py-3">
          <DataDisplay label={t('uniqueOfferView')} {...uniqueOfferView} />
          <DataDisplay label={t('offerApplication')} {...offerApplication} />
          <DataDisplay label={t('offerClicks')} {...offerClicks} />
          <DataDisplay label={t('totalOrders')} {...totalOrders} />
        </Row>
      </Container>
    </CardFeatured>
  );
};

export default AccountOverview;
