import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DefaultToastContainer, useToasts } from 'react-toast-notifications';
import { useTranslate } from '@hooks';
import { chain, isPlainObject, camelCase, snakeCase } from 'lodash';

const collectAlerts = (data) =>
  chain(data)
    .map(
      (item, key) =>
        isPlainObject(item) &&
        item.status &&
        item.message && {
          stateKey: key,
          message: item.message,
          appearance: item.status,
        }
    )
    .compact()
    .value();

const getTranslationKey = (alert) => {
  const { stateKey, appearance, message } = alert;
  if (message.indexOf(':') === -1) {
    return `notify:${stateKey}.${appearance}.${camelCase(message)}`;
  }
  return message;
};

const NotificationToast = (props) => {
  const alerts = useSelector(collectAlerts);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  useEffect(() => {
    alerts.forEach((alert) => {
      const { stateKey, appearance } = alert;
      addToast(t(getTranslationKey(alert)), {
        appearance,
      });
      dispatch({
        type: `${snakeCase(stateKey).toUpperCase()}_DISMISS_ALERT`,
      });
    });
  }, [alerts, addToast, dispatch, t]);

  return <DefaultToastContainer {...props} style={{ zIndex: 9999 }} />;
};

export default NotificationToast;
