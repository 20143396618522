import API from './api';

export default class DashboardApi {
  static accountOverview(params) {
    return API.get('advertisers/dashboards/account_overview', params);
  }

  static performanceStats(params) {
    return API.get('advertisers/dashboards/performance_stat', params);
  }

  static exposures(params) {
    return API.get('advertisers/dashboards/exposure', params);
  }

  static commissionBalances(params) {
    return API.get('advertisers/dashboards/commission_balance', params);
  }

  static recentConversions(params) {
    return API.get('advertisers/affiliate_stats/captured/recent', params);
  }

  static recentFeeds(params) {
    return API.get('feeds/recent', params);
  }

  static topPublishers(params) {
    return API.get('advertisers/dashboards/publisher', params);
  }

  static totalVisitors(params) {
    return API.get('advertisers/dashboards/visitor', params);
  }

  static monthlyStats(params) {
    return API.get('advertisers/dashboards/total_order', params);
  }
}
