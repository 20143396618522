import React from 'react';
import classnames from 'classnames';
import { NextLink, BreadcrumbSet as BsBreadcrumbSet } from '@dotone/react-ui-core';
import { Logo } from './layouts';

export const NavLink = ({ className, ...otherProps }) => (
  <NextLink className={classnames('nav-link', className)} {...otherProps} />
);

export const NavbarBrand = ({ className, logoProps, children, ...otherProps }) => (
  <NextLink className={classnames('navbar-brand', className)} {...otherProps}>
    {children || <Logo {...logoProps} />}
  </NextLink>
);

export const BreadcrumbSet = (props) => <BsBreadcrumbSet homeLink="/advertisers" {...props} />;
