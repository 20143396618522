import { DashboardApi } from '@api';
import { dashboardTypes as actionTypes } from './types';

export const fetchAccountOverview = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.ACCOUNT_OVERVIEW.LOADING,
  });

  DashboardApi.accountOverview(params)
    .then((response) => {
      dispatch({
        type: actionTypes.ACCOUNT_OVERVIEW.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.ACCOUNT_OVERVIEW.FAILURE,
        message: error.statusText,
      });
    });
};

export const fetchPerformanceStats = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.PERFORMANCE_STATS.LOADING,
  });

  DashboardApi.performanceStats(params)
    .then((response) => {
      dispatch({
        type: actionTypes.PERFORMANCE_STATS.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.PERFORMANCE_STATS.FAILURE,
        message: error.statusText,
      });
    });
};

export const fetchExposures = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.EXPOSURES.LOADING,
  });

  DashboardApi.exposures(params)
    .then((response) => {
      dispatch({
        type: actionTypes.EXPOSURES.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.EXPOSURES.FAILURE,
        message: error.statusText,
      });
    });
};

export const fetchCommissionBalances = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.COMMISSION_BALANCES.LOADING,
  });

  DashboardApi.commissionBalances(params)
    .then((response) => {
      dispatch({
        type: actionTypes.COMMISSION_BALANCES.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.COMMISSION_BALANCES.FAILURE,
        message: error.statusText,
      });
    });
};

export const fetchRecentConversions = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.RECENT_CONVERSIONS.LOADING,
  });

  DashboardApi.recentConversions(params)
    .then((response) => {
      dispatch({
        type: actionTypes.RECENT_CONVERSIONS.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.RECENT_CONVERSIONS.FAILURE,
        message: error.statusText,
      });
    });
};

export const fetchRecentFeeds = (params) => (dispatch) => {
  const type = `RECENT_FEEDS_${params.role.toUpperCase()}`;

  dispatch({
    type: actionTypes[type].LOADING,
  });

  DashboardApi.recentFeeds(params)
    .then((response) => {
      dispatch({
        type: actionTypes[type].SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes[type].FAILURE,
        message: error.statusText,
      });
    });
};

export const fetchTopPublishers = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.TOP_PUBLISHERS.LOADING,
  });

  DashboardApi.topPublishers(params)
    .then((response) => {
      dispatch({
        type: actionTypes.TOP_PUBLISHERS.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.TOP_PUBLISHERS.FAILURE,
        message: error.statusText,
      });
    });
};

export const fetchTotalVisitors = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.TOTAL_VISITORS.LOADING,
  });

  DashboardApi.totalVisitors(params)
    .then((response) => {
      dispatch({
        type: actionTypes.TOTAL_VISITORS.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.TOTAL_VISITORS.FAILURE,
        message: error.statusText,
      });
    });
};

export const fetchMonthlyStats = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.MONTHLY_STATS.LOADING,
  });

  DashboardApi.monthlyStats(params)
    .then((response) => {
      dispatch({
        type: actionTypes.MONTHLY_STATS.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.MONTHLY_STATS.FAILURE,
        message: error.statusText,
      });
    });
};
