import API from './api';

const requestOptions = { dateTimeParams: ['activeDateStart', 'activeDateEnd', 'publishedAt'] };

export default class TextCreativeApi {
  static getList(params) {
    return API.get('/advertisers/text_creatives', params);
  }

  static create(params) {
    return API.post('/advertisers/text_creatives', params, {}, requestOptions);
  }

  static update(id, params) {
    return API.put(`/advertisers/text_creatives/${id}`, params, {}, requestOptions);
  }

  static bulkUpdate(params) {
    return API.put('/advertisers/text_creatives/update_bulk', params, {}, requestOptions);
  }
}
