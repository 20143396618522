import React from 'react';
import { Modal, Input, CardStackable } from '@dotone/react-ui-core';
import { useTranslate } from '@hooks';

const TextCreativeRejectReasonModal = ({ textCreative, isOpen, handleToggle }) => {
  const { t } = useTranslate('textCreative', 'model');

  const handleClick = (e) => {
    e.target.select();
  };

  return (
    <Modal
      title={t('statusReason')}
      size="lg"
      footer={false}
      scrollable={false}
      isOpen={isOpen}
      onToggle={handleToggle}
    >
      {textCreative && (
        <CardStackable title={t('statusReason')}>
          <Input
            type="textarea"
            readOnly
            rows={3}
            value={textCreative.statusReason}
            onClick={handleClick}
          />
        </CardStackable>
      )}
    </Modal>
  );
};

export default TextCreativeRejectReasonModal;
