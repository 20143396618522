
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useShallowEqualSelector } from '@hooks';
import { deauthenticate } from '@actions';
import { envVars, isProduction, awsConfig } from '@constants';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { DotOneProvider, SessionTimeout } from '@dotone/react-ui-core';
import { NotificationToast } from '@components';
import wrapper from '../store';

import '@styles/globals.scss';

const CustomToast = (props) => <NotificationToast {...props} />;

function MyApp(props) {
  const dispatch = useDispatch();
  const { timeZone, currency } = useCurrentUser();
  const [loading, setLoading] = useState(false);

  const { Component, pageProps, router } = props;
  const { _sentryBaggage, _sentryTraceData, ...restPageProps } = pageProps;
  const sanitizedPageProps = isProduction ? pageProps : restPageProps;

  const { locale } = router;

  const { isAuthenticated } = useShallowEqualSelector(({ auth }) => auth);

  const logout = () => {
    dispatch(deauthenticate());
  };

  useEffect(() => {
    const handleStart = () => {
      setLoading(true);
    };

    const handleComplete = () => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);

  return (
    <DotOneProvider
      locale={locale}
      timeZone={timeZone}
      currency={currency}
      theme="network"
      role="network"
      awsConfig={awsConfig}
      chatConfig={{ baseUrl: envVars.actionCableUrl }}
      useToasts={useToasts}
    >
      <ToastProvider
        placement="top-center"
        autoDismiss={true}
        components={{ ToastContainer: CustomToast }}
        transitionDuration={250}
      >
        {isProduction && <SessionTimeout onLogout={logout} isAuthenticated={isAuthenticated} />}
        <Component {...sanitizedPageProps} loading={loading} />
      </ToastProvider>
    </DotOneProvider>
  );
}

const __Page_Next_Translate__ = wrapper.withRedux(MyApp);


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  