import { reportTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true }),
  pagination,
};

const defaultState = {
  ...commonState.default(),
};

export const statSummaryList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST_STAT_SUMMARY, state, action);

export const affiliationList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST_AFFILIATION, state, action);

export const statSummaryDownload = (state = defaultState, action) =>
  commonReducer(actionTypes.DOWNLOAD_STAT_SUMMARY, state, action);
