import React, { useState } from 'react';
import { useTranslate } from '@hooks';
import { ModalWithInitialButton as Modal } from '@dotone/react-ui-core';
import Form from './ImageCreativeForm';

const ImageCreativeFormButton = ({ imageCreative = {}, formProps = {}, ...otherProps }) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslate('imageCreative', 'actions');

  return (
    <Modal
      dense
      size="xlg"
      title={imageCreative.id ? t('edit') : t('add')}
      buttonProps={{
        className: 'btn-rounded',
        color: 'primary',
        size: 'sm',
        ...otherProps,
      }}
      scrollable={false}
      footer={false}
      isOpen={isOpen}
      onToggle={setOpen}
      form={
        isOpen ? (
          <Form imageCreative={imageCreative} onCancel={() => setOpen(false)} {...formProps} />
        ) : null
      }
    />
  );
};

export default ImageCreativeFormButton;
