import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useShallowEqualSelector } from '@hooks';
import { fetchTotalVisitors } from '@actions';
import { isEmpty } from 'lodash';
import { Row, Col } from 'reactstrap';
import ClicksByCountry from './ClicksByCountry';
import ClicksByDevice from './ClicksByDevice';

const TotalVisitors = () => {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();

  const { data, isLoading } = useShallowEqualSelector(({ totalVisitors }) => totalVisitors);
  const empty = isEmpty(data);

  useEffect(() => {
    if (currentUser.id && empty) {
      dispatch(fetchTotalVisitors());
    }
  }, [dispatch, currentUser.id, empty]);

  return (
    <Row>
      <Col data-tour="country-clicks">
        <ClicksByCountry totalVisitors={data?.totalVisitors} isLoading={isLoading} />
      </Col>
      <Col data-tour="device-clicks">
        <ClicksByDevice ourVisitors={data?.ourVisitors} isLoading={isLoading} />
      </Col>
    </Row>
  );
};

export default TotalVisitors;
