import { affiliateTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true }),
  pagination,
};

export const affiliateList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const affiliateUpdate = (state = collectionState, action) =>
  commonReducer(actionTypes.UPDATE, state, action);

export const affiliateRecentList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST_RECENT, state, action);

export const affiliateSearch = (state = collectionState, action) =>
  commonReducer(actionTypes.SEARCH, state, action);
