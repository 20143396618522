import { statTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true }),
  pagination,
};
const defaultState = {
  ...commonState.default(),
};

export const statClicksList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST_CLICKS, state, action);

export const statCapturedList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST_CAPTURED, state, action);

export const statPublishedList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST_PUBLISHED, state, action);

export const statConvertedList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST_CONVERTED, state, action);

export const statPendingList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST_PENDING, state, action);

export const statPendingByOfferList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST_PENDING_BY_OFFER, state, action);

export const statSearch = (state = collectionState, action) =>
  commonReducer(actionTypes.SEARCH, state, action);

export const statDownload = (state = defaultState, action) =>
  commonReducer(actionTypes.DOWNLOAD, state, action);

export const statBulkUpdate = (state = collectionState, action) =>
  commonReducer(actionTypes.BULK_UPDATE, state, action);
