import React from 'react';
import { useEffectOnce } from 'react-use';
import { useDispatch } from 'react-redux';
import { useTranslate, useShallowEqualSelector } from '@hooks';
import { fetchManagers, setCurrentManager, fetchAppConfig } from '@actions';
import { menuItems as useMenuItems } from '@data';
import {
  Sidebar as BsSidebar,
  SidebarNav,
  SidebarProfile,
  SidebarMenu,
  BasicPopover,
} from '@dotone/react-ui-core';
import { ManagerDropdown, AccountDropdown } from '@components';

const Sidebar = ({ currentPath, currentUser, ...otherProps }) => {
  const { t } = useTranslate('common', 'sidebar');
  const dispatch = useDispatch();
  const {
    data: managers,
    isLoading,
    status,
  } = useShallowEqualSelector(({ managerList }) => managerList);
  const { data, appConfig, isEmpty } = useShallowEqualSelector(
    ({ currentManager, appConfig: config }) => ({
      ...currentManager,
      appConfig: config.data,
      isEmpty: config.isEmpty,
    })
  );
  const manager = data.id ? data : managers[0] || currentUser;

  const menuItems = useMenuItems(currentUser);

  const handleManagerChange = (value) => {
    dispatch(setCurrentManager(value));
  };

  useEffectOnce(() => {
    if (managers.length === 0 && status !== 'success') {
      dispatch(fetchManagers());
    }
  });

  useEffectOnce(() => {
    if (!isEmpty) {
      dispatch(fetchAppConfig());
    }
  });

  return (
    <BsSidebar {...otherProps}>
      <SidebarNav>
        {!isLoading && (
          <SidebarProfile imgSrc={manager.avatarCdnUrl} bgSrc={appConfig.profileBgUrl}>
            {managers.length ? (
              <>
                <ManagerDropdown
                  managers={managers}
                  current={manager}
                  onChange={handleManagerChange}
                />
                <BasicPopover
                  t={t}
                  tKey="common:navbar.hints.manager"
                  target="profile-img"
                  placement="bottom"
                />
              </>
            ) : (
              <AccountDropdown currentUser={currentUser} />
            )}
          </SidebarProfile>
        )}
        <SidebarMenu items={menuItems} formatLabel={t} currentPath={currentPath} />
      </SidebarNav>
    </BsSidebar>
  );
};

export default Sidebar;
