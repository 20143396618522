import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslate, useCurrentUser, useShallowEqualSelector } from '@hooks';
import { createUpload } from '@actions';
import Trans from 'next-translate/Trans';
import { Row, Col } from 'reactstrap';
import { Label, FileUploader, Spinner, Alert } from '@dotone/react-ui-core';

const UploadCSVTab = ({ setActiveTab }) => {
  const { t } = useTranslate('uploadInstruction');
  const router = useRouter();
  const dispatch = useDispatch();
  const [creating, setCreating] = useState(false);
  const { currentUser } = useCurrentUser();

  const { isLoading, status } = useShallowEqualSelector(({ uploadCreate }) => uploadCreate);

  const handleUploadSucess = ({ cdnUrl }) => {
    if (cdnUrl) {
      setCreating(true);
      dispatch(
        createUpload({
          cdnUrl,
          descriptions: 'Upload conversions from CSV',
        })
      );
    }
  };

  useEffect(() => {
    if (creating && status === 'success') {
      setCreating(false);
      router.push(`/advertisers/uploads`);
    }
  }, [status, creating, router]);

  if (!currentUser.id || isLoading) {
    return <Spinner />;
  }

  return (
    <Row>
      <Col sm={12}>
        <Alert variant="warning">
          <Trans
            i18nKey="uploadInstruction:instructions"
            components={[
              <a onClick={() => setActiveTab(2)} key="order" className="text-underline" />,
              <a onClick={() => setActiveTab(3)} key="lead" className="text-underline" />,
            ]}
          />
        </Alert>
      </Col>
      <Col sm={12} className="mb-2">
        <Label>{t('uploadCsv')}</Label>
      </Col>
      <Col sm={12}>
        <FileUploader path={`upload/network/${currentUser.id}`} onSuccess={handleUploadSucess} />
      </Col>
    </Row>
  );
};

export default UploadCSVTab;
