import { passwordTypes as actionTypes } from '@actions';
import { commonState, commonReducer } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default(),
};

export const passwordReset = (state = defaultState, action) =>
  commonReducer(actionTypes.RESET, state, action);

export const passwordCreate = (state = defaultState, action) =>
  commonReducer(actionTypes.CREATE, state, action);

export const passwordUpdate = (state = defaultState, action) =>
  commonReducer(actionTypes.UPDATE, state, action);
