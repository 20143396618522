import React, { useState } from 'react';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';
import { useDispatch } from 'react-redux';
import { useSearchSelector } from '@hooks';
import { searchAffiliates } from '@actions';
import { AffiliateApi } from '@api';
import { flatten, compact } from 'lodash';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import { AsyncSelect } from '@dotone/react-ui-core';

const AffiliateSearchInput = ({ name, formProps, ...otherProps }) => {
  const dispatch = useDispatch();
  const { getValues } = formProps;
  const ids = compact(flatten([getValues(name)]));
  const { data: defaultValue, status } = useSearchSelector(
    ({ affiliateSearch }) => affiliateSearch,
    {
      ids,
    }
  );
  const [options, setOptions] = useState([]);

  const formatData = (data) =>
    data.map((item) => ({ value: item.id, label: item.name ? getNameWithId(item) : item.id }));

  const handleLoad = (search) => AffiliateApi.search({ search, limit: 20 });

  useDeepCompareEffect(() => {
    if (ids.length && !defaultValue.length) {
      dispatch(searchAffiliates({ ids }));
    }
  }, [dispatch, ids, defaultValue.length]);

  useEffectOnce(() => {
    if (!ids.length) {
      handleLoad()
        .then((response) => {
          setOptions(formatData(response.data));
        })
        .catch(() => {});
    }
  });

  return (
    (status === 'success' || !ids.length) && (
      <AsyncSelect
        simple
        name={name}
        defaultValue={defaultValue}
        options={options}
        formatter={formatData}
        onOptionsChange={setOptions}
        onLoad={handleLoad}
        formProps={formProps}
        {...otherProps}
      />
    )
  );
};

export default React.memo(AffiliateSearchInput);
