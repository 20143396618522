import React from 'react';
import { useTranslate } from '@hooks';
import { Table } from 'reactstrap';
import Trans from 'next-translate/Trans';
import { FlexContainer } from '@dotone/react-ui-core';

const MultiplePointConversionsTab = () => {
  const { t } = useTranslate('uploadInstruction');

  return (
    <>
      <FlexContainer>
        <span className="text-warning">{t('templateFile')}:</span>
        <ul className="d-flex">
          <li>
            <a
              target="_blank"
              href="https://s3.amazonaws.com/direct-vibrantads-com-cdn/uploads/approve-reject-orders.csv"
              rel="noreferrer"
            >
              {t('multiplePointTable.approvingRejectingOrders')}
            </a>
          </li>
          <li className="ml-4">
            <a
              target="_blank"
              href="https://s3.amazonaws.com/direct-vibrantads-com-cdn/uploads/add-pending-orders.csv"
              rel="noreferrer"
            >
              {t('multiplePointTable.addingNewPendingOrders')}
            </a>
          </li>
        </ul>
      </FlexContainer>
      <p>{t('multiplePointTable.usage')}</p>
      <Table bordered className="table-overview">
        <thead>
          <tr>
            <th scope="col">{t('tableHeaders.columnNames')}</th>
            <th scope="col">{t('tableHeaders.descriptions')}</th>
            <th scope="col">{t('tableHeaders.notes')}</th>
            <th scope="col">{t('tableHeaders.required')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('multiplePointTable.rows.capturedAt.columnName')}</td>
            <td>{t('multiplePointTable.rows.capturedAt.description')}</td>
            <td>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.capturedAt.notes.note1"
                components={[<strong key="multiplePointTable.rows.capturedAt.notes.note1" />]}
              />
              <ul>
                <li>
                  <Trans
                    i18nKey="uploadInstruction:multiplePointTable.rows.capturedAt.notes.listItem1"
                    components={[
                      <strong key="multiplePointTable.rows.capturedAt.notes.listItem1" />,
                    ]}
                  />
                </li>
              </ul>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.capturedAt.notes.note2"
                components={[<strong key="multiplePointTable.rows.capturedAt.notes.note2" />]}
              />
            </td>
            <td>{t('optional')}</td>
          </tr>
          <tr>
            <td>{t('multiplePointTable.rows.convertedAt.columnName')}</td>
            <td>{t('multiplePointTable.rows.convertedAt.description')}</td>
            <td>
              {t('multiplePointTable.rows.convertedAt.notes.note1')}
              <ul>
                <li>{t('multiplePointTable.rows.convertedAt.notes.listItem1')}</li>
              </ul>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.convertedAt.notes.note2"
                components={[<strong key="multiplePointTable.rows.convertedAt.notes.note2" />]}
              />
            </td>
            <td>{t('optional')}</td>
          </tr>
          <tr>
            <td>{t('multiplePointTable.rows.id.columnName')}</td>
            <td>{t('multiplePointTable.rows.id.description')}</td>
            <td>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.id.notes.note1"
                components={[<strong key="multiplePointTable.rows.id.notes.note1" />]}
              />
              <br />
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.id.notes.note2"
                components={[<strong key="multiplePointTable.rows.id.notes.note2" />]}
              />
            </td>
            <td>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.id.required.note1"
                components={[<strong key="multiplePointTable.rows.id.required.note1" />]}
              />
              <br />
              {t('multiplePointTable.rows.id.required.note2')}
            </td>
          </tr>
          <tr>
            <td>{t('multiplePointTable.rows.offerId.columnName')}</td>
            <td>{t('multiplePointTable.rows.offerId.description')}</td>
            <td>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.offerId.notes"
                components={[<strong key="multiplePointTable.rows.offerId.notes" />]}
              />
            </td>
            <td>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.offerId.required.note1"
                components={[<strong key="multiplePointTable.rows.offerId.required.note1" />]}
              />
              <br />
              {t('multiplePointTable.rows.offerId.required.note2')}
            </td>
          </tr>
          <tr>
            <td>{t('multiplePointTable.rows.orderNumber.columnName')}</td>
            <td>{t('multiplePointTable.rows.orderNumber.description')}</td>
            <td>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.orderNumber.notes"
                components={[<strong key="multiplePointTable.rows.orderNumber.notes" />]}
              />
            </td>
            <td>
              <Trans
                i18nKey="uploadInstruction:required"
                components={[<strong key="required" />]}
              />
            </td>
          </tr>
          <tr>
            <td>{t('multiplePointTable.rows.stepName.columnName')}</td>
            <td>{t('multiplePointTable.rows.stepName.description')}</td>
            <td>{t('multiplePointTable.rows.stepName.notes')}</td>
            <td>
              <Trans
                i18nKey="uploadInstruction:required"
                components={[<strong key="required" />]}
              />
            </td>
          </tr>
          <tr>
            <td>{t('multiplePointTable.rows.approval.columnName')}</td>
            <td>{t('multiplePointTable.rows.approval.description')}</td>
            <Trans
              i18nKey="uploadInstruction:multiplePointTable.rows.approval.notes"
              components={[<strong key="multiplePointTable.rows.approval.notes" />]}
            />
            <td>
              <Trans
                i18nKey="uploadInstruction:required"
                components={[<strong key="required" />]}
              />
            </td>
          </tr>
          <tr>
            <td>{t('multiplePointTable.rows.truePay.columnName')}</td>
            <td>{t('multiplePointTable.rows.truePay.description')}</td>
            <td>
              {t('multiplePointTable.rows.truePay.notes.note1')}
              <br />
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.truePay.notes.note2"
                components={[<strong key="multiplePointTable.rows.truePay.notes.note2" />]}
              />
              <br />
              {t('multiplePointTable.rows.truePay.notes.note3')}
              <br />
              {t('multiplePointTable.rows.truePay.notes.note4')}
            </td>
            <td>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.truePay.required.note1"
                components={[<strong key="multiplePointTable.rows.truePay.required.note1" />]}
              />
              <br />
              {t('multiplePointTable.rows.truePay.required.note2')}
            </td>
          </tr>
          <tr>
            <td>{t('multiplePointTable.rows.orderTotal.columnName')}</td>
            <td>{t('multiplePointTable.rows.orderTotal.description')}</td>
            <td>
              {t('multiplePointTable.rows.orderTotal.notes.note1')}
              <br />
              {t('multiplePointTable.rows.orderTotal.notes.note2')}
              <br />
              {t('multiplePointTable.rows.orderTotal.notes.note3')}
            </td>
            <td>
              <Trans
                i18nKey="uploadInstruction:multiplePointTable.rows.orderTotal.required.note1"
                components={[<strong key="multiplePointTable.rows.orderTotal.required.note1" />]}
              />
              <br />
              {t('multiplePointTable.rows.orderTotal.required.note2')}
            </td>
          </tr>
          <tr>
            <td>{t('multiplePointTable.rows.affiliateId.columnName')}</td>
            <td>{t('multiplePointTable.rows.affiliateId.description')}</td>
            <td>
              {t('multiplePointTable.rows.affiliateId.notes.note1')}
              <br />
              {t('multiplePointTable.rows.affiliateId.notes.note2')}
              <br />
              {t('multiplePointTable.rows.affiliateId.notes.note3')}
            </td>
            <td>{t('optional')}</td>
          </tr>
        </tbody>
      </Table>
      <h5>{t('csvExample')}</h5>
      <Table bordered>
        <thead>
          <tr>
            <th>captured_at ({t('optional')})</th>
            <th>converted_at ({t('optional')})</th>
            <th>offer_id</th>
            <th>order_number ({t('optional')})</th>
            <th>order_total</th>
            <th>true_pay</th>
            <th>step_name ({t('optional')})</th>
            <th>approval</th>
            <th>id</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('dateFormat')}</td>
            <td>{t('dateFormat')}</td>
            <td>1001</td>
            <td>S1000-5678</td>
            <td>100</td>
            <td>2</td>
            <td>default/sale</td>
            <td>Pending/Rejected/Approved</td>
            <td>{t('id')}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default MultiplePointConversionsTab;
