import React from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { useTranslate, useSearchSelector } from '@hooks';
import { searchAffiliates } from '@actions';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import { FilterBlock } from '@dotone/react-ui-core';

const AffiliateFilterBlock = ({ ids = [], ...otherProps }) => {
  const { t } = useTranslate('common', 'model');
  const dispatch = useDispatch();
  const { data: affiliates } = useSearchSelector(({ affiliateSearch }) => affiliateSearch, {
    ids,
  });

  useEffectOnce(() => {
    if (ids.length && !affiliates.length) {
      dispatch(searchAffiliates({ ids }));
    }
  });

  return (
    <FilterBlock
      list={false}
      label={t('affiliate')}
      items={affiliates.map((value) => getNameWithId(value))}
      {...otherProps}
    />
  );
};

export default React.memo(AffiliateFilterBlock);
