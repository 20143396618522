import React, { useEffect } from 'react';
import { useLocalizedDataSelector, useTranslate } from '@hooks';
import { FilterBlock } from '@dotone/react-ui-core';
import { useDispatch } from 'react-redux';
import { fetchMediaCategories } from '@actions/mediaCategoryActions';

const MediaCategoryFilterBlock = ({ ids = [], ...otherProps }) => {
  const { t, locale } = useTranslate('common', 'model');
  const dispatch = useDispatch();
  const mediaCategories = useLocalizedDataSelector(({ mediaCategoryList }) => mediaCategoryList);
  const childMediaCategories = mediaCategories.reduce(
    (acc, mediaCategory) => [...acc, ...mediaCategory.childCategories],
    []
  );
  const filter = (items, mediaCategoryIds = []) => {
    return items.filter((item) => mediaCategoryIds.includes(item.id));
  };

  useEffect(() => {
    if (!mediaCategories.length) {
      dispatch(fetchMediaCategories(locale));
    }
  }, [dispatch, mediaCategories.length, locale]);

  return (
    <FilterBlock
      label={t('affiliateType')}
      items={filter(childMediaCategories, ids)}
      {...otherProps}
    />
  );
};

export default React.memo(MediaCategoryFilterBlock);
