import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { createContact, updateContact } from '@actions';
import { contactStatus } from '@dotone/react-ui-core/constants';
import { FormGroup as BsFormGroup, Col, Form } from 'reactstrap';
import {
  Label,
  Button,
  ButtonCancel,
  ContactStatusSelect,
  FormAction,
  Input,
  MessengerServiceSelect,
} from '@dotone/react-ui-core';

const FormGroup = ({ label, required = false, ...otherProps }) => (
  <BsFormGroup row>
    <Col md={4} lg={3} className="text-left text-md-right">
      <Label>
        {label}
        {required && '*'}
      </Label>
    </Col>
    <Col md={8} lg={9} {...otherProps} />
  </BsFormGroup>
);

const ContactForm = ({ defaultValues: givenValues = {}, onSuccess, onCancel, ...otherProps }) => {
  const defaultValues = {
    status: contactStatus.ACTIVE,
    ...givenValues,
  };
  const { t } = useTranslate('contact', 'model');
  const dispatch = useDispatch();
  const {
    isLoading,
    status,
    data = { message: {} },
  } = useShallowEqualSelector(({ contactCreate, contactUpdate }) =>
    defaultValues.id ? contactUpdate : contactCreate
  );

  const { connectForm, handleSubmit, setError } = useForm({ defaultValues });

  const handleSubmitForm = (values) => {
    if (defaultValues.id) {
      dispatch(updateContact(defaultValues.id, values));
    } else {
      dispatch(createContact(values));
    }
  };

  useEffect(() => {
    if (onCancel && status === 'success' && !isLoading) onCancel();
  }, [onCancel, status, isLoading]);

  useDeepCompareEffect(() => {
    if (status === 'error' && data.message) {
      Object.keys(data.message).forEach((key) =>
        setError(key, { type: 'custom', message: data.message[key] })
      );
    }
  }, [status, data.message, setError, defaultValues]);

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} {...otherProps}>
      {connectForm(
        <>
          <div className="form-inner">
            <FormGroup label={t('status')} required>
              <ContactStatusSelect name="status" rules={{ required: true }} />
            </FormGroup>
            <FormGroup label={t('email')} required>
              <Input
                type="email"
                name="email"
                placeholder={t('email')}
                rules={{ required: true }}
              />
            </FormGroup>
            <FormGroup label={t('firstName')} required>
              <Input name="firstName" placeholder={t('firstName')} rules={{ required: true }} />
            </FormGroup>
            <FormGroup label={t('lastName')} required>
              <Input name="lastName" placeholder={t('lastName')} rules={{ required: true }} />
            </FormGroup>
            <FormGroup label={t('title')}>
              <Input name="title" placeholder={t('title')} />
            </FormGroup>
            <FormGroup label={t('phone')} required>
              <Input name="phone" placeholder={t('phone')} rules={{ required: true }} />
            </FormGroup>
            <FormGroup label={t('messengerService')}>
              <MessengerServiceSelect name="messengerService" />
            </FormGroup>
            <FormGroup label={t('messengerId')}>
              <Input name="messengerId" placeholder={t('messengerId')} />
            </FormGroup>
            <FormGroup label={t('emailOptin')}>
              <Input name="emailOptin" type="checkbox" />
            </FormGroup>
          </div>
          <FormAction align="right">
            <Button variant="save" color="primary" submitting={isLoading}>
              {t('common:actions.save')}
            </Button>
            {onCancel && <ButtonCancel disabled={isLoading} onClick={onCancel} />}
          </FormAction>
        </>
      )}
    </Form>
  );
};

export default ContactForm;
