import { menuItems } from '@data';
import { chain, find } from 'lodash';

export const findMenu = (pathname) => {
  const items = chain(menuItems)
    .flatMap((item) => [item, ...(item.children || [])])
    .value();

  return find(items, { pathname });
};

export const withHttps = (url) => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);
