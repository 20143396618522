import { AffiliateUserApi } from '@api';
import { managerTypes as actionTypes } from './types';

export const fetchManagers = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  AffiliateUserApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const setCurrentManager = (manager) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT.SUCCESS,
    payload: { data: manager },
  });
};
