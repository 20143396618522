import { offerTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default({ collection: true }),
  pagination,
};

export const offerList = (state = defaultState, action) =>
  commonReducer(actionTypes.LIST, state, action);

export const offerSearch = (state = defaultState, action) =>
  commonReducer(actionTypes.SEARCH, state, action);
