import API from './api';

export default class UserApi {
  static authenticate(params) {
    return API.post('/advertisers/sessions', params);
  }

  static authenticateViaToken(params) {
    return API.post('/advertisers/sessions/create_by_token', params);
  }

  static authenticateViaProvider(provider, params) {
    if (provider === 'google_oauth2') {
      return API.post(`/advertisers/auth/${provider}/callback`, params);
    }
    return API.get(`/advertisers/auth/${provider}/callback`, params);
  }

  static register(params, urlParams) {
    return API.post('/advertisers/registrations/register', params, urlParams);
  }

  static resendVerification(params) {
    return API.post('/advertisers/registrations/resend_verification', params);
  }

  static verify(params) {
    return API.post('/advertisers/registrations/verify', params);
  }

  static current() {
    return API.get('/advertisers/networks/current');
  }

  static get(id, params) {
    return API.get(`/advertisers/networks/${id}`, params);
  }

  static update(id, params) {
    return API.put(`/advertisers/networks/${id}`, params);
  }
}
