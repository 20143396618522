import * as Sentry from '@sentry/nextjs';
import { UserApi } from '@api';
import { userTypes as actionTypes } from './types';

export const getCurrentUser = () => (dispatch) => {
  dispatch({
    type: actionTypes.CURRENT.LOADING,
  });

  UserApi.current()
    .then((response) => {
      dispatch({
        type: actionTypes.CURRENT.SUCCESS,
        payload: response,
      });

      Sentry.setUser({
        id: `${response.data.type}:${response.data.id}`,
        email: response.data.contactEmail,
        pro: response.data.pro,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CURRENT.FAILURE,
        message: error.statusText,
      });
    });
};

// NOTE: This may not apply since network model is quite simple unlike affiliate
// Please remove if you think network model won't expand its relations
export const getUser = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.CURRENT.LOADING,
  });

  UserApi.get(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.CURRENT.SUCCESS,
        payload: { ...response, data: { ...response.data, full: true } },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CURRENT.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const registerUser = (params, urlParams) => (dispatch) => {
  dispatch({
    type: actionTypes.REGISTER.LOADING,
  });

  UserApi.register({ registration: params }, urlParams)
    .then((response) => {
      dispatch({
        type: actionTypes.REGISTER.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REGISTER.FAILURE,
        payload: { data: error.data },
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const updateUser =
  (id, params, notify = true) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE.LOADING,
    });

    UserApi.update(id, { network: params })
      .then((response) => {
        dispatch({
          type: actionTypes.CURRENT.HYDRATE,
          payload: response,
        });

        dispatch({
          type: actionTypes.UPDATE.SUCCESS,
          payload: response,
          message: notify ? 'updated' : null,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE.FAILURE,
          payload: { data: error.data },
          message: `notify:general.errors.${error.status}`,
        });
      });
  };
