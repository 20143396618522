import { chatRoomTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const collectionState = {
  ...commonState.default({ collection: true, pagination }),
};

const defaultState = {
  ...commonState.default(),
};

export const chatRoomCreate = (state = defaultState, action) =>
  commonReducer(actionTypes.CREATE, state, action);

export const chatRoomCurrent = (state = defaultState, action) =>
  commonReducer(actionTypes.GET, state, action);

export const chatRoomList = (state = collectionState, action) =>
  commonReducer(actionTypes.LIST, state, action);
