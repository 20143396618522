import { feedTypes as actionTypes } from '@actions';
import { commonState, commonReducer, pagination } from '@dotone/react-ui-core/helpers';

const defaultState = {
  ...commonState.default({ collection: true }),
  pagination,
};

export const feedList = (state = defaultState, action) =>
  commonReducer(actionTypes.LIST, state, action);
