import { SiteInfoApi } from '@api';
import { siteInfoTypes as actionTypes } from './types';

export const fetchSiteInfoImpressions =
  (id, params = {}) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.GET_SITE_INFO_IMPRESSIONS.LOADING,
    });

    SiteInfoApi.getImpressions(id, params)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_SITE_INFO_IMPRESSIONS.SUCCESS,
          payload: {
            ...response,
            stateKey: id,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_SITE_INFO_IMPRESSIONS.FAILURE,
          message: `notify:general.errors.${error.status}`,
        });
      });
  };
