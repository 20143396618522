import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate, useCurrentUser, useFilterSelector } from '@hooks';
import { fetchRecentFeeds } from '@actions';
import { appRoles } from '@dotone/react-ui-core/constants';
import { camelCase, isEmpty } from 'lodash';
import { CardBody } from 'reactstrap';
import { FeedItemList, CardFeatured } from '@dotone/react-ui-core';

const FeedRecentList = ({ role }) => {
  const { t } = useTranslate('dashboard', 'announcements');
  const dispatch = useDispatch();
  const feedType = camelCase(`recentFeeds-${role}`);

  const { currentUser, locale } = useCurrentUser();
  const { data, isLoading } = useFilterSelector((state) => state[feedType], {
    locale,
  });
  const empty = isEmpty(data);

  useEffect(() => {
    if (currentUser.id && empty) {
      dispatch(fetchRecentFeeds({ role, locale }));
    }
  }, [dispatch, currentUser.id, locale, role, empty]);

  const path = {
    pathname: '/advertisers/announcements',
    query: { role },
  };

  return (
    <CardFeatured title={t(`title.${camelCase(role)}`)} path={path} data={data} loading={isLoading}>
      <CardBody className="p-0">
        <FeedItemList
          items={data}
          itemProps={{ className: 'p-2 border-right-0', generateLink: role === appRoles.NETWORK }}
        />
      </CardBody>
    </CardFeatured>
  );
};

export default FeedRecentList;
