import { FaqFeedApi } from '@api';
import { faqTypes as actionTypes } from './types';

export const fetchFaqs = (locale) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  FaqFeedApi.getList({ locale })
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: { [locale]: response },
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
